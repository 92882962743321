import React from 'react'
import Slider from '@material-ui/core/Slider';

function tshirtValueLabelFormat(value) {
    switch (value) {
        case 1:
            return "XXS";
        case 2:
            return "XS";
        case 3:
            return "S";
        case 4:
            return "M";
        case 5:
            return "L";
        case 6:
            return "XL";
        case 7:
            return "XXL";
        default:
            return "bitte auswählen";
    }
}


export const FormikTshirtSlider = ({field, form, ...props}) => {

    return (
        <div>
            <p style={{margin:'0'}}>{props.label}: {tshirtValueLabelFormat(field.value)} {props.unit}</p>
            <Slider
                min={props.min}
                max={props.max}
                aria-label={field.name}
                name={field.name}
                value={field.value}
                valueLabelDisplay="off"
                onChange={(event, value) => {
                    form.setFieldValue(field.name, value, true)
                }}
                {...props}
            />
        </div>
    );
};
