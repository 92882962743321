import React from 'react'
import Login from './components/login/Login'
import {Dashboard} from './components/dashboard/Dashboard'
import appData from './models/ReddworldData'
import {observer} from "mobx-react";
import {reddworldTheme} from "./theme/reddworld-theme";

import './App.scss'
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {ThemeProvider} from "@material-ui/styles";
import "../src/components/font/JoannaMT.css"

// This is the Main React Component, which is used as an anchorpoint for everything else
const App = observer(
    class App extends React.Component {

        abortController = new AbortController();

        handleAddToHomescreenClick = () => {
            alert(`
                1. Open Share menu
                2. Tap on "Add to Home Screen" button`);
        };

        componentDidMount() {
            // check if there is a Auth-Token in localstorage
            if (localStorage.getItem("jwt") !== null) {
                appData.checkForSavedLogin();
            }
        }

        componentWillUnmount() {
            // abort all asychronous tasks
            this.abortController.abort()
        }

        render() {
            let currentPage;
            if (appData.loggedIn) {
                console.log("logged in, rendering dashboard");

                currentPage = <Dashboard />
                //currentPage = <TestStructure/>
            } else {
                console.log("not logged in, rendering login");
                currentPage = <Login/>
            }

            return <ThemeProvider theme={reddworldTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    {currentPage}
                </MuiPickersUtilsProvider>
            </ThemeProvider>

        }

    }
);
export default App
