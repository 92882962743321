import React from 'react';
import appData from "../../../models/ReddworldData";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
    circleAvatar: {
        width: 64,
        height: 64,
        border: "6px solid #942738"
    },
    circleAvatarBig: {
        width: "10em",
        height: "10em",
        border: "14px solid #942738",
        "margin-top": "40px"
    }
});

export function CircleAvatar() {
    const classes = useStyles();

    return (
        <Grid container justify="center" alignItems="center">
            <Avatar alt="Avatar" src={appData.user.avatarUrl} className={classes.circleAvatar} />
        </Grid>
    );
}
export function CircleAvatarBig() {
    const classes = useStyles();

    return (
        <Grid container justify="center" alignItems="center">
            <br />
            <Avatar alt="Avatar" src={appData.user.avatarUrl} className={classes.circleAvatarBig} />
        </Grid>
    );
}
