import {destroy, flow, getParent, getRoot, types} from "mobx-state-tree";

import {Address} from "./Address"
import {BILLING_DATA_FILE_NOT_UPLOADED_YET} from "../constants/constants";
import axios from "axios";
import {API_FILES_LOCAL} from "../constants/api";

/**
 *
 id FileType
 1    Studienbescheinigung (%semester%)
 2    Schulbescheinigung
 3    Krankenkassen Mitgliedsbescheinigung
 6    Statusschreiben
 7    Meldung zur Sozialversicherung
 8    letzte Gehaltsabrechnung
 11    Anmeldung Hochschule
 12    Gewerbeanmeldung
 13    Personalbogen
 14    Gehaltsabrechnung
 15    Abrechnungsdokument
 16    Optionale Bescheinigung

 */

const FileType = types.model({
    id: types.maybeNull(types.union(types.number, types.string)),
    type: types.string
});

const BillingFile = types.model({
    title: types.maybeNull(types.string),
    filename: types.string,
    path: types.string,
    date: types.Date,
    type: types.string,
    fileType: FileType,
    status: types.number,
    semester: types.maybe(types.string),
    year: types.maybe(types.union(types.number, types.string)),
    idemployment: types.maybe(types.union(types.number, types.string))
}).actions(self => ({
    deleteFileInDB: flow(function* deleteFileInDB() {
        // <- note the star, this a generator function!
        // let parent = getParent(self);
        let user = getParent(getParent(getParent(self)));
        try {
            // ... yield can be used in async/await style
            // eslint-disable-next-line no-unused-vars
            const returnValue = yield axios.post(API_FILES_LOCAL, {
                action: 'deleteUserBillingDataFile',
                iduser: user.id,
                filename: self.filename,
                auth_token: localStorage.getItem('jwt')
            }).then((response) => {
                if (response.status === 200) {
                    getParent(getParent(self)).removeFile(self);
                    return response.data
                }
            }, (error) => {
                console.log(error);
                return false
            });

        } catch (error) {
            // ... including try/catch error handling
            console.error("Failed to delete file", error)
        }
    }),
    updateFile(file) {
        self.filename = file.filename;
        self.status = file.status;
        self.title = file.title;
    },
}));

const BankAccountData = types.model({
    iban: types.string,
    bic: types.string,
    creditInstitution: types.string,
    accountHolderDerogation: types.boolean,
    bankAccountOwner: types.maybeNull(types.string)
});

const BillingAccountingType = types.model({
    id: types.maybeNull(types.union(types.number, types.string)),
    billingAccountingType: types.string
});

const Confession = types.model({
    id: types.maybeNull(types.union(types.number, types.string)),
    confession: types.maybeNull(types.string)
});

const Employer = types.model({
    id: types.maybeNull(types.union(types.number, types.string)),
    address: Address,
    name: types.string
});

const Employment = types.model({
    id: types.maybeNull(types.union(types.number, types.string)),
    start: types.Date,
    end: types.Date,
    workingDays: types.number,
    employer: Employer
}).views(self => ({
    get payRollFile() {
        if (self.id > 0) {
            let files = getRoot(self).user.billing_data.files;
            let file = files.filter(file => file.idemployment === self.id && file.fileType.id === 8)[0];
            if (file === null || typeof file === "undefined") {
                //return null;
                let parent = getParent(getParent(self));
                parent.addFile({
                    "idemployment": self.id,
                    "title": "",
                    "filename": "",
                    "path": "",
                    "date": new Date(),
                    "type": "",
                    "fileType": {
                        "id": 8,
                        "type": ""
                    },
                    "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
                });
                file = files.filter(file => file.idemployment === self.id && file.fileType.id === 8)[0];
                return file;
            } else {
                return file;
            }
        } else {
            return null
        }
        // else {
        //     return {
        //         "title": "",
        //         "filename": "",
        //         "path": "",
        //         "date": new Date(),
        //         "type": "",
        //         "fileType": {
        //             "id": 8,
        //             "type": ""
        //         },
        //         "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
        //     };
        // }
    },
    get socialSecurityFile() {
        if (self.id > 0) {
            let files = getRoot(self).user.billing_data.files;
            let file = files.filter(file => file.idemployment === self.id && file.fileType.id === 7)[0];
            if (file === null || typeof file === "undefined") {
                return {
                    "idemployment": self.id,
                    "title": "",
                    "filename": "",
                    "path": "",
                    "date": new Date(),
                    "type": "",
                    "fileType": {
                        "id": 7,
                        "type": ""
                    },
                    "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
                }
            } else {
                return file;
            }
        } else {
            return {
                "title": "",
                "filename": "",
                "path": "",
                "date": new Date(),
                "type": "",
                "fileType": {
                    "id": 7,
                    "type": ""
                },
                "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
            };
        }
    }
})).actions(self => ({
    getPayRollFile() {
        if (self.id > 0) {
            let files = getRoot(self).user.billing_data.files;
            let file = files.filter(file => file.idemployment === self.id && file.fileType.id === 8)[0];
            if (file === null || typeof file === "undefined") {
                //return null;
                let parent = getParent(getParent(self));
                parent.addFile({
                    "idemployment": self.id,
                    "title": "",
                    "filename": "",
                    "path": "",
                    "date": new Date(),
                    "type": "",
                    "fileType": {
                        "id": 8,
                        "type": "letzte Gehaltsabrechnung"
                    },
                    "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
                });
                file = files.filter(file => file.idemployment === self.id && file.fileType.id === 8)[0];
                return file;
            } else {
                return file;
            }
        } else {
            return null
        }

    },
}));

export const HealthInsurance = types.model({
    id: types.maybeNull(types.number),
    healthInsurance: types.maybeNull(types.string),
    address: Address
});

const HealthInsuranceType = types.model({
    id: types.maybeNull(types.union(types.number, types.string)),
    healthInsuranceType: types.maybeNull(types.optional(types.string, ""))
});

const TaxOffice = types.model({
    id: types.maybe(types.number),
    taxOffice: types.maybe(types.string)
});

export const BillingData = types.model({
    bankAccountData: types.maybe(BankAccountData),
    billingAccountingType: types.maybe(BillingAccountingType),
    confession: types.maybe(Confession),
    heardOfCompany: types.maybe(types.string),
    employment: types.array(Employment),
    healthInsurance: types.maybe(HealthInsurance),
    healthInsuranceType: types.maybe(HealthInsuranceType),
    nationalInsuranceNumber: types.maybe(types.string),
    numOfSemesters: types.maybe(types.number),
    taxClass: types.maybe(types.string),
    taxOffice: types.maybe(TaxOffice),
    tinTaxNumber: types.maybe(types.string),
    files: types.array(BillingFile),
}).actions(self => ({
    removeFile(file) {
        destroy(file);
    },
    addFile(file) {
        self.files.push(file);
    },
    setBillingAccountingType(value) {
        self.billingAccountingType.id = value;
    },
    getHealthInsuranceCertificates() {
        let file = self.files.filter(file => file.fileType.id === 3)[0];
        if (typeof (file) === 'undefined') {
            self.addFile({
                "title": "",
                "filename": "",
                "path": "",
                "date": new Date(),
                "type": "",
                "fileType": {
                    "id": 3,
                    "type": "Krankenkassen Mitgliedsbescheinigung"
                },
                "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
            });
        }
        return self.files.filter(file => file.fileType.id === 3)[0];
    },
    getPayRollFiles() {
        return self.files.filter(file => file.fileType.id === 14);
    },
    getCurrentSemesterCertificate() {
        const date = new Date();
        // fix for january
        const year = date.getFullYear() - 1;
        const month = date.getMonth() + 1;
        let currentSemester;
        (month > 8 || month < 3) ? currentSemester = "WS" : currentSemester = "SS";

        let file = self.files.filter(file =>
            file.fileType.id === 1 && parseInt(file.year) === year && file.semester === currentSemester)[0];
        if (typeof (file) === 'undefined') {
            self.addFile({
                "title": "",
                "filename": "",
                "path": "",
                "date": new Date(),
                "type": "",
                "year": year,
                "semester": currentSemester,
                "fileType": {
                    "id": 1,
                    "type": "Studienbescheinigung (%semester%)"
                },
                "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
            });
        }
        return self.files.filter(file =>
            file.fileType.id === 1 && parseInt(file.year) === year && file.semester === currentSemester)[0];
    },
    getPreviousSemesterCertificate() {
        const date = new Date();
        // fix for january
        const year = date.getFullYear() - 1;
        const month = date.getMonth() + 1;
        let previousSemester;
        (month > 8 || month < 3) ? previousSemester = "SS" : previousSemester = "WS";

        let file = self.files.filter(file =>
            file.fileType.id === 1 && parseInt(file.year) === year && file.semester === previousSemester)[0];

        if (typeof (file) === 'undefined') {
            self.addFile({
                "title": "",
                "filename": "",
                "path": "",
                "date": new Date(),
                "type": "",
                "year": year,
                "semester": previousSemester,
                "fileType": {
                    "id": 1,
                    "type": "Studienbescheinigung (%semester%)"
                },
                "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
            });
        }

        return self.files.filter(file =>
            file.fileType.id === 1 && parseInt(file.year) === year && file.semester === previousSemester)[0];
    },
    getBeforePreviousSemesterCertificate() {
        const date = new Date();
        // fix for january
        const year = date.getFullYear() - 2;
        // substitute with this line:
        //const year = date.getFullYear() - 1;
        const semester = "WS";

        let file = self.files.filter(file =>
            file.fileType.id === 1 && parseInt(file.year) === year && file.semester === semester)[0];

        if (typeof (file) === 'undefined') {
            self.addFile({
                "title": "",
                "filename": "",
                "path": "",
                "date": new Date(),
                "type": "",
                "year": year,
                "semester": semester,
                "fileType": {
                    "id": 1,
                    "type": "Studienbescheinigung (%semester%)"
                },
                "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
            });
        }

        return self.files.filter(file =>
            file.fileType.id === 1 && parseInt(file.year) === year && file.semester === semester)[0];

    },
    getSchoolCertificate() {

        let file = self.files.filter(file =>
            file.fileType.id === 2)[0];

        if (typeof (file) === 'undefined') {
            self.addFile({
                "title": "",
                "filename": "",
                "path": "",
                "date": new Date(),
                "type": "",
                "fileType": {
                    "id": 2,
                    "type": "Schulbescheinigung"
                },
                "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
            });
        }

        return self.files.filter(file =>
            file.fileType.id === 2)[0];

    },
    getUniversityApplication() {

        let file = self.files.filter(file =>
            file.fileType.id === 11)[0];

        if (typeof (file) === 'undefined') {
            self.addFile({
                "title": "",
                "filename": "",
                "path": "",
                "date": new Date(),
                "type": "",
                "fileType": {
                    "id": 11,
                    "type": "Anmeldung Hochschule"
                },
                "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
            });
        }

        return self.files.filter(file =>
            file.fileType.id === 11)[0];

    },
    getStatusLetter() {

        let file = self.files.filter(file =>
            file.fileType.id === 6)[0];

        if (typeof (file) === 'undefined') {
            self.addFile({
                "title": "",
                "filename": "",
                "path": "",
                "date": new Date(),
                "type": "",
                "fileType": {
                    "id": 6,
                    "type": "Statusschreiben"
                },
                "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
            });
        }

        return self.files.filter(file =>
            file.fileType.id === 6)[0];

    },
    getBusinessRegistration() {

        let file = self.files.filter(file =>
            file.fileType.id === 12)[0];

        if (typeof (file) === 'undefined') {
            self.addFile({
                "title": "",
                "filename": "",
                "path": "",
                "date": new Date(),
                "type": "",
                "fileType": {
                    "id": 12,
                    "type": "Gewerbeanmeldung"
                },
                "status": BILLING_DATA_FILE_NOT_UPLOADED_YET,
            });
        }

        return self.files.filter(file =>
            file.fileType.id === 12)[0];

    },
    addEmptyEmploymentIfNone() {
        if (self.employment.length === 0) {
            self.employment.push({
                id: null,
                start: new Date(),
                end: new Date(),
                workingDays: 0,
                employer: {
                    id: null,
                    address: {
                        id: null,
                        street: "",
                        housingNumber: "",
                        zip: "",
                        city: {
                            id: null,
                            city: ""
                        },
                        state: {
                            id: null,
                            state: ""
                        },
                        country: {
                            id: null,
                            country: ""
                        }
                    },
                    name: ""
                }
            });
        }
    },
    setHealthInsurance(healthInsurance) {
        self.healthInsurance = {
            id: healthInsurance.id,
            healthInsurance: healthInsurance.healthInsurance,
            address: {
                street: healthInsurance.address.street,
                housingNumber: healthInsurance.address.housingNumber,
                city: {
                    city: healthInsurance.address.city.city,
                    id: healthInsurance.address.city.id,
                },
                zip: healthInsurance.address.zip
            }
        };
    },

})).views(self => ({
    get healthInsuranceName() {
        return self.healthInsurance.healthInsurance;
    }
}));
