import React from 'react'
import Select from '@material-ui/core/Select';
// import styles from "../text_input/TextInput.module.css";
// import {makeStyles} from "@material-ui/core";

// const useStyles = makeStyles(theme => ({
//     root: {
//         width: '100%',
//     },
//     heading: {
//         fontSize: theme.typography.pxToRem(15),
//         fontWeight: theme.typography.fontWeightRegular,
//     },
// }));

export const SelectNative = ({field, form: {touched, errors}, ...props}) => (
    //const styles = useStyles();
        <div>
            <label htmlFor={field.name}>{props.label}</label><br />
            <Select native style={{width: "75%", textAlign: "center", textAlignLast : "center"}}
                // className={styles.input}

                {...field} {...props}>
                {/*{props.options.map((option) => {*/}
                {/*    return (<option value={option}>{option}</option>)*/}
                {/*})}*/}
            </Select>
            {touched[field.name] &&
            errors[field.name] && <div
                // className={styles.error}
            >{errors[field.name]}</div>}
        </div>
);

export const SelectClean = ({field, form: {touched, errors}, ...props}) => (
    //const styles = useStyles();
    <div>
        <Select native style={{textAlign: "center", textAlignLast : "center"}}
            // className={styles.input}
                {...field} {...props}>
            {/*{props.options.map((option) => {*/}
            {/*    return (<option value={option}>{option}</option>)*/}
            {/*})}*/}
        </Select>
        {touched[field.name] &&
        errors[field.name] && <div
            // className={styles.error}
        >{errors[field.name]}</div>}
    </div>
)
