import React from 'react'
import styles from './TextInput.module.css'

export const TextInput = ({field, form: {touched, errors}, ...props}) => (
    <div>
        {/*<label for={field.name}>{props.label}</label>*/}
        <input className={styles.input} type="text" {...field} {...props} placeholder={props.label}/>
        {touched[field.name] &&
        errors[field.name] && <div className={styles.error}>{errors[field.name]}</div>}
    </div>
);
