// export const API_USERS_LOCAL = 'http://localhost:8081/backend/modules/UserAdministration.php';
// export const API_USERS = 'http://localhost:8081/backend/modules/UserAdministration.php';
// export const API_JOBS = 'http://localhost:8081/backend/modules/JobAdministration.php';
// export const API_FILES = 'https://localhost:8081/backend/modules/FileAdministration.php';
// export const API_FILEUPLOAD = 'http://localhost:8081/backend/modules/FileAdministration.php';
// export const API_ELEARNING = 'http://localhost:8081/backend/modules/ELearningAdministration.php';
//export const API_AUTOCOMPLETE = 'http://localhost:8081/backend/modules/AutoCompletionModule.php';

// export const API_JOBS_LOCAL = 'http://localhost:8081/backend/modules/JobAdministration.php';

// export const API_FILES_LOCAL = 'https://dev.reddworld.com/backend/modules/FileAdministration.php';
// export const API_USERS_LOCAL = 'https://dev.reddworld.com/backend/modules/UserAdministration.php';
// export const API_JOBS_LOCAL = 'https://dev.reddworld.com/backend/modules/JobAdministration.php';
// export const API_USERS = 'https://dev.reddworld.com/backend/modules/UserAdministration.php';
// export const API_JOBS = 'https://dev.reddworld.com/backend/modules/JobAdministration.php';
// export const API_FILES = 'https://dev.reddworld.com/backend/modules/FileAdministration.php';
// export const API_FILEUPLOAD = 'https://dev.reddworld.com/backend/modules/FileAdministration.php';
// export const API_ELEARNING = 'https://dev.reddworld.com/backend/modules/ELearningAdministration.php';
// export const API_AUTOCOMPLETE = 'https://dev.reddworld.com/backend/modules/AutoCompletionModule.php';
// export const API_BACKEND = 'https://dev.reddworld.com/backend';
// export const API_BASE = 'https://dev.reddworld.com';
// export const API_FILEDOWNLOAD = 'https://dev.reddworld.com/backend/download.php';


//export const API_JOBS_LOCAL = 'https://dev.reddworld.com/backend/modules/JobAdministration.php';
//export const API_FILES_LOCAL = 'http://localhost:8081/backend/modules/FileAdministration.php';
//export const API_FILEDOWNLOAD = 'http://localhost:8081/backend/download.php';



//
export const API_FILES_LOCAL = 'https://backoffice.reddworld.com/backend/modules/FileAdministration.php';
export const API_USERS_LOCAL = 'https://backoffice.reddworld.com/backend/modules/UserAdministration.php';
export const API_JOBS_LOCAL = 'https://backoffice.reddworld.com/backend/modules/JobAdministration.php';
export const API_USERS = 'https://backoffice.reddworld.com/backend/modules/UserAdministration.php';
export const API_JOBS = 'https://backoffice.reddworld.com/backend/modules/JobAdministration.php';
export const API_FILES = 'https://backoffice.reddworld.com/backend/modules/FileAdministration.php';
export const API_FILEUPLOAD = 'https://backoffice.reddworld.com/backend/modules/FileAdministration.php';
export const API_ELEARNING = 'https://backoffice.reddworld.com/backend/modules/ELearningAdministration.php';
export const API_AUTOCOMPLETE = 'https://backoffice.reddworld.com/backend/modules/AutoCompletionModule.php';
export const API_BACKEND = 'https://backoffice.reddworld.com/backend';
export const API_BASE = 'https://backoffice.reddworld.com';
export const API_FILEDOWNLOAD = 'https://backoffice.reddworld.com/backend/download.php';
