import {types} from "mobx-state-tree";

// const exampleAddress = {
//     id: 123,
//     street: "Teststraße",
//     housingNumber: "1",
//     zip: 12345,
//     city: {
//         id: 1,
//         city: "Testhausen"
//     },
//     state: {
//         id: 1,
//         state: "Teststaat"
//     },
//     country: {
//         id: 5,
//         country: "Deutschland"
//     }
// };

export const City = types.model({
    id: types.maybeNull(types.number),
    city: types.maybeNull(types.string)
});

export const State = types.model({
    id: types.maybeNull(types.number),
    state: types.maybeNull(types.string)
});

export const Country = types.model({
    id: types.maybeNull(types.number),
    country: types.maybeNull(types.string)
});

// this is how to define a model in mobx
export const Address = types.model({
    id: types.maybeNull(types.number),
    street: types.maybeNull(types.string),
    housingNumber: types.maybeNull(types.string),
    zip: types.maybeNull(types.string),
    city: types.maybeNull(City),
    state: types.maybeNull(State),
    country: types.maybeNull(Country),
}).actions(self => ({
        saveAddress() {
            console.log("saving the address: " + JSON.stringify(self))
        }
    })
).views(self => ({
    getFullAddress() {
        return (self.street + " " + self.housingNumber + ", " + self.zip + " " + self.city.city + ", " + self.country.country)
    }
}));
