import {createMuiTheme} from "@material-ui/core/index";
import "../components/font/JoannaMT.css"

export const reddworldTheme = createMuiTheme({
    fontFamily: 'Merriweather',
    boxShadow: 'none',

    palette: {
        primary: {500: '#b4233c'},
        secondary: {A400: '#008000'},
        error: {main: '#ff0000'},
    },

    typography: {
        useNextVariants: true,
        fontFamily: "Merriweather",
    },

    overrides: {
        TextInput_input:{
            "margin-bottom": "1.5em",
            "background-color" : "#FFFFFF",
            "-webkit-appearance": "none",
            "-webkit-border-radius":0,
            "border-radius":0
            },



        MuiInputBase: {
            input: {
                "text-align": "center",
                "border-bottom": "2px solid #942738",
                "background-color" : "#FFFFFF",
                "-webkit-appearance": "none",
                "-webkit-border-radius":0,
               "border-radius":0
            },
            // throws an error in console - style does not exist:
            // focus: {
            //     "border-bottom": "2px solid #b4233c",
            // }
        },
        MuiInput:{
            underline: {
                before: {
                    "border-bottom": "2px solid #b4233c"
                }
            },
        },
        MuiTypography: {

            root: {
                fontFamily: "Merriweather",
                "font-size": "1rem",
                // "font-family": '"Joanna MT", "Helvetica"',

            },
            h4: {
                "font-size": "2.5rem",
                // "font-family": '"Joanna MT", "Helvetica"',
                "font-weight": 700,
                "line-height": "1.17",
            },
            h6: {
                "font-size": "1rem",
                // "font-family": '"Joanna MT", "Helvetica"',
                "font-weight": 400,
                "line-height": "1.75",
            }
        },
        MuiButton: {
            outlinedPrimary: {
                border: '2px solid #b4233c',
                "line-height": "1.2",
                '&:hover': {
                    border: '2px solid #b4233c',
                }
            },
            outlinedSecondary: {
                border: '3px solid #008000',
                '&:hover': {
                    border: '3px solid #008000',
                }
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#b4233c',
            },
        },

        MuiFormControl: {
            root: {
                "margin-bottom": '1em',
            },
            // input:{
            //     'border-bottom': '2px solid #B4233C',
            // },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: lightBlue.A200,
                // color: "white",
            },
        },
        MuiPickersDay: {
            day: {
                color: '#b4233c',
            },
            daySelected: {
                backgroundColor: '#b4233c',
            },
            dayDisabled: {
                color: '#b4233c',
            },
            current: {
                color: '#b4233c',
            },
        },
        MuiPickersModal: {
            // throws error in console - style does not exist:
            // dialogAction: {
            //     color: '#b4233c',
            // },
        },
        MuiSlider: {
            root: {
                color: '#b4233c',
                height: 8,
                width: "75%",
            },
            markLabel: {
                marginTop: "0.5em",
            },
            thumb: {
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
                '&:focus,&:hover,&$active': {
                    boxShadow: 'inherit',
                },
            },
            active: {},
            valueLabel: {
                left: 'calc(-50% + 4px)',
            },
            track: {
                height: 8,
                borderRadius: 4,
            },
            rail: {
                height: 8,
                borderRadius: 4,
            },
        },
        MuiContainer: {
            root: {
                "padding-left": "0px",
                "padding-right": "0px",
                "padding-top": "0px",
                "overflow": "hidden",
                'margin-bottom': '14vh',


            },
            fixed: {
            },
            maxWidthXs: {

            },
            maxWidthSm: {
            },
            maxWidthMd: {
                "max-width": 'calc((100vh / 16) * 9)',
            },
            maxWidthLg: {
                "max-width": "800px !important",
                "padding-left": "0px",
                "padding-right": "0px",
                //"overflow-y": "hidden"
            },
            maxWidthXl: {

            },
        },
        MuiBottomNavigation: {
            root: {
                "height": "inherit",
                "display": 'flex',
                "bottom": 0,
                "z-index": 1000,
                "position": "fixed",
             //   "max-height": "10vh",
             //   "min-height": "9vh",
                "left": 0,
                "width": "100%",
                "padding-left": 0,
                backgroundColor: "#ffffff",
                // marginBottom: '10px',

                "boxShadow": "0pc 17px 16px 6px #c5c5c5;",
                // [breakpoints.down("md")]: {
                //    position: "fixed",
                //    "max-height": "10vh",
                //    "min-height": "9vh",
                //    width: "100%",
               //     height: "9vh",
                //    display: 'flex',
                //    bottom: "0",
                //    left: 0,
                //    "padding-left": 0,
                //    "z-index": 400,
                // },
            },
            iconOnly:{
                'padding-top': '8px',
            //    'padding-bottom': '16px',
            }
        },

        MuiBottomNavigationAction: {
            root: {
                "min-width": "40px !important",
             //   padding: "6px 6px 6px",
                //"border-radius": "30px",
                //border: "3px solid #b4233c"
            }
        },
        MuiPaper: {
            root: {
                //height: "100%",
                //width: "100%",
                margin: "0",
                padding: "0",
                "marginTop": "0em",
                "min-width":"60px",
            },

            elevation1: {
                boxShadow: 0,
            },


        },
        MuiExpansionPanel: {
            root: {
            "font-size": "1em",
                boxShadow: "none",
            },
            // first: {
            //   margin:0,
            // }
        },
        MuiExpansionPanelSummary: {
            root: {
                    "padding" : 0,
                minHeight : "44px !important",
            },
            content:{
                margin : "0 !important",

                expanded:{
                    margin: 0,
                }
            }
        },

        MuiSvgIcon: {
            root: {
                // "margin-top" : "0.5em",
                "min-height": "24px",
            }
        },
        MuiExpansionPanelDetails:{
            root: {

                "justify-content": "center",
            }
        },
        MuiCircularProgress: {
            svg: {
                width: "128px",
                height: "128px",
            }
        },
        MuiRating: {
            root: {
                "justify-content": "center",
                color: "#b4233c"
            }
        },
        MuiCollapse: {
            container: {
                // maxWidth: "80%",
                // margin: "0 auto",
                // border: "2px solid #942738",
                // borderRadius: "12px",

                },
        }
    },

});

reddworldTheme.spacing(1);
