import React from "react";
import appData from '../../../models/ReddworldData';
import JobAlertListItemView from "./JobAlertListItemView";
import moment from 'moment';
import 'moment/locale/de';
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import withStyles from "@material-ui/core/styles/withStyles";
import {Minus, Plus} from "../../icons/GeneralIcons";
import {
    JOB_APPLICATION_APPLIED,
    JOB_APPLICATION_AVAILABLE,
    JOB_APPLICATION_PENDING,
} from "../../../constants/constants";

const ExpansionPanel = withStyles({
    root: {
        //border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        "margin-right": 0,
        "margin-left": 0,
        "margin-top": 0,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: '#ffffff',
        //borderBottom: '1px solid rgba(0, 0, 0, .125)',
        margin: "0 0",
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            margin: '0 0',
            '&:first-child': {
                marginTop: 0,
            },
            '&:last-child': {
                marginBottom: 0,
            },
            '&:before': {
                opacity: 0,
            },
        },
        '&$expanded + &': {
            '&:before': {
                display: 'none',
            },
        },
    },
    content: {
        '&$expanded': {
            //margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        background: "#ffffff",
        padding: theme.spacing(2),
        display: 'inherit',
    },
}))(MuiExpansionPanelDetails);

export default function JobAlertListView(props) {
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    // const [jobAlertListRerender, setJobAlertListRerender] = React.useState();

    let jobAlertsByMonth;
    switch(props.status) {
        case JOB_APPLICATION_AVAILABLE:
            jobAlertsByMonth = appData.jobAlertsByMonthAll;
            break;
        // case JOB_APPLICATION_AVAILABLE:
        //     jobAlertsByMonth = appData.jobAlertsByMonthAvailable;
        //     break;
        case JOB_APPLICATION_APPLIED:
            jobAlertsByMonth = appData.jobAlertsByMonthApplied;
            break;
        case JOB_APPLICATION_PENDING:
            jobAlertsByMonth = appData.jobAlertsByMonthPending;
            break;
        default:
            jobAlertsByMonth = appData.jobAlertsByMonthAvailable;
    }

    return <>
        {Object.keys(jobAlertsByMonth).filter(value => value.length > 0).map(function (e) {
                return monthView(e, currentYear, jobAlertsByMonth[e], props.status)
            })
        }
        {Object.keys(jobAlertsByMonth).filter(value => value.length > 0).map(function (e) {
            return monthView(e, nextYear, jobAlertsByMonth[e], props.status)
        })
        }
    </>
}

function monthView(month, year, jobAlerts, status) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    let initialExpansion = false;
    if ((parseInt(month) === parseInt(currentMonth)) && (parseInt(year) === parseInt(currentYear))) {
        initialExpansion = true;
    }
    const [expanded, setExpanded] = React.useState(initialExpansion);

    function handleExpansion() {
        setExpanded(!expanded);
    }

    const headerId = month + "-header";
    const ariaContent = month + "-content";
    moment.locale("de");
    let d = moment(year + "-" + month, "YY-M", "de");
    let monthString = d.format('MMMM');
    let expansionStatusIndicator;
    expanded ? expansionStatusIndicator = <Minus /> : expansionStatusIndicator = <Plus />;

    jobAlerts = jobAlerts.filter(jobAlert => jobAlert.year === year);

    if (jobAlerts.length > 0) {
        return <>
            <ExpansionPanel square expanded={expanded} onChange={handleExpansion}>
                <ExpansionPanelSummary aria-controls={ariaContent} id={headerId}>
                    {expansionStatusIndicator}
                    <Typography>{monthString} {year}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {jobAlerts.map((jobAlert, idx) => {
                        // Return the element. Also pass key
                        return jobAlertView(jobAlert, idx, status);
                    })}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </>
    } else {
        return null;
    }
}

function jobAlertView(jobAlert, id, status) {
    return <>
        <JobAlertListItemView key={id} jobAlert={jobAlert} status={status}/>
    </>
}
