export const ERROR_SEVERE = 3;
export const BILLING_DATA_FILE_DELETED = 0;
export const BILLING_DATA_FILE_PENDING = 1;
export const BILLING_DATA_FILE_REJECTED = 2;
export const BILLING_DATA_FILE_VERIFIED = 3;
export const BILLING_DATA_FILE_OUTDATED = 4;
export const BILLING_DATA_FILE_NOT_UPLOADED_YET = 5;

export const PERSONEL_QUESTIONAIRE_TYPE_STUDENT	= 0;
export const PERSONEL_QUESTIONAIRE_TYPE_EMPLOYEE = 1;
export const PERSONEL_QUESTIONAIRE_TYPE_UNEMPLOYEED	= 2;
export const PERSONEL_QUESTIONAIRE_TYPE_UNEMPLOYEED_WITHOUT_REMUNERATION = 3;

// Schüler
export const BILLING_ACCOUNTING_TYPE_SCHOOL = 1;
// Student
export const BILLING_ACCOUNTING_TYPE_STUDENT = 2;
// Angestellter
export const BILLING_ACCOUNTING_TYPE_EMPLOYEE = 3;
// Freiberufler
export const BILLING_ACCOUNTING_TYPE_FREELANCER = 4;
// Studiumsbewerber
export const BILLING_ACCOUNTING_TYPE_STUDENT_APPLICANT = 5;
// Arbeitssuchend gemeldet
export const BILLING_ACCOUNTING_TYPE_UNEMPLOYED = 6;
// Selbstständig
export const BILLING_ACCOUNTING_TYPE_SELF_EMPLOYED = 7;
// Arbeitslos nicht gemeldet
export const BILLING_ACCOUNTING_TYPE_UNEMPLOYED_NO_REMUNERATION = 8;

export const JOB_APPLICATION_ALL = 0;
export const JOB_APPLICATION_AVAILABLE = 1;
export const JOB_APPLICATION_PENDING = 2;
export const JOB_APPLICATION_APPLIED = 3;
