import React from 'react'
import JobAlertListView from "./JobAlertListView"
import {Field, Form, Formik} from 'formik'
import {FormikJobAlertStatusSlider} from "../../input_elements/slider/FormikJobAlertStatusSlider";
import {JOB_APPLICATION_ALL, JOB_APPLICATION_APPLIED, JOB_APPLICATION_PENDING} from "../../../constants/constants";

const statusMarks = [
    {
        value: 1,
        label: "alle",
    },
    // {
    //     value: JOB_APPLICATION_AVAILABLE,
    //     label: "verfügbar",
    // },
    {
        value: JOB_APPLICATION_PENDING,
        label: "beworben",
    },
    {
        value: JOB_APPLICATION_APPLIED,
        label: "zugesagt",
    },
];

export default function JobAlerts() {
    const [status, setStatus] = React.useState(JOB_APPLICATION_ALL);

    return (
        <>
            <Formik
                initialValues={{status: status}}

                onSubmit={(values, {setSubmitting}) => {
                    setStatus(values.status);
                }}
                render={({handleChange, handleBlur, values, errors, handleSubmit}) => (
                    <Form>
                        <h1>Events</h1>
                        <Field component={FormikJobAlertStatusSlider} name="status"
                               label="Status"
                               marks={statusMarks}
                        />
                    </Form>
                )}
            />

                <JobAlertListView status={status}/>
            </>

    )


}
