import React from 'react'
import Slider from '@material-ui/core/Slider';

function experienceValueLabelFormat(value) {
    switch (value) {
        case 0:
            return "keine";
        case 1:
            return "wenig";
        case 2:
            return "mittel";
        case 3:
            return "viel";
        case 4:
            return "sehr viel";
        default:
            return "bitte auswählen";
    }
}


export const FormikExperienceSlider = ({field, form, ...props}) => {

    return (
        <div>
            <p style={{margin:'0'}}>{props.label}: {experienceValueLabelFormat(field.value)}</p>
            <Slider
                min={props.min}
                max={props.max}
                aria-label={field.name}
                name={field.name}
                value={field.value}
                valueLabelDisplay="off"
                onChange={(event, value) => {
                    form.setFieldValue(field.name, value)
                }}
                {...props}
            />
        </div>
    );
};

