import React from 'react'
import Slider from '@material-ui/core/Slider';

export const FormikGraduationSlider = ({field, form, ...props}) => {

    return (
        <div>
            <p style={{margin:'0'}}>{props.label}</p>
            <Slider
                min={props.min}
                max={props.max}
                aria-label={field.name}
                name={field.name}
                value={field.value}
                valueLabelDisplay="auto"
                onChange={(event, value) => {
                    form.setFieldValue(field.name, value, true)
                }}
                {...props}
            />
        </div>
    );
};

