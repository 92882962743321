import React from 'react';
import appData from "../../../models/ReddworldData";
import "../../../App.scss"
import "../../font/JoannaMT.css"
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import {PhotoUploader} from "../avatar/PhotoUploader";
import "./overview.css"
import Typography from "@material-ui/core/Typography";
import {IconArrowRight, IconMissingData, IconOkay, IconPending, IconXOut} from "../../icons/StatusIcons";
import {
    bankAccountSchema,
    billingAccountingTypeSchema,
    confessionSchema,
    employmentSchema,
    healthInsuranceSchema,
    taxSchema
} from "../billing_data/validationSchemas";
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import {RWRoundButton} from "../../input_elements/buttons/Buttons";


export const Overview = props => {

    // eslint-disable-next-line no-unused-vars
    const [random, setRandom] = React.useState();

    // eslint-disable-next-line no-unused-vars
    function logout() {
        appData.logout();
        setRandom(Math.random());
    }

    return <Container  style={{"padding": "0 1em 0 1em"}}>
        {/*style={{position:"fixed", top:0, left:0, right:0}}*/}
        <Grid xs={12} item={true} >
        {/*<CircleAvatarBig/>*/}
        <br />
        <PhotoUploader/>
        </Grid>
        <Grid xs={12}  item={true}>
        <h2>Hallo, {appData.user.firstname} {appData.user.lastname}</h2>
        </Grid>
        <Grid xs={12} item={true} align={"left"}>
            <Typography variant="subtitle2" component="h3" align={"left"} color={"textPrimary"} style={{ 'marginBottom' : '0.5em'}}>
                Deine aktuellen Kontaktdaten:
            </Typography>
        </Grid>
        <Grid xs={12} item={true} align={"right"}>
            <ContactData setCurrentPage={props.setCurrentPage}/>
        </Grid>

        <Grid xs={12} item={true} align={"left"}>
            <Typography variant="subtitle2" component="h3" align={"left"} color={"textPrimary"} style={{ 'marginBottom' : '0.5em'}}>
                Dein Profilstatus:
            </Typography>
        </Grid>
        <Grid xs={12} item={true} align={"right"}>
            <ProfileStatus setCurrentPage={props.setCurrentPage}/>
        </Grid>
        <Grid xs={12} item={true} align={"right"}>
            <StatusMessageDocuments setCurrentPage={props.setCurrentPage}/>
        </Grid>
        {/*<RWButton variant="outlined" color="primary" text="Outline"/>*/}
        {/*<RWButton variant="contained" color="primary" text="Contained"/>*/}
        {/*<RWButton variant="contained" color="secondary" text="Secondary"/>*/}
        {/*<br/>*/}
        {/*<RWRoundButton variant="outlined" color="primary" text="Outline"/>*/}
        {/*<RWRoundButton variant="contained" color="primary" text="Contained"/>*/}
        {/*<RWRoundButton variant="contained" color="secondary" text="Secondary"/>*/}
        <Grid xs={12} item={true} align={"left"}>
            <Typography variant="subtitle2" component="h3" align={"left"} color={"textPrimary"} style={{ 'marginBottom' : '1em'}}>
                Du hast {appData.newsVisibility.amountOfMessages} neue Nachrichten:
            </Typography>
        </Grid>
        <Grid xs={12} item={true} align={"right"}>
            <NewsNewPaycheck setCurrentPage={props.setCurrentPage}/>
        </Grid>
        <Grid xs={12} item={true} align={"right"}>
            <NewsNewEvents setCurrentPage={props.setCurrentPage}/>
        </Grid>
        <Grid xs={12} item={true} align={"right"}>
            <NewsMissingDocuments setCurrentPage={props.setCurrentPage}/>
        </Grid>
        <Grid xs={12} item={true} align={"right"}>
            <NewsElearning setCurrentPage={props.setCurrentPage}/>
        </Grid>
        <Grid xs={12} item={true} align={"right"}>
            <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title="Hier klicken um zu den verfügbaren Jobs zu springen">
                <div  style={{"display": "inline-flex"}} onClick={() => props.setCurrentPage("JobAlerts")}>
                    <Typography variant="subtitle2" component="h3" align={"right"} color={"textPrimary"} style={{"border": "1px solid #942738" , "padding": "0px 6px 0px 6px" , "borderRadius" : "12px" , "lineHeight" : "16pt", 'marginBottom' : '1em'}}>
                        {appData.availableJobAlerts.length} Jobs verfügbar - Let's go!
                    </Typography>
                    <IconArrowRight />
                {/*<IconXOut/>*/}
                </div>
            </Tooltip>
        </Grid>
        {/*<RWSwitch checked={switchValue}*/}
        {/*          onChange={(event) => setSwitchValue(event.target.checked)}/> {switchValue ? "Switch ist aktiviert" : "Switch ist deaktiviert"}*/}
        {/*<RWButton variant="contained" color="primary" text="Dialog öffnen" onClick={() => setOpenDialog(true)}/>*/}

        {/*<SelectNative options={[{option: "Option 1", value: "Value1"},{option: "Option 2", value: "Value2"}]} />*/}
      {/*<RWModal openDialog={openDialog} handler={setOpenDialog}/>*/}
        <Grid xs={12} item={true} align={"right"}>
            <RWRoundButton variant="outlined" onClick={() => logout()} color="primary" text="Logout"/>
        </Grid>
    </Container>
};

const NewsNewPaycheck = () => {

    // for forcing a rerender of this component, state can be set to a random variable
    // eslint-disable-next-line no-unused-vars
    const [state, setState] = React.useState(null);

    if (appData.newsNewPaycheck && appData.newsVisibility.newPaycheck) {
        return <div  style={{"display": "inline-flex"}}>
                <Typography variant="subtitle2" component="h3" align={"right"} color={"textPrimary"} style={{"border": "1px solid #942738" , "padding": "0px 6px 0px 6px" , "borderRadius" : "12px" , "lineHeight" : "16pt", 'marginBottom' : '1em'}}>
                    Du hast eine neue Gehaltsabrechnung erhalten.
                </Typography>
                <div onClick={() => {
                    appData.newsVisibility.hideNewPaycheck();
                    setState(Math.random());
                }}>
                    <IconXOut/>
                </div>
            </div>
    } else {
        return null;
    }
};

const NewsNewEvents = props => {
    // for forcing a rerender of this component, state can be set to a random variable
    // eslint-disable-next-line no-unused-vars
    const [state, setState] = React.useState(null);

    if(appData.newsNewEvents && appData.newsVisibility.newEvents) {
        return <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title="Hier klicken um die Events zu sehen">
            <div  style={{"display": "inline-flex"}} onClick={() => props.setCurrentPage("JobAlerts")}>
                <Typography variant="subtitle2" component="h3" align={"right"} color={"textPrimary"} style={{"border": "1px solid #942738" , "padding": "0px 6px 0px 6px" , "borderRadius" : "12px" , "lineHeight" : "16pt", 'marginBottom' : '1em'}}>
                    Es sind neue Events für dich verfügbar!
                </Typography>
                <div onClick={() => {
                    appData.newsVisibility.hideNewEvents();
                    setState(Math.random());
                }}>
                    <IconXOut/>
                </div>
            </div>
        </Tooltip>
    } else {
        return null;
    }
};

const NewsMissingDocuments = props => {
    // for forcing a rerender of this component, state can be set to a random variable
    // eslint-disable-next-line no-unused-vars
    const [state, setState] = React.useState(null);

    if (appData.getNewsMissingDocuments().length > 0 && appData.newsVisibility.missingDocuments) {
        return <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title="Hier klicken um zu den Abrechnungsdaten zu springen">
        <div  style={{"display": "inline-flex"}} onClick={() => props.setCurrentPage("BillingData")}>
            <Typography variant="subtitle2" component="h3" align={"right"} color={"textPrimary"} style={{"border": "1px solid #942738" , "padding": "0px 6px 0px 6px" , "borderRadius" : "12px" , "lineHeight" : "16pt", 'marginBottom' : '1em'}}>
                Um dein Gehalt abrechnen zu können benötigen wir noch folgende Dokumente von dir:
                {appData.getNewsMissingDocuments().map((missingDocument, index) => (
                    <div key={index}>{missingDocument}</div>
                ))}
            </Typography>
            <div onClick={() => {
                appData.newsVisibility.hideMissingDocuments();
                setState(Math.random());
            }}>
                <IconXOut/>
            </div>
        </div>
        </Tooltip>
    } else {
        return null;
    }
};

const StatusMessageDocuments = props => {

    if (appData.user.billing_data.files.length > 0 && appData.allDocumentsAccepted) {
        return <div style={{"display": "inline-flex"}}>
            <Typography variant="subtitle2" component="h3" align={"right"} color={"textPrimary"} style={{ 'marginBottom' : '0.5em'}}>
                Deine Dokumente wurden akzeptiert!
            </Typography>
            <IconOkay/>
        </div>
    } else if (appData.user.billing_data.files.length > 0 && !appData.allDocumentsAccepted) {
        return <div style={{"display": "inline-flex"}}>
            <Typography variant="subtitle2" component="h3" align={"right"} color={"textPrimary"} style={{ 'marginBottom' : '0.5em'}}>
                Deine Dokumente werden überprüft!
            </Typography>
            <IconPending/>
        </div>
    } else {
        return null
    }
};

const ProfileStatus = (props) => {
    const bankAccountOkay = bankAccountSchema.isValidSync(appData.user.billing_data.bankAccountData);
    const confessionOkay = confessionSchema.isValidSync(appData.user.billing_data.confession);
    const billingAccountingTypeOkay = billingAccountingTypeSchema.isValidSync(appData.user.billing_data.billingAccountingType);
    const employmentOkay = employmentSchema.isValidSync(appData.user.billing_data.employment);
    const healthInsuranceOkay = healthInsuranceSchema.isValidSync({nationalInsuranceNumber: appData.user.billing_data.nationalInsuranceNumber, ...appData.user.billing_data.healthInsurance});
    const taxOkay = taxSchema.isValidSync(appData.user.billing_data);

    console.log(bankAccountOkay, confessionOkay, billingAccountingTypeOkay, employmentOkay, healthInsuranceOkay, taxOkay);

    if (bankAccountOkay
        && confessionOkay
        && billingAccountingTypeOkay
        && employmentOkay
        && healthInsuranceOkay
        && taxOkay
        && appData.getNewsMissingDocuments().length === 0) {
        return <>
            <div  style={{"display": "inline-flex"}}>
                <Typography variant="subtitle2" component="h3" align={"right"} color={"textPrimary"} style={{ 'marginBottom' : '0.5em'}}>
                    Dein Profil ist bereit zur Abrechnung!
                </Typography>
                <IconOkay/>
            </div>
        </>
    } else {
        return <>
            <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title="Hier klicken um zu den Abrechnungsdaten zu springen">
                <div  style={{"display": "inline-flex"}} onClick={() => props.setCurrentPage("BillingData")}>
                    <Typography variant="subtitle2" component="h3" align={"right"} color={"textPrimary"} style={{ 'marginBottom' : '0.5em'}}>
                        Wir benötigen zur Abrechnung von dir:<br/>
                        {!bankAccountOkay && <>deine Bankdaten<br/></>}
                        {!confessionOkay && <>deine Konfession<br/></>}
                        {!employmentOkay && <>die Infos zu deinen Arbeitgebern<br/></>}
                        {!healthInsuranceOkay && <>deine Krankenversicherungsdaten<br/></>}
                        {!taxOkay && <>deine Steuerdaten<br/></>}
                        {!(appData.getNewsMissingDocuments().length === 0) && <>fehlende Dokumente<br/></>}
                    </Typography>
                    <IconPending/>
                </div>
            </Tooltip>
        </>
    }

};

const ContactData = props => {
    return <>
        <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title="Hier klicken um deine Kontaktdaten zu ändern">
            <div  style={{"display": "inline-flex"}} onClick={() => props.setCurrentPage("PersonalData")}>
                <Typography variant="subtitle2"
                            component="h3"
                            align={"right"}
                            color={"textPrimary"}
                            style={{ 'marginBottom' : '0.5em'}}>
                    E-Mail: {appData.user.email.length > 0 ? appData.user.email : "nicht angegeben!"}<br/>
                    Mobil: {appData.user.mobile.length > 0 ? appData.user.mobile : "nicht angegeben!"}<br/>
                    Adresse: {appData.user.addressAsString.length > 0 ? appData.user.addressAsString : "nicht angegeben!"}<br/>
                </Typography>
                <IconMissingData/>
            </div>
        </Tooltip>
    </>
};

const NewsElearning = props => {

    // for forcing a rerender of this component, state can be set to a random variable
    // eslint-disable-next-line no-unused-vars
    const [state, setState] = React.useState(null);

    if (appData.newsElearnings && appData.newsVisibility.elearnings) {
        return <div  style={{"display": "inline-flex"}} onClick={() => props.setCurrentPage("ELearning")}>
            <Typography variant="subtitle2" component="h3" align={"right"} color={"textPrimary"} style={{"border": "1px solid #942738" , "padding": "0px 6px 0px 6px" , "borderRadius" : "12px" , "lineHeight" : "16pt", 'marginBottom' : '1em'}}>
                Es warten E-Learning Module auf dich!
            </Typography>
            <div onClick={() => {
                appData.newsVisibility.hideElearnings();
                setState(Math.random());
            }}>
                <IconXOut/>
            </div>
        </div>
    } else {
        return null;
    }
};

// function missingDocumentsMessage() {
//     const files = appData.user.billing_data.files;
//
//
// }

