import {types} from "mobx-state-tree"
import {Address} from "./Address"

export const Permission = types.model({
    id: types.number,
    permission: types.maybeNull(types.number)
});

export const JobType = types.model({
    id: types.number,
    jobtype: types.maybe(types.string),
    permission: types.array(Permission),
    shortcut: types.maybe(types.string)
});

export const Location = types.model({
    id: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    address: Address
});

export const Event = types.model({
    id: types.number,
    location: Location,
    name: types.string,
    website: types.maybe(types.string),
    description: types.maybe(types.string)
});

export const JobAlert = types.model({
    image: types.maybe(types.string),
    id: types.number,
    title: types.string,
    description: types.optional(types.string, ""),
    start: types.string,
    end: types.string,
    workingStart: types.string,
    workingEnd: types.string,
    jobType: types.array(JobType),
    event: Event
}).views(self => ({
    get month() {
        let startdate_array = self.workingStart.split(".");
        // for some reason, javascript Date starts the month at 0...
        // let startDate = new Date(startdate_array[2], (parseInt(startdate_array[1]) - 1), startdate_array[0]);
        return parseInt(startdate_array[1])
    },
    get year() {
        let startdate_array = self.workingStart.split(".");
        // for some reason, javascript Date starts the month at 0...
        // let startDate = new Date(startdate_array[2], (parseInt(startdate_array[1]) - 1), startdate_array[0]);
        return parseInt(startdate_array[2])
    },
}));
//.actions(self => ({
//         // api calls
//         getCurrentJobAlertsForUser: flow(function* getCurrentJobAlerts() { // <- note the star, this a generator function!
//             try {
//                 // ... yield can be used in async/await style
//                 const user = yield axios.post(API_JOBS, {
//                     action: '',
//                     auth_token: localStorage.getItem('jwt')
//                 }).then((response) => {
//                     if (response.status === 200) {
//                         return response.data
//                     }
//                 }, (error) => {
//                     console.log(error)
//                     return false
//                 })
//                 self.firstname = user.firstname
//                 self.lastname = user.lastname
//                 self.email = user.email
//                 self.id = user.id
//                 self.username = user.username
//
//             } catch (error) {
//                 // ... including try/catch error handling
//                 console.error("Failed to fetch user", error)
//             }
//         }),
//         getServiceEmployeeData: flow(function* getServiceEmployeeData() { // <- note the star, this a generator function!
//             try {
//                 // ... yield can be used in async/await style
//                 const user = yield axios.post(API_USERS, {
//                     action: 'getUserServiceEmployeeProfileByID',
//                     auth_token: localStorage.getItem('jwt'),
//                     iduser: self.id
//                 }).then((response) => {
//                     if (response.status === 200) {
//                         return response.data
//
//                     }
//                 }, (error) => {
//                     console.log(error)
//                     return false
//                 })
//                 console.log(user)
//                 self.address.id = user.address.id
//                 self.address.street = user.address.street
//                 self.address.housingNumber = user.address.housingNumber
//                 self.address.city.id = user.address.city.id
//                 self.address.city.city = user.address.city.city
//                 self.address.state.id = user.address.state.id
//                 self.address.state.state = user.address.state.state
//                 self.address.country.id = user.address.country.id
//                 self.address.country.country = user.address.country.country
//                 self.mobile = user.mobile
//                 self.description = user.description_text
//                 self.heard_of = user.heardOfCompany
//
//                 self.service_employee = ServiceEmployee.create()
//                 self.employee_id = parseInt(user.employeeID)
//                 self.date_of_birth = user.dateOfBirth
//                 self.service_employee.body_height = user.bodyHeight
//                 self.service_employee.breast_circumference = parseFloat(user.breastCircumference)
//                 self.service_employee.waist_size = parseFloat(user.waistSize)
//                 self.service_employee.seat_circumference = parseFloat(user.seatCircumference)
//                 self.service_employee.t_shirt_size = user.tShirtSize
//                 self.service_employee.shoe_size = parseFloat(user.shoeSize)
//                 self.service_employee.idhair_color = user.hairColor.id
//                 self.service_employee.has_driver_licence = user.hasDriverLicence
//                 self.service_employee.has_a_security_cert = user.hasASecurityCert
//                 self.service_employee.has_transport_of_persons_sparkle = user.HasTransportOfPersonsSparkle
//                 self.service_employee.is_tattooed = user.isTattooed
//                 self.service_employee.is_pierced = user.isPierced
//                 self.service_employee.has_glasses = user.hasGlasses
//                 self.service_employee.jeans_length = parseFloat(user.jeansLength)
//                 self.service_employee.jeans_width = parseFloat(user.jeansWidth)
//                 self.service_employee.clothes_size = parseFloat(user.clothesSize)
//                 self.service_employee.prefers_day_shift = user.prefersDayShift
//                 self.service_employee.prefers_night_shift = user.prefersNightShift
//             } catch (error) {
//                 // ... including try/catch error handling
//                 console.error("Failed to fetch user", error)
//             }
//         })
//     })
// )
