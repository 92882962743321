import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import 'animate.css'
import './LoadingPage.css'
import r from '../../../img/r.svg'

export function LoadingPage (props) {

    return (
        <div>
            <CircularProgress variant="static" value={(props.percent * 100)} style={{width: 128, height: 128, marginTop: "50%", backgroundImage: `url(${r})`, backgroundPosition: "center", backgroundRepeat: "no-repeat"}}/>
            <br /><br /><br /><br /><br />
            <span>Bitte habe einen Moment Geduld.<br />
            Die reddworld lädt die Daten...</span>

    </div>
    )
}

