// this defines the state tree model with all submodels and backend interactions
import {detach, flow, onPatch, types} from "mobx-state-tree";
import {JobAlert} from "./JobAlert";
import {ELearning} from "./ELearning";
import {User} from "./User";
import {ErrorMessage} from "./ErrorMessage";
import axios from "axios";
import {
    BILLING_ACCOUNTING_TYPE_SCHOOL,
    BILLING_ACCOUNTING_TYPE_STUDENT,
    BILLING_ACCOUNTING_TYPE_STUDENT_APPLICANT,
    BILLING_ACCOUNTING_TYPE_UNEMPLOYED,
    BILLING_ACCOUNTING_TYPE_UNEMPLOYED_NO_REMUNERATION,
    BILLING_DATA_FILE_NOT_UPLOADED_YET,
    BILLING_DATA_FILE_PENDING,
    ERROR_SEVERE,
    JOB_APPLICATION_APPLIED,
    JOB_APPLICATION_PENDING,
    PERSONEL_QUESTIONAIRE_TYPE_EMPLOYEE,
    PERSONEL_QUESTIONAIRE_TYPE_STUDENT,
    PERSONEL_QUESTIONAIRE_TYPE_UNEMPLOYEED,
    PERSONEL_QUESTIONAIRE_TYPE_UNEMPLOYEED_WITHOUT_REMUNERATION
} from "../constants/constants";
import {API_ELEARNING, API_FILES, API_JOBS, API_JOBS_LOCAL, API_USERS, API_USERS_LOCAL} from '../constants/api';
import {AutoComplete} from "./AutoComplete";

const NewsVisibility = types.model({
    newPaycheck: types.boolean,
    newEvents: types.boolean,
    missingDocuments: types.boolean,
    elearnings: types.boolean
}).actions(self => ({
    hideNewPaycheck() {
        console.log("hide new paycheck");
        self.newPaycheck = false;
    },
    hideNewEvents() {
        console.log("hide new events");
        self.newEvents = false;
    },
    hideMissingDocuments() {
        console.log("hide missing documents");
        self.missingDocuments = false;
    },
    hideElearnings() {
        console.log("hide elearnings");
        self.elearnings = false;
    }
})).views(self => ({
    get amountOfMessages() {
        return self.newPaycheck + self.newEvents + self.missingDocuments + self.elearnings;
    },

}));

const ReddworldData = types.model({
    user: User,
    availableJobAlerts: types.array(JobAlert),
    appliedJobAlerts: types.array(JobAlert),
    pendingJobAlerts: types.array(JobAlert),
    elearnings: types.array(ELearning),
    loading: types.boolean,
    loggedIn: types.boolean,
    lastLogin: types.Date,
    errors: types.array(ErrorMessage),
    newsVisibility: NewsVisibility,
    autoComplete: AutoComplete
}).actions(self => ({
    logout() {
        self.loggedIn = false;
        localStorage.setItem("jwt", null);
        console.log("logged out.");
    },
    setUser(user) {
        let languages = JSON.parse(JSON.stringify(user.languages));
        languages.forEach(language => language.id = parseInt(language.id));
        user.languages = Object.values({...languages});
        self.user = {...user}
    },
    addJobAlert(jobAlert) {
        self.availableJobAlerts.push(jobAlert)
    },
    setLoggedIn(loggedIn) {
        self.loggedIn = loggedIn
    },
    setLoading(loading) {
        self.loading = loading
    },
    setLastLogin(lastLogin) {
        self.lastLogin = new Date(lastLogin);
    },
    addErrorMessage(message, context, severity) {
        // check for empty object
        if (typeof (message.length) === "undefined") {
            message = "";
        }
        self.errors.push({message: message, context: context, severity: severity})
    },
    moveJobAlertFromAvailableToApplied(jobAlertID) {
        let sourceIndex = self.availableJobAlerts.findIndex(jobAlert => jobAlert.id === jobAlertID);
        let jobAlertToBeMoved = self.availableJobAlerts[sourceIndex];

        //self.availableJobAlerts.splice(sourceIndex, 1);
        self.pendingJobAlerts.push(detach(jobAlertToBeMoved));
    },
    getCurrentJobAlertsForUser: flow(function* getCurrentJobAlertsForUser() {
        // <- note the star, this a generator function!
        try {
            // ... yield can be used in async/await style
            // first get the available jobalerts
            const availableJobalerts = yield axios.post(API_JOBS, {
                action: 'getCurrentJobAlertsForUser',
                userID: self.user.id,
                auth_token: localStorage.getItem('jwt')
            }).then((response) => {
                if (response.status === 200) {
                    return response.data
                }
            }, (error) => {
                console.log(error);
                return false
            });
            availableJobalerts.forEach((jobAlert) => {
                self.availableJobAlerts.push(jobAlert)
            });

            // then get the jobalerts where the user has a pending application
            const pendingJobalerts = yield axios.post(API_JOBS, {
                action: 'getCurrentJobAlertsByUserAndStatus',
                status: JOB_APPLICATION_PENDING,
                auth_token: localStorage.getItem('jwt')
            }).then((response) => {
                if (response.status === 200) {
                    return response.data
                }
            }, (error) => {
                console.log(error);
                return false
            });
            Object.values(pendingJobalerts).forEach((jobAlert) => {
                self.pendingJobAlerts.push(jobAlert)
            });

            // at last, get the job alerts where the application was approved
            const appliedJobalerts = yield axios.post(API_JOBS, {
                action: 'getCurrentJobAlertsByUserAndStatus',
                status: JOB_APPLICATION_APPLIED,
                auth_token: localStorage.getItem('jwt')
            }).then((response) => {
                if (response.status === 200) {
                    return response.data
                }
            }, (error) => {
                console.log(error);
                return false
            });
            Object.values(appliedJobalerts).forEach((jobAlert) => {
                self.appliedJobAlerts.push(jobAlert)
            })

        } catch (error) {
            // ... including try/catch error handling
            console.error("Failed to fetch jobalerts", error)
        }
    }),
    // looks for a saved Auth Token (JSON Web Token), which is saved upon Login, and checks it
    // for validity / expiration

    checkForSavedLogin: flow(function* checkForSavedLogin() {
        console.log("checking for saved login");
        // try the login with the auth token. It that doesn't work, a usual Login Form appears.
        try {
            // ... yield can be used in async/await style
            yield axios.post(API_USERS, {
                action: 'authenticate',
                data: {jwt: localStorage.getItem("jwt")}
            }).then((response) => {
                if (response.status === 200) {
                    self.setLoading(false);
                    self.setLoggedIn(response.data.success);
                    if (response.data.success === false) {
                        self.addErrorMessage("Es gab einen Fehler beim Login", "login", ERROR_SEVERE);
                    }
                }
            }, (error) => {
                //self.addErrorMessage(error, "login", ERROR_SEVERE);
                console.log(error);
                self.setLoading(false);
                self.setLoggedIn(false);
            });
        } catch (error) {
            //self.addErrorMessage(error, "login", ERROR_SEVERE);
            self.setLoading(false);
            self.setLoggedIn(false);
            console.error("Failed authenticate Auth Token", error)
        }
    }),
    login: flow(function* login(username, password) {
        // try the login with the given credentials. If that doesn't work, show an error message
        try {
            self.loading = true;
            // ... yield can be used in async/await style
            const returnValue = yield axios.post(API_USERS_LOCAL, {
                action: 'loginUserJWT',
                data: {username: username, password: password}
            }).then((response) => {
                if (response.status === 200) {
                    // save the JSON Web Token that contains the signed data from the server
                    // which can be used to identify the user later.
                    if (response.data.length < 1) {
                        self.setLoggedIn(false);
                        self.setLoading(false);
                        return false;
                    }
                    console.log(response.data);
                    if (response.data.token !== false) {
                        localStorage.setItem("jwt", response.data.token);
                        self.setLastLogin(response.data.lastLogin.date);
                        self.setLoggedIn(true);
                        self.setLoading(false);
                        return true;
                    } else {
                        throw new Error('falsches Passwort');
                    }
                }
            }, (error) => {
                // generate ErrorMessage, show it to the user
                self.setLoggedIn(false);
                self.setLoading(false);
                console.log(error);
                return false;
            });
            return returnValue;
        } catch (error) {
            self.setLoggedIn(false);
            self.setLoading(false);
            console.error("Failed authenticate with credentials", error)
        }
    }),
    resetPassword: flow(function* resetPassword(email) {
        // try the login with the given credentials. If that doesn't work, show an error message
        try {
            self.loading = true;
            console.log("resetting password.");
            //... yield can be used in async/await style
            yield axios.post(API_USERS_LOCAL, {
                action: 'forgotPassword',
                email: email
            }).then((response) => {
                if (response.status === 200) {
                    self.setLoggedIn(false);
                    self.setLoading(false);
                }
            }, (error) => {
                // generate ErrorMessage, show it to the user
                self.setLoggedIn(false);
                self.setLoading(false);
                console.log(error)
            });
        } catch (error) {
            // self.setLoggedIn(false);
            // self.setLoading(false);
            console.error("Failed reset password.", error)
        }
    }),
    saveUserDataToBackend: flow(function* saveUserDataToBackend() {
        // try the login with the given credentials. If that doesn't work, show an error message
        try {
            //self.loading = true;
            console.log("saving userdata to backend.");
            //... yield can be used in async/await style
            yield axios.post(API_USERS_LOCAL, {
                action: 'updateUserReact',
                auth_token: localStorage.getItem('jwt'),
                data: self.user
            }).then((response) => {
                if (response.status === 200) {

                    console.log(response.data);

                }
            }, (error) => {
                // generate ErrorMessage, show it to the user

                console.log(error)
            });
        } catch (error) {
            // self.setLoggedIn(false);
            // self.setLoading(false);
            console.error("Failed to save data to backend.", error)
        }
    }),
    saveBillingDataToBackend: flow(function* saveBillingDataToBackend() {
        // try the login with the given credentials. If that doesn't work, show an error message
        try {
            //self.loading = true;
            console.log("saving billingdata to backend.");
            //... yield can be used in async/await style
            yield axios.post(API_JOBS_LOCAL, {
                action: 'updateBillingDataByUserIDReact',
                auth_token: localStorage.getItem('jwt'),
                data: self.user.billing_data
            }).then((response) => {
                if (response.status === 200) {
                    console.log(response.data[0].message);
                    console.log("new data: " + JSON.stringify(response.data[0].data));
                    self.user.updateBillingData(response.data[0].data);
                }
            }, (error) => {
                // generate ErrorMessage, show it to the user

                console.log(error)
            });
        } catch (error) {
            // self.setLoggedIn(false);
            // self.setLoading(false);
            console.error("Failed to save billingdata to backend.", error)
        }
    }),
    applyForJobAlert: flow(function* applyForJobAlert(jobAlertID) {
        // try the login with the given credentials. If that doesn't work, show an error message
        try {
            //... yield can be used in async/await style
            const returnValue = yield axios.post(API_JOBS_LOCAL, {
                action: 'applyToJobAlert',
                auth_token: localStorage.getItem('jwt'),
                userID: self.user.id,
                status: 2,
                refID: jobAlertID,
            }).then((response) => {
                if (response.status === 200) {
                    self.moveJobAlertFromAvailableToApplied(jobAlertID);
                    console.log(response.data);
                    return !response.data[0].error;
                } else {
                    return false
                }
            }, (error) => {
                // generate ErrorMessage, show it to the user

                console.log(error)
            });
            return returnValue;
        } catch (error) {
            console.error("Failed to apply for jobalert.", error);
            return false
        }
    }),
    getNewsMissingDocuments() {
        // lookup if there are missing documents
        let missingDocuments = [];
        switch (parseInt(self.user.billing_data.billingAccountingType.id)) {
            // Schüler
            case 1:
                if (self.user.billing_data.getSchoolCertificate().status === BILLING_DATA_FILE_NOT_UPLOADED_YET) {
                    missingDocuments.push("Schulbescheinigung");
                }
                break;
            // Student
            case 2:
                switch (appData.user.billing_data.numOfSemesters) {
                    case 0:
                        break;
                    case 1:
                        if (self.user.billing_data.getCurrentSemesterCertificate().status === BILLING_DATA_FILE_NOT_UPLOADED_YET) {
                            missingDocuments.push("Semesterbescheinigung");
                        }
                        break;
                    case 2:
                        if ((self.user.billing_data.getCurrentSemesterCertificate().status === BILLING_DATA_FILE_NOT_UPLOADED_YET) ||
                            (self.user.billing_data.getPreviousSemesterCertificate().status === BILLING_DATA_FILE_NOT_UPLOADED_YET)) {
                            missingDocuments.push("Semesterbescheinigung");
                        }
                        break;
                    case 3:
                        if ((self.user.billing_data.getCurrentSemesterCertificate().status === BILLING_DATA_FILE_NOT_UPLOADED_YET) ||
                            (self.user.billing_data.getPreviousSemesterCertificate().status === BILLING_DATA_FILE_NOT_UPLOADED_YET) ||
                            (self.user.billing_data.getBeforePreviousSemesterCertificate().status === BILLING_DATA_FILE_NOT_UPLOADED_YET)) {
                            missingDocuments.push("Semesterbescheinigung");
                        }
                        break;
                    default:
                        break;
                }
                break;
            // Angestellter
            case 3:
                self.user.billing_data.employment.forEach((employment) => {
                    if (employment.getPayRollFile().status === BILLING_DATA_FILE_NOT_UPLOADED_YET) {
                        missingDocuments.push("Gehaltsabrechnung (" + employment.employer.name + ")");
                    }
                });
                break;
            // Freiberufler
            case 4:
                // deprecated
                break;
            // Studiumsbewerber
            case 5:
                if (self.user.billing_data.getUniversityApplication().status === BILLING_DATA_FILE_NOT_UPLOADED_YET) {
                    missingDocuments.push("Anmeldung Hochschule");
                }
                if (self.user.billing_data.getStatusLetter().status === BILLING_DATA_FILE_NOT_UPLOADED_YET) {
                    missingDocuments.push("Statusschreiben");
                }
                break;
            // Arbeitssuchend gemeldet
            case 6:
                break;
            // Selbstständig
            case 7:
                if (self.user.billing_data.getBusinessRegistration().status === BILLING_DATA_FILE_NOT_UPLOADED_YET) {
                    missingDocuments.push("Gewerbeanmeldung");
                }
                break;
            // Arbeitslos nicht gemeldet
            case 8:
                if (self.user.billing_data.getStatusLetter().status === BILLING_DATA_FILE_NOT_UPLOADED_YET) {
                    missingDocuments.push("Statusschreiben");
                }
                break;
            default:
                break;
        }
        if (self.user.billing_data.getHealthInsuranceCertificates().status === BILLING_DATA_FILE_NOT_UPLOADED_YET) {
            missingDocuments.push("Krankenkassenbescheinigung");
        }
        return missingDocuments;
    },
    generatePDF: flow(function* generatePDF() {
        // <- note the star, this a generator function!
        try {
            let questionnaire;
            if (
                self.user.billing_data.billingAccountingType.id === BILLING_ACCOUNTING_TYPE_SCHOOL
                || self.user.billing_data.billingAccountingType.id === BILLING_ACCOUNTING_TYPE_STUDENT
                || self.user.billing_data.billingAccountingType.id === BILLING_ACCOUNTING_TYPE_STUDENT_APPLICANT
            ) questionnaire = PERSONEL_QUESTIONAIRE_TYPE_STUDENT;
            else if (self.user.billing_data.billingAccountingType.id === BILLING_ACCOUNTING_TYPE_UNEMPLOYED)
                questionnaire = PERSONEL_QUESTIONAIRE_TYPE_UNEMPLOYEED;
            else if (self.user.billing_data.billingAccountingType.id === BILLING_ACCOUNTING_TYPE_UNEMPLOYED_NO_REMUNERATION)
                questionnaire = PERSONEL_QUESTIONAIRE_TYPE_UNEMPLOYEED_WITHOUT_REMUNERATION;
            else questionnaire = PERSONEL_QUESTIONAIRE_TYPE_EMPLOYEE;

            // eslint-disable-next-line no-unused-vars
            const response = yield axios.post(API_FILES, {
                action: 'createPersonnelQuestionnairePDF',
                iduser: self.user.id,
                idquestionnaire: questionnaire,
                auth_token: localStorage.getItem('jwt')
            }).then((response) => {
                if (response.status === 200) {
                    return response.data
                }
            }, (error) => {
                console.log(error);
                return false
            });
        } catch (error) {
            // ... including try/catch error handling
            console.error("Failed to fetch jobalerts", error)
        }
    }),
    getElearningsForUser: flow(function* getElearningsForUser() {
        // <- note the star, this a generator function!
        try {
            // ... yield can be used in async/await style
            // first get the available jobalerts
            const elearnings = yield axios.post(API_ELEARNING, {
                action: 'loadElearningsForUser',
                iduser: self.user.id,
                auth_token: localStorage.getItem('jwt')
            }).then((response) => {
                if (response.status === 200) {
                    return response.data
                }
            }, (error) => {
                console.log(error);
                return false
            });
            elearnings.forEach((obj) => {
                let date_passed, valid_until, status;
                if(typeof(obj.date_passed) !== "undefined") {
                    date_passed = new Date(obj.date_passed.date);
                    if (obj.result >= 50) {
                        status = "bestanden";
                    } else {
                        status = "nicht bestanden";
                    }
                } else {
                    date_passed = null;
                    status = "noch nicht abgelegt";
                }
                if(typeof(obj.valid_until) !== "undefined") {
                    valid_until = new Date(obj.valid_until.date);
                } else {
                    valid_until = null;
                }
                let elearning = {
                    id: obj.id,
                    path: obj.path,
                    name: obj.name,
                    result: obj.result,
                    status: status,
                    standard_valid_for_days: obj.standard_valid_for_days,
                    date_passed: date_passed,
                    date_until_necessary: new Date(obj.date_until_necessary.date),
                    valid_until: valid_until
                };
                self.elearnings.push(elearning)
            });
        } catch (error) {
            // ... including try/catch error handling
            console.error("Failed to fetch elearnings", error)
        }
    }),

})).views(self => ({
    get allDocumentsAccepted() {

        let accepted = true;

        if (self.user.billing_data.files.length === 0) {
            return false;
        }

        self.user.billing_data.files.forEach((file) => {
            if (file.status === BILLING_DATA_FILE_PENDING) {
                accepted = false;
            }
        });

        return accepted;
    },
    get newsNewPaycheck() {
        // iterate through all the billing files - return true if a new paycheck has been uploaded since last login
        let newPaycheck = false;
        self.user.billing_data.files.forEach((file) => {
            if (file.fileType.id === 15 && file.date < self.lastLogin) {
                newPaycheck = true;
            }
        });
        return newPaycheck;
    },
    get newsNewEvents() {
        // sinnvoll?
        return true;
    },
    get newsElearnings() {
        // iterate through all the billing files - return true if a new paycheck has been uploaded since last login
        let notPassedElearnings = false;
        self.elearnings.forEach((elearning) => {
            if(elearning.status !== "bestanden") {
                notPassedElearnings = true;
            }
        });
        return notPassedElearnings;
    },
    get jobAlertsByMonthAvailable() {
        let jobAlertsGroupedByMonth = {
            1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [], 12: []
        };
        self.availableJobAlerts.forEach((jobAlert) => {
            jobAlertsGroupedByMonth[jobAlert.month].push(jobAlert);
        });
        return jobAlertsGroupedByMonth;
    },
    get jobAlertsByMonthPending() {
        let jobAlertsGroupedByMonth = {
            1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [], 12: []
        };
        self.pendingJobAlerts.forEach((jobAlert) => {
            jobAlertsGroupedByMonth[jobAlert.month].push(jobAlert);
        });
        return jobAlertsGroupedByMonth;
    },
    get jobAlertsByMonthApplied() {
        let jobAlertsGroupedByMonth = {
            1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [], 12: []
        };
        self.appliedJobAlerts.forEach((jobAlert) => {
            jobAlertsGroupedByMonth[jobAlert.month].push(jobAlert);
        });
        return jobAlertsGroupedByMonth;
    },
    get jobAlertsByMonthAll() {
        let jobAlertsGroupedByMonth = {
            1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [], 12: []
        };
        self.availableJobAlerts.forEach((jobAlert) => {
            jobAlertsGroupedByMonth[jobAlert.month].push(jobAlert);
        });
        self.appliedJobAlerts.forEach((jobAlert) => {
            jobAlertsGroupedByMonth[jobAlert.month].push(jobAlert);
        });
        self.pendingJobAlerts.forEach((jobAlert) => {
            jobAlertsGroupedByMonth[jobAlert.month].push(jobAlert);
        });
        return jobAlertsGroupedByMonth;
    },
}));


const initialstate = {
    user: {
        id: 0,
        username: "MaxMuster",
        firstname: "Max",
        lastname: "Mustermann",
        address: {
            id: 123,
            street: "Teststraße",
            housingNumber: "1",
            zipcode: "12345",
            city: {
                id: 1,
                city: "Testhausen"
            },
            state: {
                id: 1,
                state: "Teststaat"
            },
            country: {
                id: 5,
                country: "Deutschland"
            }
        },
        gender: {
            id: 2,
        },
        date_of_birth: new Date(1990, 6, 22),
        place_of_birth: "Musterstadt",
        citizenship: {id: 153, citizenship: "Deutsch"},
        mobile: "+123456789",
        email: "test@asdf.xx",
        heard_of: "Marie Musterfrau",
        description: "Freitext",
    },
    availableJobAlerts: [
        // {
        //     "id": 603,
        //     "image": "5bfc08b03102a7c2dccc0d534c6ae86c337d4d3f8728d.jpg",
        //     "title": "Blechexpo Stuttgart",
        //     "description": "Für unsere Kunden suchen wir Hostessen [w].<br />\r\nEinsatzzeitraum: 05. - 08.11.2019 | ganztägig<br />\r\nVergütung: ab 12€ über LST [abhängig vom Aufgabenbereich]",
        //     "event": {
        //         "id": 506,
        //         "name": "Blechexpo Stuttgart",
        //         "location": {
        //             "id": 280,
        //             "name": "Messe Stuttgart",
        //             "address": {
        //                 "id": 5233,
        //                 "housingNumber": "1",
        //                 "street": "Messepiaza ",
        //                 "zip": " 70629",
        //                 "city": {
        //                     "id": 13221,
        //                     "city": "Stuttgart "
        //                 },
        //                 "state": {
        //                     "id": null,
        //                     "state": ""
        //                 },
        //                 "country": {
        //                     "id": 0,
        //                     "country": ""
        //                 }
        //             },
        //             "website": null,
        //             "description": null
        //         }
        //     },
        //     "jobType": [
        //         {
        //             "id": 3,
        //             "jobtype": "Hostess Service",
        //             "shortcut": "HS",
        //             "permission": [
        //                 {
        //                     "id": 1,
        //                     "permission": null
        //                 },
        //                 {
        //                     "id": 2,
        //                     "permission": null
        //                 },
        //                 {
        //                     "id": 3,
        //                     "permission": null
        //                 },
        //                 {
        //                     "id": 4,
        //                     "permission": null
        //                 }
        //             ]
        //         }
        //     ],
        //     "start": "26.11.2018",
        //     "end": "08.11.2019",
        //     "workingStart": "05.11.2019",
        //     "workingEnd": "08.11.2019"
        // },
        // {
        //     "id": 604,
        //     "image": "5bfc08b03102a7c2dccc0d534c6ae86c337d4d3f8728d.jpg",
        //     "title": "Test2",
        //     "description": "Für unsere Kunden suchen wir Hostessen [w].<br />\r\nEinsatzzeitraum: 05. - 08.11.2019 | ganztägig<br />\r\nVergütung: ab 12€ über LST [abhängig vom Aufgabenbereich]",
        //     "event": {
        //         "id": 506,
        //         "name": "Blechexpo Stuttgart",
        //         "location": {
        //             "id": 280,
        //             "name": "Messe Stuttgart",
        //             "address": {
        //                 "id": 5233,
        //                 "housingNumber": "1",
        //                 "street": "Messepiaza ",
        //                 "zip": " 70629",
        //                 "city": {
        //                     "id": 13221,
        //                     "city": "Stuttgart "
        //                 },
        //                 "state": {
        //                     "id": null,
        //                     "state": ""
        //                 },
        //                 "country": {
        //                     "id": 0,
        //                     "country": ""
        //                 }
        //             },
        //             "website": null,
        //             "description": null
        //         }
        //     },
        //     "jobType": [
        //         {
        //             "id": 3,
        //             "jobtype": "Hostess Service",
        //             "shortcut": "HS",
        //             "permission": [
        //                 {
        //                     "id": 1,
        //                     "permission": null
        //                 },
        //                 {
        //                     "id": 2,
        //                     "permission": null
        //                 },
        //                 {
        //                     "id": 3,
        //                     "permission": null
        //                 },
        //                 {
        //                     "id": 4,
        //                     "permission": null
        //                 }
        //             ]
        //         }
        //     ],
        //     "start": "26.11.2018",
        //     "end": "08.11.2019",
        //     "workingStart": "05.11.2019",
        //     "workingEnd": "08.11.2019"
        // }
    ],
    loading: true,
    loggedIn: false,
    lastLogin: new Date(),
    errors: [{
        message: "Ein Fehler in der Reddworld",
        context: "Übersicht",
        severity: 1
    }, {
        message: "Noch ein Fehler in der Reddworld, langsam wirds eng..",
        context: "Abrechnung",
        severity: 2
    }],
    newsVisibility: {
        newPaycheck: true,
        newEvents: true,
        missingDocuments: true,
        elearnings: true,
    },
    autoComplete: {
        healthInsurances: []
    }
};

// this creates the central datastore, with appData, every piece of information about the user, the
// job alerts, billing data etc, can be accessed from anywhere n the app
const appData = ReddworldData.create(initialstate);

// this outputs every change to the state tree to the console, useful for debugging
onPatch(appData, (patch) => {
    //console.log(patch)
    //console.dir(getSnapshot(appData))
});
export default appData
