import React from 'react'
import Slider from '@material-ui/core/Slider';

function degreeOfKnowledgeValueLabelFormat(value) {
    switch (value) {
        case 1:
            return "Grundkenntnis";
        case 2:
            return "sicherer Umgang";
        case 3:
            return "Fließend / Perfekt";
        case 4:
            return "Muttersprache";
        default:
            return "bitte auswählen";
    }
}


export const FormikDegreeOfKnowledgeSlider = ({field, form, ...props}) => {

    return (
        <div>
            <p style={{margin:'0'}}>{props.label}: {degreeOfKnowledgeValueLabelFormat(field.value)} {props.unit}</p>
            <Slider
                min={props.min}
                max={props.max}
                aria-label={field.name}
                name={field.name}
                value={field.value}
                valueLabelDisplay="off"
                onChange={(event, value) => {
                    form.setFieldValue(field.name, value, true)
                }}
                {...props}
            />
        </div>
    );
};

