import React from "react";
import MomentUtils from "@date-io/moment";

import {DatePicker} from "@material-ui/pickers";
import {MuiPickersUtilsProvider} from '@material-ui/pickers/MuiPickersUtilsProvider'


export const FormikDatePicker = ({field, form, ...props}) => {

    const currentError = form.errors[field.name];

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="de">
            <DatePicker {...field} {...props}
                        name={field.name}
                        value={field.value}
                        format="D.M.YYYY"
                        helperText={currentError}
                        error={Boolean(currentError)}
                        onError={(_, error) => form.setFieldError(field.name, error)}
                        onChange={date => {
                            let d = new Date();
                            d.setHours( d.getHours() + 12 );
                            d.setTime(date);
                            form.setFieldValue(field.name, d, true)
                        }}
            />
            </MuiPickersUtilsProvider>
        </div>
    );
};
