import {flow, types} from "mobx-state-tree"
import {Address} from "./Address"
import {ServiceEmployee} from "./ServiceEmployee"
import {BillingData} from "./BillingData"
import {API_BASE, API_FILES, API_JOBS, API_JOBS_LOCAL, API_USERS} from "../constants/api"
import axios from "axios"
import {Image} from "./Image";
import {unionBy} from 'lodash';
import * as moment from 'moment';

const Citizenship = types.model({
    id: types.maybeNull(types.union(types.number, types.string)),
    citizenship: types.string
});

const DegreeOfKnowledge = types.model({
    id: types.number,
    knowledge: types.string
});

const Language = types.model({
    id: types.number,
    language: types.string,
    degreeOfKnowledge: DegreeOfKnowledge
});

// this is how to define a model in mobx
export const User = types.model({
    id: types.number,
    employee_id: types.maybe(types.integer),
    username: types.string,
    firstname: types.string,
    lastname: types.string,
    // TODO only for debug purposes, delete the maybe type later
    address: types.maybe(Address),
    date_of_birth: types.Date,
    place_of_birth: types.string,
    citizenship: Citizenship,
    gender: types.model({
        id: types.number,
    }),
    mobile: types.string,
    email: types.string,
    heard_of: types.maybe(types.string),
    description: types.maybe(types.string),
    service_employee: types.maybe(ServiceEmployee),
    languages: types.optional(types.array(Language), []),
    billing_data: types.maybe(BillingData),
    images: types.maybe(types.array(Image))
}).actions(self => ({
        changeFirstname(newName) {
            self.firstname = newName
        },
        setBillingData(billingData) {
            // dirty workaround, since the select elements always return a string, but a number is needed.
            // JSON.parse(JSON.stringify(obj)) creates a clean copy of an object

            // mitigated by changing the type of the attributes to a union type of number and string - the
            // php backend doesn't care anyway what type the variables have.

            // let confession = JSON.parse(JSON.stringify(self.billing_data.confession));
            // confession.id = parseInt(confession.id);
            // billingData.confession = confession;
            //
            // let billingAccountingType = JSON.parse(JSON.stringify(self.billing_data.billingAccountingType));
            // billingAccountingType.id = parseInt(billingAccountingType.id);
            // billingData.billingAccountingType = billingAccountingType;
            //
            // let healthInsuranceType = JSON.parse(JSON.stringify(self.billing_data.healthInsuranceType));
            // healthInsuranceType.id = parseInt(healthInsuranceType.id);
            // billingData.healthInsuranceType = healthInsuranceType;

            billingData.employment = JSON.parse(JSON.stringify(billingData.employment));
            billingData.employment.forEach((employment) => {
                employment.start = new Date(employment.start);
                employment.end = new Date(employment.end);
            });

            console.log("updating billing data", JSON.stringify(billingData));
            self.billing_data = {...billingData};
        },
        // api calls
        getUserData: flow(function* getUserData() { // <- note the star, this a generator function!
            try {
                // ... yield can be used in async/await style
                const user = yield axios.post(API_USERS, {
                    action: 'getCurrentUser',
                    auth_token: localStorage.getItem('jwt')
                }).then((response) => {
                    if (response.status === 200) {
                        return response.data
                    }
                }, (error) => {
                    console.log(error);
                    return false
                });
                self.firstname = user.firstname;
                self.lastname = user.lastname;
                self.email = user.email;
                self.id = user.id;
                self.username = user.username;
                self.citizenshop = user.citizenship;
                //self.gender.id = user.gender.id;

            } catch (error) {
                // ... including try/catch error handling
                console.error("Failed to fetch user", error)
            }
        }),
        getServiceEmployeeData: flow(function* getServiceEmployeeData() { // <- note the star, this a generator function!
            try {
                // ... yield can be used in async/await style
                const user = yield axios.post(API_USERS, {
                    action: 'getUserServiceEmployeeProfileByID',
                    auth_token: localStorage.getItem('jwt'),
                    iduser: self.id
                }).then((response) => {
                    if (response.status === 200) {
                        return response.data

                    }
                }, (error) => {
                    console.log(error);
                    return false
                });
                //console.log(user);
                self.gender.id = user.gender.id;
                self.address.id = user.address.id;
                self.address.street = user.address.street;
                self.address.housingNumber = user.address.housingNumber;
                self.address.zip = user.address.zip;
                self.address.city.id = user.address.city.id;
                self.address.city.city = user.address.city.city;
                self.address.state.id = user.address.state.id;
                self.address.state.state = user.address.state.state;
                self.address.country.id = user.address.country.id;
                self.address.country.country = user.address.country.country;
                self.mobile = user.mobile;
                self.description = user.description_text;
                self.heard_of = user.heardOfCompany;
                self.service_employee = ServiceEmployee.create();
                self.employee_id = parseInt(user.employeeID);
                self.place_of_birth = user.placeOfBirth.city;
                // parse the german format for dates into a javascript compatible format and set the date:
                let dob_array = user.dateOfBirth.split(".");
                // for some reason, javascript Date starts the month at 0...
                let dob = new Date(dob_array[2], (parseInt(dob_array[1]) - 1), dob_array[0]);

                self.date_of_birth = dob;

                self.service_employee.body_height = user.bodyHeight;

                if(typeof(user.breastCircumference) !== "undefined") {
                    self.service_employee.breast_circumference = parseFloat(user.breastCircumference);
                }

                if(typeof(user.waistSize) !== "undefined") {
                    self.service_employee.waist_size = parseFloat(user.waistSize);
                }

                if(typeof(user.seatCircumference) !== "undefined") {
                    self.service_employee.seat_circumference = parseFloat(user.seatCircumference);
                }

                switch (user.tShirtSize) {
                    case "XXS":
                        self.service_employee.t_shirt_size = 1;
                        break;
                    case "XS":
                        self.service_employee.t_shirt_size = 2;
                        break;
                    case "S":
                        self.service_employee.t_shirt_size = 3;
                        break;
                    case "M":
                        self.service_employee.t_shirt_size = 4;
                        break;
                    case "L":
                        self.service_employee.t_shirt_size = 5;
                        break;
                    case "XL":
                        self.service_employee.t_shirt_size = 6;
                        break;
                    case "XXL":
                        self.service_employee.t_shirt_size = 7;
                        break;
                    default:
                        self.service_employee.t_shirt_size = 1
                }

                if(typeof(user.shoeSize) !== "undefined") {
                    self.service_employee.shoe_size = parseFloat(user.shoeSize);
                }

                if(typeof user.hairColor !== "undefined") {
                    self.service_employee.hairColor = {
                        id: user.hairColor.id,
                        haircolor: user.hairColor.haircolor
                    };
                }

                self.service_employee.has_driver_licence = user.hasDriverLicence;
                self.service_employee.has_a_security_cert = user.hasASecurityCert;
                self.service_employee.has_transport_of_persons_sparkle = user.HasTransportOfPersonsSparkle;
                self.service_employee.is_tattooed = user.isTattooed;
                self.service_employee.is_pierced = user.isPierced;
                self.service_employee.has_glasses = user.hasGlasses;

                if(typeof(user.jeansLength) !== "undefined") {
                    self.service_employee.jeans_length = parseFloat(user.jeansLength);
                }

                if(typeof(user.jeansWidth) !== "undefined") {
                    self.service_employee.jeans_width = parseFloat(user.jeansWidth);
                }

                if(typeof(user.clothesSize) !== "undefined") {
                    self.service_employee.clothes_size = parseFloat(user.clothesSize);
                }

                self.service_employee.prefers_day_shift = !!user.prefersDayShift;
                self.service_employee.prefers_night_shift = !!user.prefersNightShift;
                self.service_employee.graduation = user.graduation;


                // arrays
                try {
                    let languages = [];
                    if(typeof(user.languages) !== "undefined") {
                        user.languages.forEach((language) => {
                            languages.push({
                                id: parseInt(language.id),
                                language: language.language,
                                degreeOfKnowledge: {
                                    id: language.degreeOfKnowledge.id,
                                    knowledge: language.degreeOfKnowledge.knowledge
                                }
                            })
                        });
                    }
                    if(languages.length === 0) {
                        languages.push({
                            id: -1,
                            language: "Deutsch",
                            degreeOfKnowledge: {
                                id: 1,
                                knowledge: "asdf"
                            }
                        })
                    }
                    self.languages = languages;
                } catch (error) {
                    console.log("Failed to fetch languages", error)
                }

                try {

                    if (typeof(user.jobTypeExperienceRating) !== "undefined") {
                        user.jobTypeExperienceRating.forEach((jobTypeExperienceRating) => {
                            switch(jobTypeExperienceRating.jobType.id) {
                                case 1:
                                    self.service_employee.cleaningExperience.value = jobTypeExperienceRating.value;
                                    break;
                                case 3:
                                    self.service_employee.hostessExperience.value = jobTypeExperienceRating.value;
                                    break;
                                case 4:
                                    self.service_employee.securityExperience.value = jobTypeExperienceRating.value;
                                    break;
                                case 6:
                                    self.service_employee.promotionExperience.value = jobTypeExperienceRating.value;
                                    break;
                                case 8:
                                    self.service_employee.gastroExperience.value = jobTypeExperienceRating.value;
                                    break;
                                case 11:
                                    self.service_employee.salesExperience.value = jobTypeExperienceRating.value;
                                    break;
                                default:
                                    break;
                            }
                        });
                    }
                    console.log("experience:\n hostess:" +self.service_employee.hostessExperience.value+ " security: "+self.service_employee.securityExperience.value+" cleaning: "+self.service_employee.cleaningExperience.value);
                } catch (error) {
                    console.log("Failed to fetch job type experiences", error)
                }

                try {
                    let accommodationOptions = [];

                    if (typeof(user.accommodationOptions) !== "undefined") {
                        user.accommodationOptions.forEach((accommodationOption) => {
                            accommodationOptions.push({
                                id: accommodationOption.id,
                                city: accommodationOption.city,
                                checked: true
                            })
                        });
                    }

                    const cities = [
                        {id: 128, city: "Berlin", checked: false},
                        {id: 415, city: "Köln", checked: false},
                        {id: 469, city: "Düsseldorf", checked: false},
                        {id: 516, city: "Friedrichshafen", checked: false},
                        {id: 570, city: "Frankfurt am Main", checked: false},
                        {id: 702, city: "Hamburg", checked: false},
                        {id: 925, city: "Stuttgart", checked: false},
                        {id: 1485, city: "Hannover", checked: false},
                        {id: 2124, city: "Nürnberg", checked: false},
                        {id: 2759, city: "Essen", checked: false},
                        {id: 4332, city: "München", checked: false}
                    ];

                    let accommodationOptionsMerged = unionBy(accommodationOptions, cities, 'id');
                    self.service_employee.accommodationOptions = accommodationOptionsMerged;
                } catch (error) {
                    //console.error("Failed to fetch accommodation options", error);
                    console.log("Failed to fetch accommodation options")
                }

                try {
                    let educations = [];
                    let apprenticeship = {
                        subject: {
                            id: 0,
                            subject: ""
                        }
                    };

                    if (typeof(user.finalDegree) !== "undefined") {
                        user.finalDegree.forEach((education) => {
                            if (typeof(education.university) === "undefined") {
                                  apprenticeship = {
                                      subject:{
                                          id: education.subject.id,
                                          subject: education.subject.subject
                                      }
                                  }
                            } else {
                                educations.push({
                                    done: education.done,
                                    degree: {
                                        id: education.degree.id,
                                        degree: education.degree.degree
                                    },
                                    subject: {
                                        id: education.subject.id,
                                        subject: education.subject.subject
                                    },
                                    semester: education.semester,
                                    university: {
                                        id: education.university.id,
                                        university: education.university.university,
                                    }
                                })
                            }

                        });
                    }
                    let currentDegree, finishedDegree;
                    try {
                        currentDegree = educations.filter(education =>
                            education.done === false && education.subject !== null)[0];
                    } catch (e) {
                        // no current Degree
                    }

                    try {
                        finishedDegree = educations.filter(education =>
                            education.done === true && education.subject !== null)[0];
                    } catch (e) {
                        // no finished Degree
                    }

                    self.service_employee.currentDegree = currentDegree;
                    self.service_employee.finishedDegree = finishedDegree;
                    self.service_employee.apprenticeship = apprenticeship;
                } catch (error) {
                    //console.log("Failed to fetch degrees and education, probably empty...");
                    console.error("Failed to fetch degrees and education, probably empty...", error)
                }

                try {
                    let experiencesForApplication = [];

                    if (typeof(user.experienceForApplication) !== "undefined") {
                        user.experienceForApplication.forEach((experience) => {
                            experiencesForApplication.push({
                                year: experience.year,
                                company: experience.company,
                                job_type: experience.job_type,
                                event: experience.event
                            })
                        });
                    }
                    self.service_employee.experienceForApplication = experiencesForApplication;
                } catch (error) {
                    //console.error("Failed to fetch experiences for application", error)
                    console.log("Failed to fetch experiences for application - probably empty");
                }

            } catch (error) {
                // ... including try/catch error handling
                console.log("Failed to fetch user", error)
            }
        }),
        getBillingData: flow(function* getBillingData() { // <- note the star, this a generator function!
            try {
                // ... yield can be used in async/await style
                let billingdata = yield axios.post(API_JOBS_LOCAL, {
                    action: 'retreiveBillingDataByUserID',
                    auth_token: localStorage.getItem('jwt'),
                    userID: self.id
                }).then((response) => {
                    if (response.status === 200) {
                        return response.data
                    }
                }, (error) => {
                    console.log(error);
                    return false
                });
                console.log(billingdata);

                // if billing data is empty, initialize with empty variables
                if (typeof(billingdata.billingAccountingType) === "undefined") {
                    billingdata = {
                        "id": self.id,
                        "address": {
                            "id": null,
                            "housingNumber": null,
                            "street": null,
                            "zip": null,
                            "city": {"id": null, "city": ""},
                            "state": {"id": null, "state": ""},
                            "country": {"id": 0, "country": ""}
                        },
                        "job_type": [],
                        "billingAccountingType": {"id": null, "billingAccountingType": ""},
                        "taxOffice": {"taxOffice": ""},
                        "taxClass": "I",
                        "healthInsurance": {
                            "id": null,
                            "healthInsurance": "",
                            "address": {
                                "id": null,
                                "housingNumber": "",
                                "street": "",
                                "zip": "",
                                "city": {"id": null, "city": ""},
                                "state": {"id": null, "state": ""},
                                "country": {"id": 0, "country": "Deutschland"}
                            }
                        },
                        "healthInsuranceType": {"id": 0, "healthInsuranceType": "gesetzlich"},
                        "nationalInsuranceNumber": "",
                        "tinTaxNumber": "",
                        "confession": {"id": null, "confession": ""},
                        "bankAccountData": {
                            "iban": "",
                            "bic": "",
                            "creditInstitution": "",
                            "accountHolderDerogation": false,
                            "bankAccountOwner": ""
                        },
                        "employment": [],
                        "documents": [],
                        "numOfSemesters": 0
                    }
                } else {
                    // workaround if health insurance not entirely defined
                    if(typeof(billingdata.healthInsurance.address.city.city) !== "string") {
                        billingdata.healthInsurance.address.city = {id: 0, city: ""};
                    }
                    if(typeof(billingdata.healthInsurance.healthInsurance) !== "string") {
                        billingdata.healthInsurance.healthInsurance = "";
                    }
                    if (typeof(billingdata.employment) !== "undefined") {
                        if (billingdata.employment.length > 0) {
                            billingdata.employment.forEach((employment) => {
                                // workaround if city is not defined
                                if(typeof(employment.employer.address.city.city) !== "string") {
                                    employment.employer.address.city = {id: 0, city: ""};
                                }
                                let start = employment.start.split(".");
                                let end = employment.end.split(".");
                                employment.start = new Date(start[2], start[1] - 1, start[0]);
                                employment.end = new Date(end[2], end[1] - 1, end[0]);
                                //employment.address.zip = employment.address.zip
                                //console.log(employment);
                            });
                        }
                    }
                }
                self.billing_data = billingdata;

            } catch (error) {
                // ... including try/catch error handling
                console.error("Failed to fetch user", error)
            }
        }),
        getUserImages: flow(function* getUserData() { // <- note the star, this a generator function!
            try {
                // ... yield can be used in async/await style
                const responseArray = yield axios.post(API_FILES, {
                    action: 'retreiveUserImagesByID',
                    userID: self.id,
                    auth_token: localStorage.getItem('jwt')
                }).then((response) => {
                    if (response.status === 200) {
                        return response.data
                    }
                }, (error) => {
                    console.log(error);
                    return false
                });
                let images = [];
                try {
                    responseArray.forEach((image) => {
                        images.push({
                            id: image.id,
                            title: image.title,
                            filename: image.filename,
                            date: image.date,
                            url: API_BASE + image.path + image.filename,
                            url_thumbnail: API_BASE + image.path + image.filename_thb
                        })
                    });
                    self.images = images
                } catch (error) {
                    console.error("Failed to fetch user images", error)
                }

            } catch (error) {
                // ... including try/catch error handling
                console.error("Failed to fetch user", error)
            }
        }),
        getBillingFiles: flow(function* getBillingFiles() {
            try {
                const responseArray = yield axios.post(API_JOBS, {
                    action: 'retreiveBillingDataFilesByUserID',
                    userID: self.id,
                    auth_token: localStorage.getItem('jwt')
                }).then((response) => {
                    if (response.status === 200) {
                        return response.data;
                    }
                }, (error) => {
                    console.log(error);
                    return false;
                });
                let files = [];
                try {
                    responseArray.forEach((file) => {
                        file.date = moment(file.date, "DD.MM.YYYY HH:mm").toDate();
                        files.push(file);
                    });
                    console.log(files);
                    self.billing_data.files = files;
                    // initialize with empty array if there are no billing data files.
                    if(responseArray.length === 0) {
                        self.billing_data.files = [];
                    }
                } catch (error) {
                    console.log("No Billing Files were fetched", error);
                }

            } catch (error) {
                // ... including try/catch error handling
                console.error("Failed to fetch user", error);
            }
        }),
        updateBillingData(newData) {
            if (typeof(newData.employment) !== "undefined") {
                if (newData.employment.length > 0) {
                    newData.employment.forEach((employment) => {
                        // workaround if city is not defined
                        if(typeof(employment.employer.address.city.city) !== "string") {
                            employment.employer.address.city = {id: 0, city: ""};
                        }
                        let start = employment.start.split(".");
                        let end = employment.end.split(".");
                        employment.start = new Date(start[2], start[1] - 1, start[0]);
                        employment.end = new Date(end[2], end[1] - 1, end[0]);
                        //employment.address.zip = employment.address.zip
                        //console.log(employment);
                    });
                }
            }
            if(typeof(newData.healthInsurance.address.city.city) !== "string") {
                newData.healthInsurance.address.city = {id: 0, city: ""};
            }
            if(typeof(newData.healthInsurance.healthInsurance) !== "string") {
                newData.healthInsurance.healthInsurance = "";
            }
            if(typeof(newData.billingAccountingType) === "undefined") {
                newData.billingAccountingType = {id: 1, billingAccountingType: ""};
            }
            self.billing_data = newData;
        }
    }),
).views(self => ({
    get avatarUrl() {
        if (typeof (self.images) !== "undefined" && self.images.length > 0) {
            return self.images[0].url
        } else {
            return API_BASE + "/img/icons/rw-icon-data_upload-thumbnail.svg"
        }
    },
    get addressAsString() {
        return self.address.street + " " + self.address.housingNumber + ", " + self.address.zip + " " + self.address.city.city;
    }
}));
