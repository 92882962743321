import {SvgIcon} from "@material-ui/core";
import React from "react";

export const ScrollDownIcon = () => (
    <SvgIcon>
        <svg width="28" height="43" viewBox="0 0 28 43" fill="none">
            <g clip-path="url(#clip0)">
                <path
                    d="M15.932 27.4274L17.25 28.7124L11.625 34.1968L6 28.7124L7.31798 27.4274L11.625 31.6267L15.932 27.4274Z"
                    fill="#8C2332"/>
            </g>
            <rect x="1" y="1" width="21" height="38" rx="9" stroke="#B4233C" strokeWidth="2"/>
            <path
                d="M11.8525 14.1465C12.1338 14.1465 12.3682 14.2285 12.5557 14.3926C12.7432 14.5625 12.8369 14.8145 12.8369 15.1484C12.8369 15.4824 12.7432 15.7344 12.5557 15.9043C12.3682 16.0801 12.1338 16.168 11.8525 16.168C11.583 16.168 11.3574 16.0801 11.1758 15.9043C10.9941 15.7344 10.9033 15.4824 10.9033 15.1484C10.9033 14.8145 10.9941 14.5625 11.1758 14.3926C11.3574 14.2285 11.583 14.1465 11.8525 14.1465ZM11.8525 18.8926C12.1338 18.8926 12.3682 18.9746 12.5557 19.1387C12.7432 19.3086 12.8369 19.5605 12.8369 19.8945C12.8369 20.2285 12.7432 20.4805 12.5557 20.6504C12.3682 20.8262 12.1338 20.9141 11.8525 20.9141C11.583 20.9141 11.3574 20.8262 11.1758 20.6504C10.9941 20.4805 10.9033 20.2285 10.9033 19.8945C10.9033 19.5605 10.9941 19.3086 11.1758 19.1387C11.3574 18.9746 11.583 18.8926 11.8525 18.8926ZM11.8525 23.6387C12.1338 23.6387 12.3682 23.7207 12.5557 23.8848C12.7432 24.0547 12.8369 24.3066 12.8369 24.6406C12.8369 24.9746 12.7432 25.2266 12.5557 25.3965C12.3682 25.5723 12.1338 25.6602 11.8525 25.6602C11.583 25.6602 11.3574 25.5723 11.1758 25.3965C10.9941 25.2266 10.9033 24.9746 10.9033 24.6406C10.9033 24.3066 10.9941 24.0547 11.1758 23.8848C11.3574 23.7207 11.583 23.6387 11.8525 23.6387Z"
                fill="#B4233C"/>
            <defs>
                <clipPath id="clip0">
                    <rect width="11.25" height="23.625" fill="white" transform="translate(6 19)"/>
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);


export const SaveIcon = () => (
    <SvgIcon>
        <svg width="24px" height="24px" viewBox="0 0 24 24">
            <g>
                <path d="M432.823,121.049c-3.806-9.132-8.377-16.367-13.709-21.695l-79.941-79.942c-5.325-5.325-12.56-9.895-21.696-13.704
            C308.346,1.903,299.969,0,292.357,0H27.409C19.798,0,13.325,2.663,7.995,7.993c-5.33,5.327-7.992,11.799-7.992,19.414v383.719
            c0,7.617,2.662,14.089,7.992,19.417c5.33,5.325,11.803,7.991,19.414,7.991h383.718c7.618,0,14.089-2.666,19.417-7.991
            c5.325-5.328,7.987-11.8,7.987-19.417V146.178C438.531,138.562,436.629,130.188,432.823,121.049z M182.725,45.677
            c0-2.474,0.905-4.611,2.714-6.423c1.807-1.804,3.949-2.708,6.423-2.708h54.819c2.468,0,4.609,0.902,6.417,2.708
            c1.813,1.812,2.717,3.949,2.717,6.423v91.362c0,2.478-0.91,4.618-2.717,6.427c-1.808,1.803-3.949,2.708-6.417,2.708h-54.819
            c-2.474,0-4.617-0.902-6.423-2.708c-1.809-1.812-2.714-3.949-2.714-6.427V45.677z M328.906,401.991H109.633V292.355h219.273
            V401.991z M402,401.991h-36.552h-0.007V283.218c0-7.617-2.663-14.085-7.991-19.417c-5.328-5.328-11.8-7.994-19.41-7.994H100.498
            c-7.614,0-14.087,2.666-19.417,7.994c-5.327,5.328-7.992,11.8-7.992,19.417v118.773H36.544V36.542h36.544v118.771
            c0,7.615,2.662,14.084,7.992,19.414c5.33,5.327,11.803,7.993,19.417,7.993h164.456c7.61,0,14.089-2.666,19.41-7.993
            c5.325-5.327,7.994-11.799,7.994-19.414V36.542c2.854,0,6.563,0.95,11.136,2.853c4.572,1.902,7.806,3.805,9.709,5.708l80.232,80.23
            c1.902,1.903,3.806,5.19,5.708,9.851c1.909,4.665,2.857,8.33,2.857,10.994V401.991z"/>
            </g>
        </svg>
    </SvgIcon>
);

export const Line = () => (
    <SvgIcon style={{marginTop: '0.4em'}}>
        <svg width="17" height="1" viewBox="0 0 17 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="17" y1="0.5" x2="-4.37114e-08" y2="0.499999" stroke="#B4233C"/>
        </svg>
    </SvgIcon>
);

// export const Minus = () => (
//     <SvgIcon style={{marginTop: '0.0em'}}>
//         <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 2"><line x1="17" y1="1" y2="1" fill="none" stroke="#B4233C" stroke-width="2"/></svg>
//     </SvgIcon>
// )

export const Minus = () => (
    <SvgIcon style={{marginTop: '0.1em'}}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M16 8H0" stroke="#B4233C" strokeWidth="2"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);


export const Plus = () => (
    <SvgIcon style={{marginTop: '0.1em'}}>
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line
                x1="17"
                y1="8.5"
                x2="0"
                y2="8.5"
                stroke="#B4233C"
                id="line5974"
                style={{strokeWidth: 2, strokeMiterlimit: 4, strokeDasharray: "none"}}/>
            <line
                x1="8.5"
                y1="0"
                x2="8.5"
                y2="17"
                stroke="#B4233C"
                id="line5976"
                style={{strokeWidth: 2, strokeMiterlimit: 4, strokeDasharray: "none"}}/>
        </svg>
    </SvgIcon>
);

export const Construction = () => (
    <SvgIcon viewBox="0 0 70 70" style={{height: "5em", width: "5em"}}>
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M59.2083 35C59.2083 48.4167 48.4167 59.2084 35 59.2084C21.5833 59.2084 10.7917 48.4167 10.7917 35C10.7917 21.5833 21.5833 10.7917 35 10.7917C48.4167 10.7917 59.2083 21.5833 59.2083 35ZM64.1667 35C64.1667 18.9583 51.0417 5.83334 35 5.83334C18.9583 5.83334 5.83333 18.9583 5.83333 35C5.83333 51.0417 18.9583 64.1667 35 64.1667C51.0417 64.1667 64.1667 51.0417 64.1667 35Z"
                fill="#B4233C"/>
            <path
                d="M45.1735 36.4416C45.029 35.8745 44.4954 35.48 43.8715 35.48H41.8038C40.0249 29.4807 36.9673 19.6658 36.6038 18.5211C36.5161 17.941 35.8251 17.66 34.5 17.66C33.1749 17.66 32.4865 17.941 32.3963 18.5185C32.1488 19.3126 30.0708 26.0338 28.3331 31.7727L28.2687 31.8139C28.279 31.8293 28.2945 31.8449 28.3048 31.8629C27.918 33.1468 27.5468 34.3765 27.2194 35.48H25.1285C24.6207 35.48 24.1282 35.8049 23.8781 36.3076L20.6709 46.5015L20.64 47.36C20.64 48.1257 21.1943 48.68 21.96 48.68H47.04C47.8057 48.68 48.36 48.1257 48.36 47.36V46.7L45.1735 36.4416ZM33.6079 19.0573C33.7755 19.0213 34.0772 18.98 34.5 18.98C34.9279 18.98 35.2322 19.0213 35.3972 19.0599C35.5467 19.4827 35.851 20.4392 36.2479 21.7231C36.0546 21.9577 35.3946 22.28 34.5 22.28C33.5203 22.28 32.8603 21.9062 32.7649 21.7592C33.1568 20.4959 33.4559 19.5317 33.6079 19.0573ZM30.8674 27.9596C31.7543 28.5242 33.0743 28.88 34.5 28.88C35.9283 28.88 37.2535 28.5165 38.1506 27.9338C38.5013 29.0888 38.8571 30.2671 39.2051 31.4169C38.3646 32.1671 36.5754 32.84 34.5 32.84C32.4143 32.84 30.6431 32.2213 29.7872 31.5174C30.1404 30.3443 30.5065 29.1404 30.8674 27.9596ZM41.9173 40.8038C41.8502 42.1315 38.6663 44.06 34.5 44.06C30.3363 44.06 27.1034 42.1315 27.0363 40.8038C27.0879 40.5615 27.5546 38.994 27.9387 37.6869C29.4417 38.7465 31.9167 39.44 34.5 39.44C37.0317 39.44 39.5145 38.7027 41.0381 37.5812C41.4351 38.945 41.8632 40.5563 41.9173 40.8038Z"
                fill="#444444"/>
        </svg>
    </SvgIcon>
);
