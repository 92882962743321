import {types} from "mobx-state-tree"

export const Image = types.model({
    id: types.string,
    title: types.optional(types.string, ""),
    filename: types.string,
    url_thumbnail: types.string,
    url: types.string,
    date: types.string
}).actions(self => ({
    setUrl(url) {
        self.url = url;
    },
}));
