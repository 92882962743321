import React from 'react';
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import withStyles from "@material-ui/core/styles/withStyles";
import appData from "../../../models/ReddworldData";
import {Field, FieldArray, Form, Formik} from "formik";
import {TextInput} from "../../input_elements/text_input/TextInput";
import {FormikSlider} from "../../input_elements/slider/FormikSlider";
import {RWSwitch} from "../../input_elements/switch/Switch";
import {RWRoundButton} from "../../input_elements/buttons/Buttons";
import {FormikDatePicker} from "../../input_elements/datepicker/FormikDatePicker";
import {SelectClean, SelectNative} from "../../input_elements/select_native/SelectNative";
import {IconMissingData, IconOkay, IconPending} from "../../icons/StatusIcons";
import {Minus, Plus} from "../../icons/GeneralIcons";
import HealthInsuranceDocuments from "../documents/HealthInsuranceDocuments";
import SemesterCertificates from "../documents/SemesterCertificates";
import {Snackbar} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {amber, blueGrey, green} from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import {FilePreviewUploader} from "../documents/FilePreviewUploader";
import AdditionalDocuments from "../documents/AdditionalDocuments";
import {
    bankAccountSchema,
    billingAccountingTypeSchema,
    billingDataSchema,
    confessionSchema,
    employmentSchema,
    healthInsuranceSchema,
    taxSchema
} from "./validationSchemas";
import {NumberInput} from "../../input_elements/number_input/NumberInput";
import Tooltip from '@material-ui/core/Tooltip';
import Fade from "@material-ui/core/Fade";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autosuggest from 'react-autosuggest';
import "./BillingData.css";

const ExpansionPanel = withStyles({
    root: {
        //border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        "margin-right": 0,
        "margin-left": 0,
        "margin-top": 0,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: '#ffffff',
        //borderBottom: '1px solid rgba(0, 0, 0, .125)',
        margin: "0 0",
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            margin: '0 0',
            '&:first-child': {
                marginTop: 0,
            },
            '&:last-child': {
                marginBottom: 0,
            },
            '&:before': {
                opacity: 0,
            },
        },
        '&$expanded + &': {
            '&:before': {
                display: 'none',
            },
        },
    },
    content: {
        '&$expanded': {
            //margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        background: "#ffffff",
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);

const semesterMarks = [
    {
        value: 1,
        label: 'WS 19/20',
    },
    {
        value: 2,
        label: 'SS 2019',
    },
    {
        value: 3,
        label: '> WS 18/19',
    },
];

function semesterValueLabelFormat(value) {
    switch (value) {
        case 1:
            return "1";
        case 2:
            return "2";
        case 3:
            return "2+";
        default:
            return "";
    }
}

const useSavingStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: blueGrey[300],
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

function FeedbackContentWrapper(props) {
    const classes = useSavingStyles();
    const {className, message, onClose, variant, ...other} = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)}/>
                    {message}
        </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon}/>
                </IconButton>,
            ]}
            {...other}
        />
    );
}

FeedbackContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

// Autosuggestion helper functions for autosuggestion of healthInsurance.
// Teach Autosuggest how to calculate suggestions for any given input value.
const getHealthInsuranceSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : appData.autoComplete.healthInsurances.filter(healthInsurance =>
        healthInsurance.healthInsurance.toLowerCase().slice(0, inputLength) === inputValue
    );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getHealthInsuranceSuggestionValue = suggestion => suggestion.healthInsurance;

// Krankenkasse Vorschlagscontainer
function renderHealthInsuranceSuggestionsContainer({ containerProps , children, query }) {
    return (
        <div {... containerProps}>
            {/*Hier werden die Vorschläge angezeigt: */}
            {children}
        </div>
    );
}

const renderHealthInsuranceSuggestion = suggestion => (
    // Dies ist ein Eintrag für die Vorschlagsliste für die Krankenkassen
    <>
        <p><b>{suggestion.healthInsurance}</b></p><span>{suggestion.address.street} {suggestion.address.housingNumber}, {suggestion.address.zip} {suggestion.address.city.city}</span>
    </>
);

const renderHealthInsuranceInputComponent = inputProps => (
    // Dies ist das Eingabefeld für die Krankenkasse
    <TextField {...inputProps}/>
);

function employmentFile(index) {
    let employment = appData.user.billing_data.employment[index];
    if(typeof employment !== "undefined") {
        return <FilePreviewUploader file={employment.getPayRollFile()}/>
    } else {
        return <></>
    }
}

function payRollList() {
    const payRollFiles = appData.user.billing_data.getPayRollFiles();
    return (
        <>
            {appData.user.billing_data.getPayRollFiles().length > 0 ? <b>Gehaltsabrechnungen</b> : <></>}
            {
                payRollFiles.map((file, index) => (
                <FilePreviewUploader file={file} uploadDisabled={true} key={index}/>
            ))}
        </>
    )
}

export default function BillingData() {
    // const savingFeedbackClasses = useSavingStyles();

    // calculate, how many input fields for additional employers should be shown.
    // if no additional employers are saved in the database, show no fields, and set
    // the amount to 0.

    let amountOfAdditionalEmployersInStore = 0;
    let additionalEmployersInStore = false;
    try {
        amountOfAdditionalEmployersInStore = appData.user.billing_data.employment.length;
        amountOfAdditionalEmployersInStore > 0 ? additionalEmployersInStore = true : additionalEmployersInStore = false;
    } catch (e) {
        additionalEmployersInStore = false;
        amountOfAdditionalEmployersInStore = 0;
    }

    function profileReady() {
        const bankAccountOkay = bankAccountSchema.isValidSync(appData.user.billing_data.bankAccountData);
        const confessionOkay = confessionSchema.isValidSync(appData.user.billing_data.confession);
        const billingAccountingTypeOkay = billingAccountingTypeSchema.isValidSync(appData.user.billing_data.billingAccountingType);
        const employmentOkay = employmentSchema.isValidSync(appData.user.billing_data.employment);
        const healthInsuranceOkay = healthInsuranceSchema.isValidSync({nationalInsuranceNumber: appData.user.billing_data.nationalInsuranceNumber, ...appData.user.billing_data.healthInsurance});
        const taxOkay = taxSchema.isValidSync(appData.user.billing_data);

        return bankAccountOkay
            && confessionOkay
            && billingAccountingTypeOkay
            && employmentOkay
            && healthInsuranceOkay
            && taxOkay
            // && appData.getNewsMissingDocuments().length === 0;
    }

    const [expanded, setExpanded] = React.useState('panel2');
    const [additionalEmployers, setAdditionalEmployers] = React.useState(additionalEmployersInStore);
    const [savingFeedbackOpen, setSavingFeedbackOpen] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const [savingSuccess, setSavingSuccess] = React.useState(false);
    const [savingError, setSavingError] = React.useState(false);

    const [pdfFeedbackOpen, setPdfFeedbackOpen] = React.useState(true);
    const [generatingPdf, setGeneratingPdf] = React.useState(false);
    const [pdfSuccess, setPdfSuccess] = React.useState(false);
    const [pdfError, setPdfError] = React.useState(false);
    const [pdfWarning, setPdfWarning] = React.useState(false);

    // for forcing a rerender
    // eslint-disable-next-line no-unused-vars
    const [random, setRandom] = React.useState(null);

    // holds the suggestions for the autosuggestion component
    const [healthInsuranceSuggestions, setHealthInsuranceSuggestions] = React.useState([]);
    const [suggestedHealthInsurance, setSuggestedHealthInsurance] = React.useState({healthInsurance: appData.user.billing_data.healthInsurance.healthInsurance});

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = ({value}) => {
        setHealthInsuranceSuggestions(getHealthInsuranceSuggestions(value));
    };
    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => setHealthInsuranceSuggestions([]);
    const onHealthInsuranceChange = (event, {newValue}) => {
        setSuggestedHealthInsurance({healthInsurance: newValue});
        appData.user.billing_data.setHealthInsurance({
            id: 0,
            healthInsurance: newValue,
            address: {
                street: "",
                housingNumber: "",
                city: {
                    city: "",
                    id: 0,
                },
                zip: ""
            }
        });
        setRandom(Math.random());
    };

    // Autosuggest will pass through all these props to the input.
    const healthInsuranceAutosuggestInputProps = {
        placeholder: 'Krankenversicherung',
        value: suggestedHealthInsurance.healthInsurance,

        onChange: onHealthInsuranceChange
    };

    function onHealthInsuranceSuggestionSelected(event, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) {
        appData.user.billing_data.setHealthInsurance(suggestion);
        setRandom(Math.random());
    }

    // used for scrolling
    const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
    const panel1Ref = React.useRef(null);
    const panel2Ref = React.useRef(null);
    const panel4Ref = React.useRef(null);
    const panel5Ref = React.useRef(null);
    const panel6Ref = React.useRef(null);

    function executeScroll(panel) {
        switch(panel) {
            case "panel1":
                scrollToRef(panel1Ref);
                break;
            case "panel2":
                scrollToRef(panel2Ref);
                break;
            case "panel4":
                scrollToRef(panel4Ref);
                break;
            case "panel5":
                scrollToRef(panel5Ref);
                break;
            case "panel6":
                scrollToRef(panel6Ref);
                break;
            default:
                break;
        }
    }

    const handlePanelChange = panel => (event, newExpanded) => {
        executeScroll(panel);
        setTimeout(function() {
            setExpanded(newExpanded ? panel : false);
        }, 50);
    };

    function openSavingFeedback() {
        setSavingFeedbackOpen(true);
    }

    function handleSavingFeedbackClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setSavingFeedbackOpen(false);
    }

    function openPdfFeedback() {
        setPdfFeedbackOpen(true);
    }

    function handlePdfFeedbackClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setPdfFeedbackOpen(false);
    }

    function generatePDF() {
        setGeneratingPdf(true);
        setPdfSuccess(false);
        setPdfWarning(false);
        setPdfError(false);


        if (!profileReady()) {
            setPdfWarning(true);
            openPdfFeedback();
        } else {
            openPdfFeedback();
            appData.generatePDF().then(() => {
                setPdfSuccess(true);
                console.log("pdf generiert");
            }, () => {
                setPdfError(true);
                console.log("pdf fehler");
            })
        }
    }

    function toggleAdditionalEmployers() {
        appData.user.billing_data.addEmptyEmploymentIfNone();
        console.log("there are: " + appData.user.billing_data.employment.length + " employments.");
        setAdditionalEmployers(!additionalEmployers);
        setRandom(Math.random());
    }

    return (
        <Formik initialValues={appData.user.billing_data}
                validationSchema={billingDataSchema}
                onSubmit={values => {
                    setSaving(true);
                    setSavingSuccess(false);
                    setSavingError(false);
                    openSavingFeedback();
                    // on submit of form, update the mobx state tree
                    // updating the data to the backend can be done with an event listener on the model (onPatch)
                    console.log("updating billing Data");
                    appData.user.setBillingData(values);
                    appData.saveBillingDataToBackend().then(function (values) {
                        setSaving(false);
                        setSavingSuccess(true);
                        setRandom(Math.random());
                    }, function (values) {
                        setSavingSuccess(false);
                        setSaving(false);
                        setSavingError(true);
                        setRandom(Math.random());
                    });
                    // values contains the entire user subtree of the state
                    //alert("user updated" + JSON.stringify(values, null, 2));
                    //setSubmitting(false);
                }}
                render={({handleChange, handleBlur, values, errors, handleSubmit, setFieldValue}) => (
                    <Form autoComplete={"off"}>
                        <h1>Abrechnung</h1>
                        <ExpansionPanel ref={panel2Ref} square expanded={expanded === 'panel2'} onChange={handlePanelChange('panel2')}>
                            <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
                                {expanded === 'panel2' ? <Minus/> : <Plus/>}
                                {billingAccountingTypeSchema.isValidSync({...values.billingAccountingType}) &&
                                <IconOkay/>}
                                {!billingAccountingTypeSchema.isValidSync({...values.billingAccountingType}) &&
                                <IconMissingData/>}
                                <Typography>Beruflicher Status</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid item xs={12} className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 info clean"}>
                                    <Field component={SelectClean} name="billingAccountingType.id"
                                           label=""
                                           onChange={(event) => {
                                               console.log("changing...");
                                               appData.user.billing_data.setBillingAccountingType(parseInt(event.target.value));

                                               setFieldValue('billingAccountingType.id', parseInt(event.target.value), false);
                                               setRandom(Math.random());
                                               handleSubmit();
                                           }}
                                    >
                                        <option value="0" disabled>bitte auswählen</option>
                                        <option value="3">Angestellter</option>
                                        <option value="6">Arbeitssuchend gemeldet beim Arbeitsamt</option>
                                        <option value="8">Arbeitssuchend NICHT gemeldet beim Arbeitsamt</option>
                                        <option value="1">Schüler</option>
                                        <option value="7">Selbstständig / Gewerbetreibender</option>
                                        <option value="2">Student</option>
                                        {/*<option value="4">Freiberufler</option>*/}
                                        <option value="5">Studiumsbewerber</option>
                                    </Field>
                                    <Tooltip
                                        title="Wir müssen wissen welcher Tätigkeit du aktuell hauptberuflich nachgehst, sprich ob Du dich in einem festen Arbeitsverhältnis befindest, Selbstständig, Arbeitssuchend, Student, Studiumsbewerber, Schüler etc. bist. Bei Zweifel  How to zur Abrechnung."
                                        disableFocusListener disableTouchListener TransitionComponent={Fade}
                                        placement={"top"}
                                        style={{left : "140px", position : "relative", top : "-25px"}}
                                        >
                                        <InfoIcon/>
                                    </Tooltip>
                                    <AdditionalDocuments/>
                                    {appData.user.billing_data.billingAccountingType.id === 2 && <Typography component={"div"}>
                                        Seit wann studierst du?
                                        <Field component={FormikSlider} name="numOfSemesters" hidelabel="true"
                                               defaultValue={0}
                                               valueLabelFormat={semesterValueLabelFormat}
                                               min={1}
                                               max={3}
                                               aria-labelledby="discrete-slider-restrict"
                                               step={null}
                                               valueLabelDisplay="auto"
                                               marks={semesterMarks}
                                               onBlur={handleSubmit}
                                        />
                                        <SemesterCertificates/>
                                    </Typography>}
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        {/*<ExpansionPanel ref={panel6Ref} square expanded={expanded === 'panel6'} onChange={handlePanelChange('panel6')}*/}
                        {/*                style={values.billingAccountingType.id === 2 ? {} : {display: "none"}}>*/}
                        {/*    <ExpansionPanelSummary aria-controls="panel6d-content" id="panel6d-header">*/}
                        {/*        {expanded === 'panel6' ? <Minus/> : <Plus/>}*/}
                        {/*        {(values.billingAccountingType.id === 2 && values.numOfSemesters !== 0) && <IconOkay/>}*/}
                        {/*        {(values.billingAccountingType.id === 2 && values.numOfSemesters === 0) &&*/}
                        {/*        <IconPending/>}*/}
                        {/*        <Typography component={"div"}>*/}
                        {/*            <div>Studium</div>*/}
                        {/*        </Typography>*/}
                        {/*    </ExpansionPanelSummary>*/}
                        {/*    <ExpansionPanelDetails>*/}
                        {/*        <Typography component={"div"}>*/}
                        {/*            Seit wann studierst du?*/}
                        {/*            <Field component={FormikSlider} name="numOfSemesters" hidelabel={true}*/}
                        {/*                   defaultValue={0}*/}
                        {/*                   valueLabelFormat={semesterValueLabelFormat}*/}
                        {/*                   min={1}*/}
                        {/*                   max={3}*/}
                        {/*                   aria-labelledby="discrete-slider-restrict"*/}
                        {/*                   step={null}*/}
                        {/*                   valueLabelDisplay="auto"*/}
                        {/*                   marks={semesterMarks}*/}
                        {/*                   onBlur={handleSubmit}*/}
                        {/*            />*/}
                        {/*            <SemesterCertificates/>*/}
                        {/*        </Typography>*/}
                        {/*    </ExpansionPanelDetails>*/}
                        {/*</ExpansionPanel>*/}
                        <ExpansionPanel ref={panel4Ref} square expanded={expanded === 'panel4'} onChange={handlePanelChange('panel4')}>
                            <ExpansionPanelSummary aria-controls="panel4d-content" id="panel4d-header">
                                {expanded === 'panel4' ? <Minus/> : <Plus/>}
                                {employmentSchema.isValidSync(values.employment) && <IconOkay/>}
                                {!employmentSchema.isValidSync(values.employment) && <IconPending/>}
                                <Typography>Andere Arbeitgeber</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography component={"div"}>
                                    Hast du dieses Jahr bereits für andere Arbeitgeber gearbeitet?
                                    <br/>
                                    <RWSwitch checked={additionalEmployers} onChange={(evt) => {
                                        // if(evt.target.checked === false) {
                                        //     values.employment = []
                                        // }
                                        toggleAdditionalEmployers();
                                        //setAdditionalEmployers(!additionalEmployers)
                                    }}/>
                                    {additionalEmployers ? " ja" : " nein"}
                                    <br/>

                                    <FieldArray
                                        name="employment"
                                        render={arrayHelpers => {
                                            if (additionalEmployers) {
                                                return (
                                                    <div>
                                                        <RWRoundButton
                                                            variant="outlined"
                                                            color="primary"
                                                            text="Arbeitgeber hinzufügen"
                                                            onClick={() => {
                                                                arrayHelpers.insert(0,
                                                                    {
                                                                        employer: {
                                                                            id: 0,
                                                                            name: "",
                                                                            address: {
                                                                                street: "",
                                                                                housingNumber: "",
                                                                                zip: "",
                                                                                city: {
                                                                                    city: ""
                                                                                },
                                                                                state: {
                                                                                    state: ""
                                                                                },
                                                                                country: {
                                                                                    country: ""
                                                                                }
                                                                            }
                                                                        },
                                                                        start: new Date(),
                                                                        end: new Date(),
                                                                        workingDays: 1,
                                                                    }
                                                                );
                                                                console.log("Anzahl Arbeitgeber " + values.employment.length);
                                                            }}/>
                                                        {values.employment.map((employment, index) => (
                                                            <div key={index}>
                                                                <Field component={TextInput}
                                                                       label="Name des Arbeitgebers"
                                                                       name={`employment.${index}.employer.name`}
                                                                       onBlur={handleSubmit}
                                                                />
                                                                <Field
                                                                    component={FormikDatePicker}
                                                                    label="Beginn"
                                                                    name={`employment.${index}.start`}
                                                                    onBlur={handleSubmit}
                                                                />
                                                                <Field
                                                                    component={FormikDatePicker}
                                                                    label="Ende"
                                                                    name={`employment.${index}.end`}
                                                                    onBlur={handleSubmit}
                                                                />
                                                                {/*<Field component={FormikSlider}*/}
                                                                {/*       name={`employment.${index}.workingDays`}*/}
                                                                {/*       min={1} max={365}*/}
                                                                {/*       label="Tatsächliche Anzahl gearbeiteter Tage"*/}
                                                                {/*       onBlur={handleSubmit}*/}
                                                                {/*/>*/}
                                                                Tatsächliche Anzahl gearbeiteter Tage
                                                                <Field component={NumberInput}
                                                                       label="Tatsächliche Anzahl gearbeiteter Tage"
                                                                       min={1}
                                                                       max={365}
                                                                       name={`employment.${index}.workingDays`}
                                                                       onBlur={handleSubmit}
                                                                />
                                                                <Field component={TextInput}
                                                                       label="Straße"
                                                                       name={`employment.${index}.employer.address.street`}
                                                                       onBlur={handleSubmit}
                                                                />
                                                                <Field component={TextInput}
                                                                       label="Hausnummer"
                                                                       name={`employment.${index}.employer.address.housingNumber`}
                                                                       onBlur={handleSubmit}
                                                                />
                                                                <Field component={TextInput}
                                                                       label="Postleitzahl"
                                                                       name={`employment.${index}.employer.address.zip`}
                                                                       onBlur={handleSubmit}
                                                                />
                                                                <Field component={TextInput}
                                                                       label="Stadt"
                                                                       name={`employment.${index}.employer.address.city.city`}
                                                                       onBlur={handleSubmit}
                                                                />
                                                                {/*<Field component={TextInput}*/}
                                                                {/*       label="Bundesland"*/}
                                                                {/*       name={`employment.${index}.employer.address.state.state`} />*/}
                                                                {/*<Field component={TextInput}*/}

                                                                {/*       label="Land"*/}
                                                                {/*       name={`employment.${index}.employer.address.country.country`} />*/}
                                                                <RWRoundButton
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    text="Arbeitgeber entfernen"
                                                                    onClick={() => arrayHelpers.remove(index)}/>
                                                                <Typography>
                                                                    Unterlagen zur Tätigkeit bei {employment.employer.name}
                                                                </Typography>
                                                                {
                                                                    employmentFile(index)
                                                                }
                                                            </div>
                                                        ))}

                                                        {/*{appData.user.billing_data.employment.length > 0 && appData.user.billing_data.employment.map(employment => (*/}
                                                        {/*    <>*/}
                                                        {/*        <Typography align={"center"} variant={"h5"}>Unterlagen*/}
                                                        {/*            zur Tätigkeit*/}
                                                        {/*            bei {employment.employer.name}</Typography>*/}
                                                        {/*        /!*letzte Gehaltsabrechnung*!/*/}
                                                        {/*        <FilePreviewUploader*/}
                                                        {/*            file={employment.getPayRollFile()}/>*/}
                                                        {/*    </>))*/}
                                                        {/*}*/}
                                                    </div>
                                                )
                                            } else {
                                                return null
                                            }
                                        }}
                                    />


                                </Typography>
                                <br/>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel  ref={panel1Ref} square expanded={expanded === 'panel1'} onChange={handlePanelChange('panel1')}>
                            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
                                {expanded === 'panel1' ? <Minus/> : <Plus/>}
                                {(bankAccountSchema.isValidSync({...values.bankAccountData}) && taxSchema.isValidSync({...values})) &&
                                <IconOkay/>}
                                {(!bankAccountSchema.isValidSync({...values.bankAccountData}) || !taxSchema.isValidSync({...values})) &&
                                <IconPending/>}
                                <Typography>Bank- und Steuerdaten</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                    <Field component={TextInput} name="bankAccountData.iban" label="IBAN"
                                           onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12} style={{maxHeight:"50px"}}>
                                    <Field component={TextInput} name="bankAccountData.bic" label="BIC"
                                           onBlur={handleSubmit} />
                                    <Tooltip
                                        title="Trage hier DEINE BIC ein. Du findest sie auf der Rückseite Deiner Bankkarte. Wenn die BIC weniger als 11 Zeichen hat, ist sie mit XXX aufzufüllen."
                                        disableFocusListener disableTouchListener TransitionComponent={Fade}
                                        placement={"top"}
                                        style={{left : "140px", position : "relative", top : "-50px"}}>
                                        <InfoIcon/>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput} name="bankAccountData.creditInstitution"
                                           label="Name der Bank" onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12} style={{maxHeight:"50px"}}>
                                    <Field component={TextInput}
                                           name="tinTaxNumber"
                                           label="Steuer-Identifikations-Nummer"
                                           onBlur={handleSubmit}/>
                                    <Tooltip
                                        title="Deine Steuer-ID[TIN] findest Du auf jedem Schreiben vom Finanzamt oder kannst sie beim Finanzamt erfragen. Sie ist immer 11-stellig. Bitte beachte: Wir benötigen die Steuer-Identifikations-Nummer, nicht die Steuernummer."
                                        disableFocusListener disableTouchListener TransitionComponent={Fade}
                                        placement={"top"}
                                        style={{left : "140px", position : "relative", top : "-50px"}}>
                                        <InfoIcon/>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextInput}
                                           name="taxOffice.taxOffice"
                                           label="Finanzamt"
                                           onBlur={handleSubmit}/>
                                </Grid>
                                <Grid item xs={12} className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 info"}>
                                    <Field component={SelectNative} name="taxClass" label="Steuerklasse"
                                           onBlur={handleSubmit}>
                                        <option value="I">I</option>
                                        <option value="II">II</option>
                                        <option value="III">III</option>
                                        <option value="IV">IV</option>
                                        <option value="V">V</option>
                                        <option value="VI">VI</option>
                                    </Field>
                                    <Tooltip
                                        title="Trage deine Steuerklasse ein. Solltest Du aktuell einen Hauptjob auf Lohnsteuerklasse 1 ausführen, trägst Du hier bitte Lohnsteuerklasse 6 ein. Du kannst deine Lohnsteuerklasse auch bei deinem zuständigen Finanzamt erfragen."
                                        disableFocusListener disableTouchListener TransitionComponent={Fade}
                                        placement={"top"}
                                        style={{left : "140px", position : "relative", top : "-50px"}}>
                                        <InfoIcon/>
                                    </Tooltip>
                                </Grid>
                                    <br/>
                                <Grid item xs={12} className={"MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 info"}>
                                    <Field component={SelectNative} name="confession.id" label="Konfession"
                                           onBlur={handleSubmit}>
                                        <option value="0" disabled>bitte auswählen</option>
                                        <option value="7">konfessionslos</option>
                                        <option value="1">evangelisch</option>
                                        <option value="2">römisch-katholisch</option>
                                        <option value="3">alt-katholisch</option>
                                        <option value="4">freireligiöse Gemeinde Offenbach/Main</option>
                                        <option value="5">freie Protestanten</option>
                                        <option value="6">jüdische Gemeinde</option>
                                        <option value="11">muslimisch</option>
                                        <option value="12">buddhistisch</option>
                                        <option value="13">christlich orthodox</option>
                                        <option value="14">hinduistisch</option>
                                        <option value="8">freireligiöse Landesgemeinde Baden</option>
                                        <option value="9">freireligiöse Landesgemeinde Pfalz</option>
                                        <option value="10">freie Religionsgemeinschaft Alzey</option>
                                    </Field>
                                    <Tooltip
                                        title="Gib an, ob Du bei einer der genannten Kirchen Mitglied und somit kirchensteuerpflichtig oder konfessionslos bist."
                                        disableFocusListener disableTouchListener TransitionComponent={Fade}
                                        placement={"top"}
                                        style={{left : "140px", position : "relative", top : "-50px"}}>
                                        <InfoIcon/>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                        <ExpansionPanel ref={panel5Ref} square expanded={expanded === 'panel5'} onChange={handlePanelChange('panel5')}>
                            <ExpansionPanelSummary aria-controls="panel5d-content" id="panel5d-header">
                                {expanded === 'panel5' ? <Minus/> : <Plus/>}
                                {healthInsuranceSchema.isValidSync({nationalInsuranceNumber: values.nationalInsuranceNumber,...values.healthInsurance}) && <IconOkay/>}
                                {!healthInsuranceSchema.isValidSync({nationalInsuranceNumber: values.nationalInsuranceNumber,...values.healthInsurance}) && <IconPending/>}
                                <Typography>Krankenversicherung</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                    <Grid container>
                                        <Grid item xs={12} style={{maxHeight:"50px"}}>
                                            <Field component={TextInput}
                                                   name="nationalInsuranceNumber"
                                                   label="Sozialversicherungsnummer"
                                                   onBlur={handleSubmit}/>
                                            <Tooltip
                                                title="Die 12-stellige Sozialversicherungsnummer [auch Rentenversicherungsnummer genannt] findest Du auf einer ehemaligen Gehaltsabrechnung [unter SV-Nummer] oder Du kannst sie bei deiner Krankenkasse beantragen bzw. erfragen."
                                                disableFocusListener disableTouchListener TransitionComponent={Fade}
                                                placement={"top"}
                                                style={{left : "130px", position : "relative", top : "-50px"}}>
                                                <InfoIcon/>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                    <Field component={SelectNative} name="healthInsuranceType.id"
                                           label="Krankenversicherungsart" onBlur={handleSubmit} >
                                        <option value="0">gesetzlich</option>
                                        <option value="1">privat</option>
                                    </Field>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <br/>
                                        </Grid>
                                        <Grid item xs={12}>
                                    {/*<Field component={TextInput} name="healthInsurance.healthInsurance"*/}
                                    {/*        label="Krankenversicherung"*/}
                                    {/*        onBlur={handleSubmit}*/}
                                    {/*/>*/}
                                    <Autosuggest
                                        suggestions={healthInsuranceSuggestions}
                                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                                        getSuggestionValue={getHealthInsuranceSuggestionValue}
                                        renderSuggestion={renderHealthInsuranceSuggestion}
                                        inputProps={healthInsuranceAutosuggestInputProps}
                                        onSuggestionSelected={onHealthInsuranceSuggestionSelected}
                                        renderInputComponent={renderHealthInsuranceInputComponent}
                                        renderSuggestionsContainer={renderHealthInsuranceSuggestionsContainer}
                                    />

                                    {/*<Autocomplete*/}
                                    {/*    // freeSolo*/}
                                    {/*    id="free-solo-demo"*/}
                                    {/*    options={appData.autoComplete.healthInsurances}*/}
                                    {/*    getOptionLabel={option => option.healthInsurance}*/}
                                    {/*    renderInput={params => (*/}
                                    {/*        <TextField {...params} label="Krankenversicherung" margin="normal" fullWidth/>*/}
                                    {/*    )}*/}
                                    {/*    value={healthInsuranceInputValue}*/}
                                    {/*    inputValue={healthInsuranceInputValue}*/}
                                    {/*    onInputChange={onInputChange}*/}
                                    {/*    // onSelect={(val) => console.log(val)}*/}
                                    {/*    onChange={function (event, value) {*/}
                                    {/*        let newValue = {*/}
                                    {/*            address: {*/}
                                    {/*                street: value.address.street,*/}
                                    {/*                housingNumber: value.address.housingNumber,*/}
                                    {/*                city: {*/}
                                    {/*                    city: value.address.city.city,*/}
                                    {/*                    id: value.address.city.id,*/}
                                    {/*                },*/}
                                    {/*                zip: value.address.zip*/}
                                    {/*            },*/}
                                    {/*            id: value.id,*/}
                                    {/*            healthInsurance: value.healthInsurance*/}
                                    {/*        };*/}
                                    {/*        console.log(newValue);*/}
                                    {/*        console.log("health insurance: " + appData.user.billing_data.healthInsurance.healthInsurance);*/}
                                    {/*        appData.user.billing_data.setHealthInsurance(newValue);*/}
                                    {/*        setRandom(Math.random());*/}
                                    {/*    }}*/}
                                    {/*/>*/}

                                    {/*<Autocomplete*/}
                                    {/*    id="combo-box-demo"*/}
                                    {/*    options={top100Films}*/}
                                    {/*    getOptionLabel={option => option.title}*/}
                                    {/*    style={{ width: 300 }}*/}
                                    {/*    renderInput={params => (*/}
                                    {/*        <TextField {...params} label="Combo box" variant="outlined" fullWidth />*/}
                                    {/*    )}*/}
                                    {/*/>*/}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <br/>
                                           </Grid>
                                            <Grid item xs={12}>
                                    <Field component={TextInput} name="healthInsurance.address.street" label="Straße"
                                           onBlur={handleSubmit}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                    <Field component={TextInput} name="healthInsurance.address.housingNumber"
                                           label="Hausnummer" onBlur={handleSubmit}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                    <Field component={TextInput} name="healthInsurance.address.zip" label="PLZ"
                                           onBlur={handleSubmit}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                    <Field component={TextInput} name="healthInsurance.address.city.city" label="Stadt"
                                           onBlur={handleSubmit}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                    <HealthInsuranceDocuments/>
                                        </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <Grid item xs={12} style={{padding: "1em"}}>
                        Sobald du deine Daten vervollständigt hast, klicke bitte auf diesen Button, um dir ein PDF
                        mit deinen Daten zusenden zu lassen. Bitte sende uns dieses PDF per Post zu, damit wir
                        deine Gehaltsabrechnungen bearbeiten können.
                        </Grid>
                        <RWRoundButton
                            variant="outlined"
                            color="primary"
                            text="PDF zusenden"
                            onClick={() => generatePDF()}/>

                        {/*<Field type="text" name="date_of_birth" />*/}
                        {/*{errors.date_of_birth && touched.date_of_birth ? (*/}
                        {/*    <div>{errors.date_of_birth}</div>*/}
                        {/*) : null}*/}
                        {/*<RWRoundButton variant="outlined" text="Speichern" color="primary" type="submit" onClick={handleSubmit} />*/}
                        {/*<button type="submit" onClick={handleSubmit}>*/}
                        {/*    Speichern*/}
                        {/*</button>*/}
                        {/*<Documents />*/}
                        <br />
                        <br />
                        {payRollList()}

                        {saving && <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={savingFeedbackOpen}
                            autoHideDuration={8000}
                            onClose={handleSavingFeedbackClose}
                        >
                            <FeedbackContentWrapper
                                onClose={handleSavingFeedbackClose}
                                variant="info"
                                message="Die reddworld speichert die Daten..."
                            />
                        </Snackbar>}
                        {savingSuccess && <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={savingFeedbackOpen}
                            autoHideDuration={1000}
                            onClose={handleSavingFeedbackClose}
                        >
                            <FeedbackContentWrapper
                                onClose={handleSavingFeedbackClose}
                                variant="success"
                                message="Speichern erfolgreich!"
                            />
                        </Snackbar>}
                        {savingError && <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={savingFeedbackOpen}
                            autoHideDuration={8000}
                            onClose={handleSavingFeedbackClose}
                        >
                            <FeedbackContentWrapper
                                onClose={handleSavingFeedbackClose}
                                variant="error"
                                message="Es gab einen Fehler beim Speichern der Daten."
                            />
                        </Snackbar>}
                        {!pdfSuccess && !pdfError && !pdfWarning && generatingPdf && <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={pdfFeedbackOpen}
                            autoHideDuration={8000}
                            onClose={handlePdfFeedbackClose}
                        >
                            <FeedbackContentWrapper
                                onClose={handlePdfFeedbackClose}
                                variant="info"
                                message="Bitte warten..."
                            />
                        </Snackbar>}
                        {pdfSuccess && <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={pdfFeedbackOpen}
                            autoHideDuration={8000}
                            onClose={handlePdfFeedbackClose}
                        >
                            <FeedbackContentWrapper
                                onClose={handlePdfFeedbackClose}
                                variant="success"
                                message="Die PDF wurde an deine Mailadresse versendet. Bitte ausfüllen, unterschreiben und per Post zurücksenden."
                            />
                        </Snackbar>}
                        {pdfWarning && <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={pdfFeedbackOpen}
                            autoHideDuration={8000}
                            onClose={handlePdfFeedbackClose}
                        >
                            <FeedbackContentWrapper
                                onClose={handlePdfFeedbackClose}
                                variant="warning"
                                message="Bitte fülle zuerst alle Felder aus und lade die benötigten Dokumente hoch."
                            />
                        </Snackbar>}
                        {pdfError && <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={pdfFeedbackOpen}
                            autoHideDuration={8000}
                            onClose={handlePdfFeedbackClose}
                        >
                            <FeedbackContentWrapper
                                onClose={handlePdfFeedbackClose}
                                variant="error"
                                message="Es gab einen Fehler beim Generieren des PDFs. Bitte schreibe uns eine Mail."
                            />
                        </Snackbar>}
                    </Form>
                )}
        />

    );
}
