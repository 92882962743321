import React from 'react';
import appData from "../../../models/ReddworldData";

import 'react-dropzone-uploader/dist/styles.css'
import {Typography} from "@material-ui/core";
import {FilePreviewUploader} from "./FilePreviewUploader";


export default function SemesterCertificates() {
    return (
        <React.Fragment>
            <Typography align={"center"}>
               <br/> Wir benötigen von dir Semsterbescheinigungen für alle Semester die du dieses Jahr Student warst.
                Die Bescheinigungen kannst du durch einen klick auf den Upload Button direkt hochladen.

            </Typography>
            <br/>
            {/* semester certificates */}
            {semesterCertificates()}
        </React.Fragment>


    )

}

function semesterCertificates() {

    if (appData.user.billing_data.billingAccountingType.id === 2) {
        // depending on the semester, render that many
        switch (appData.user.billing_data.numOfSemesters) {
            case 0:
                return (
                    <></>
                );
            case 1:
                return (
                    <>
                        <FilePreviewUploader file={appData.user.billing_data.getCurrentSemesterCertificate()}/>
                    </>

                );
            case 2:
                return (
                    <>
                        <FilePreviewUploader file={appData.user.billing_data.getCurrentSemesterCertificate()}/>
                        <br/>
                        <FilePreviewUploader file={appData.user.billing_data.getPreviousSemesterCertificate()}/>
                    </>
                );
            case 3:
                return (
                    <>
                        <FilePreviewUploader file={appData.user.billing_data.getCurrentSemesterCertificate()}/>
                        <br/>
                        <FilePreviewUploader file={appData.user.billing_data.getPreviousSemesterCertificate()}/>
                        <br/>
                        <FilePreviewUploader file={appData.user.billing_data.getBeforePreviousSemesterCertificate()}/>
                    </>
                );
            default:
                return <></>;
        }
    } else {
        return <></>
    }
}
