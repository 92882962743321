import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {API_BASE, API_FILEUPLOAD} from "../../../constants/api";

import Dropzone from 'react-dropzone-uploader'
import appData from "../../../models/ReddworldData";

const useStyles = makeStyles({
    centerGrid: {
        textAlign: "center",
    },
    filePreview: {
        margin: "0 auto",
        width: 96,
        height: 96,
        border: "5px solid #b4233c",
        overflowX: "hidden",
        overflowY: "hidden"
    },
    previewCircle: {
        margin: "0 auto",
        width: "10em",
        height: "10em",
        border: "10px solid #b4233c",
        "border-radius": "50%",
        overflowX: "hidden",
        overflowY: "hidden"
    }
});

export function PhotoUploader() {
    // holds a random variable, for forcing rerendering
    // eslint-disable-next-line no-unused-vars
    const [state, setState] = React.useState(null);

    const getUploadParams = ({file, meta}) => {
        const body = new FormData();
        body.append("action", "uploadUserImage");
        body.append("auth_token", localStorage.getItem("jwt"));
        body.append("data", appData.user.id);
        body.append("file0", file);
        //body.append("fileInfo", JSON.stringify(fileInfo));
        return {
            url: API_FILEUPLOAD,
            body
        }
    };

    const handleChangeStatus = (fileWithMeta, status) => {
        if (status === 'headers_received') {
            //toast(`${meta.name} uploaded!`)
            //remove()
        } else if (status === 'aborted') {
            //toast(`${meta.name}, upload failed...`)
        } else if (status === 'done') {
            console.log("upload done.");

            let filename = JSON.parse(fileWithMeta.xhr.response)[0].data[0].filename;
            let path = JSON.parse(fileWithMeta.xhr.response)[0].data[0].path;
            let completePath = API_BASE + path + filename;
            appData.user.images[0].setUrl(completePath);

            // forces rerender of the component
            setState(Math.random());
        }
    };

    const classes = useStyles();
    // let imgSrc = API_BASE + "/img/icons/rw-icon-data_upload-thumbnail.svg";

    // switch(file.type) {
    //     case "image/jpeg":
    //         imgSrc = API_BASE + file.path + file.filename;
    //         break;
    //     case "image/png":
    //         imgSrc = API_BASE + file.path + file.filename;
    //         break;
    //     default:
    //         //imgSrc = API_BASE + "/img/icons/rw-icon-data_upload-thumbnail.svg";
    //         imgSrc = 'https://wiki.tripwireinteractive.com/images/4/47/Placeholder.png';
    //
    // }

    return (<>

            <Grid container justify="center" alignItems="center" className={classes.centerGrid}>
                <Grid item xs={12}>
                    <div className={classes.previewCircle}>
                        <Dropzone
                            getUploadParams={getUploadParams}
                            onChangeStatus={handleChangeStatus}
                            maxFiles={1}
                            canCancel={false}
                            multiple={false}
                            accept="image/*,audio/*,video/*"
                            inputContent={(files, extra) => (extra.reject ? '' : '')}
                            PreviewComponent={Preview}
                            styles={{
                                dropzone: {
                                    background: "lightblue url(" + appData.user.avatarUrl + ") no-repeat center",
                                    overflow: "hidden",
                                    height: 140,
                                    border: "0px",
                                    top: "-0px",
                                    borderRadius: "50%",
                                    backgroundSize: "cover"
                                },
                                border: "0px",
                                "border-radius": "50%",
                                dropzoneActive: {borderColor: 'green'},
                                "z-index": 20,
                                position: "relative",
                                top: "-128px",
                                dropzoneReject: {borderColor: 'red', backgroundColor: '#DAA'},
                                inputLabel: (files, extra) => (extra.reject ? {color: 'red'} : {})
                            }}
                        />
                    </div>
                </Grid>

                {/*<Grid item xs={12}>*/}
                {/*    <RWRoundButton variant="outlined" color="primary" text="Upload"/>*/}
                {/*</Grid>*/}
            </Grid>
        </>

    );
}

const Preview = ({meta}) => {
    // const {name, percent, status} = meta;
    return (<></>
        //<span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
        //{name}, {Math.round(percent)}%, {status}
        //</span>
    )
};
