import React from 'react'
import {Button, makeStyles} from "@material-ui/core";

// const useStyles = makeStyles(theme => ({
//     button: {
//         margin: theme.spacing(1),
//     },
//     input: {
//         display: 'none',
//     },
// }));

const useRegularStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
}));

export function RWButton(props) {
    const classes = useRegularStyles();
    return <Button variant={props.variant} color={props.color} className={classes.button}>{props.text}</Button>
}

const useRoundStyles = makeStyles(theme => ({
    button: {
        'border-radius': '20px',
        margin: theme.spacing(1),
        'min-width': '180px',
    },
    input: {
        display: 'none',
    },
}));

export function RWRoundButton(props) {
    const classes = useRoundStyles();
    return <Button variant={props.variant} color={props.color} className={classes.button} onClick={props.onClick}>{props.text}</Button>
}

export function RWLoginButton(props) {
    const classes = useRoundStyles();
    return <Button type="submit" variant={props.variant} color={props.color} className={classes.button} onClick={props.onClick}>{props.text}</Button>
}

const useJoinUsStyles = makeStyles(theme => ({
    button: {
        'border-radius': '20px'
    },
    input: {
        display: 'none',
    },
}));

export function RWJoinUsButton(props) {
    const classes = useJoinUsStyles();
    return <Button variant={props.variant} color={props.color} className={classes.button} onClick={props.onClick}>{props.text}</Button>
}

export function RWPendingButton(props) {
    const classes = useJoinUsStyles();
    return <Button style={{ color: "grey", borderColor: "grey"}} variant={props.variant} color={props.color} className={classes.button} onClick={props.onClick}>{props.text}</Button>
}

export function RWAppliedButton(props) {
    const classes = useJoinUsStyles();
    return <Button style={{ color: "green", borderColor: "green"}} variant={props.variant} color={props.color} className={classes.button} onClick={props.onClick}>{props.text}</Button>
}
