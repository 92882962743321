import React from 'react'
import appData from '../../../models/ReddworldData'

export const Personal = () => {
    return(
        <div>
        <h1>Persönlicher Bereich</h1>
            <h5>Hi, {appData.user.firstname}! Dies ist dein persönlicher Bereich - du kannst hier die Einstellungen der reddworld ändern und dich ausloggen.</h5>
        </div>
    )
}