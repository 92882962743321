import React from 'react';
import appData from "../../../models/ReddworldData";
import 'react-dropzone-uploader/dist/styles.css'
import {Typography} from "@material-ui/core";
import {FilePreviewUploader} from "./FilePreviewUploader";


export default function AdditionalDocuments() {
    return (
        <>
            {additionalDocuments()}
        </>


    )

}

function additionalDocuments() {
    switch(appData.user.billing_data.billingAccountingType.id) {
        // Schüler
        case 1:
            return (
                <>
                    <Typography align={"center"} variant={"h5"}>
                        Schulbescheinigung
                    </Typography>
                    <FilePreviewUploader file={appData.user.billing_data.getSchoolCertificate()}/>
                </>

            );
        // Student
        case 2:
            // documents already under semestercertificates
            return <>
            </>;
        // Angestellter
        case 3:
            // already under employer section
            return <></>;
        // Freiberufler
        case 4:
            // deprecated
            return <></>;
        // Studiumsbewerber
        case 5:
            return (
                <>
                    <Typography align={"center"} variant={"h5"}>
                        Hochschulbescheinigungen
                    </Typography>
                    <FilePreviewUploader file={appData.user.billing_data.getUniversityApplication()}/>
                    <FilePreviewUploader file={appData.user.billing_data.getStatusLetter()}/>
                </>
            );
        // Arbeitssuchend gemeldet
        case 6:
            return <></>;
        // Selbstständig
        case 7:
            return (
                <>
                    <Typography align={"center"} variant={"h5"}>
                        Gewerbeanmeldung
                    </Typography>
                    <FilePreviewUploader file={appData.user.billing_data.getBusinessRegistration()}/>
                </>
            );
        // Arbeitslos nicht gemeldet
        case 8:
            return (
                <>
                    <Typography align={"center"} variant={"h5"}>
                        Hochschulbescheinigungen
                    </Typography>
                    <FilePreviewUploader file={appData.user.billing_data.getStatusLetter()}/>
                </>
            );
        default:
            return (
                <></>
            );
    }
}
