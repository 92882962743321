import {SvgIcon} from "@material-ui/core";
import React from "react";

export const IconGenderFemale = () => (
    <div style={{"width": "24px", "height": "24px", "float": "right"}}>
        <SvgIcon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.99916 5.10248e-10C9.29944 5.10248e-10 11.9992 2.70007 11.9992 5.99998C11.9992 8.89997 9.89925 11.3998 6.99922 11.8998V14.0001H8.99935V15.9999H6.99922V17.9999H4.99947V15.9999H2.99935V14.0001H4.99947V11.8998C1.69919 11.3998 -0.500496 8.2999 0.0993153 4.99996C0.599347 2.09997 3.0995 -3.77948e-05 5.99916 5.10248e-10V5.10248e-10ZM5.99916 2.00015C3.79947 2.00015 1.99928 3.79995 1.99928 5.99998C1.99928 8.20008 3.79947 9.99981 5.99916 9.99981C8.19922 9.99981 9.99941 8.20004 9.99941 5.99998C9.99941 3.79991 8.19922 2.00015 5.99916 2.00015Z"
                    fill="#942738"/>
            </svg>
        </SvgIcon>
    </div>
);

export const IconGenderMale = () => (
    <div style={{"width": "18px", "height": "18px", "float": "right"}}>
        <SvgIcon>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.00399 5.98317C7.30356 5.98317 8.50315 6.38206 9.50282 7.08011L14.6011 1.99443H10.0027V0H18V7.97761H16.0007V3.39053L10.9024 8.47621C12.8017 11.1686 12.202 14.8583 9.50282 16.8527C6.80373 18.8471 3.005 18.149 1.10564 15.4566C-0.793717 12.7642 -0.193919 8.9748 2.50517 7.08011C3.50483 6.38206 4.8044 5.98317 6.00399 5.98317ZM6.00399 7.97761C3.80473 7.97761 2.00534 9.77264 2.00534 11.9665C2.00534 14.1603 3.80473 15.9552 6.00399 15.9552C8.20325 15.9552 10.0027 14.1603 10.0027 11.9665C10.0027 9.77264 8.20325 7.97761 6.00399 7.97761Z" fill="#942738"/>
            </svg>
        </SvgIcon>
    </div>
);

export const IconGenderDiverse = () => (
    <div style={{"width": "24px", "height": "24px", "float": "right"}}>
        <SvgIcon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <path
                        d="M14.6078 2.00003H11.0078V0H18.0078V6.99999H16.0078V3.40005L12.2078 7.20011C13.7078 9.5001 13.1078 12.6 10.8078 14.1C10.3078 14.5 9.7078 14.7 9.0078 14.8V17H11.0078V19.0001H9.0078V21.0001H7.0078V19.0001H5.0078V17H7.0078V14.9C4.3078 14.4 2.5078 11.7 3.1078 9.00002C3.7078 6.3 6.3078 4.50008 9.0078 5.10008C9.6078 5.20024 10.2078 5.50011 10.8078 5.80009L14.6078 2.00003ZM8.0078 6.99999C6.3078 6.99999 5.0078 8.30004 5.0078 10C5.0078 11.7 6.3078 13.0001 8.0078 13.0001C9.7078 13.0001 11.0078 11.7 11.0078 10C11.0078 8.30004 9.7078 6.99999 8.0078 6.99999Z"
                        fill="#942738"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    </div>
);

export const IconGenderFemaleWhite = () => (
    <div style={{"width": "12px", "height": "18px", "float": "right"}}>
        <SvgIcon>
            <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M5.99916 5.10248e-10C9.29944 5.10248e-10 11.9992 2.70007 11.9992 5.99998C11.9992 8.89997 9.89925 11.3998 6.99922 11.8998V14.0001H8.99935V15.9999H6.99922V17.9999H4.99947V15.9999H2.99935V14.0001H4.99947V11.8998C1.69919 11.3998 -0.500496 8.2999 0.0993153 4.99996C0.599347 2.09997 3.0995 -3.77948e-05 5.99916 5.10248e-10V5.10248e-10ZM5.99916 2.00015C3.79947 2.00015 1.99928 3.79995 1.99928 5.99998C1.99928 8.20008 3.79947 9.99981 5.99916 9.99981C8.19922 9.99981 9.99941 8.20004 9.99941 5.99998C9.99941 3.79991 8.19922 2.00015 5.99916 2.00015Z"
                    fill="#FBEFF1"/>
            </svg>
        </SvgIcon>
    </div>
);

export const IconGenderMaleWhite = () => (
    <div style={{"width": "12px", "height": "18px", "float": "right"}}>
        <SvgIcon>
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.99826 4.96411C5.86434 4.96411 6.6638 5.23005 7.33002 5.69544L10.7278 2.30479H7.66316V0.975098H12.9929V6.29381H11.6605V3.23558L8.26275 6.62623C9.52856 8.42128 9.12883 10.8812 7.33002 12.2109C5.53123 13.5405 2.99961 13.0751 1.73379 11.2801C0.467983 9.48502 0.867713 6.95864 2.6665 5.69544C3.33272 5.23005 4.1988 4.96411 4.99826 4.96411ZM4.99826 6.29381C3.53258 6.29381 2.33339 7.49056 2.33339 8.95322C2.33339 10.4159 3.53258 11.6125 4.99826 11.6125C6.46394 11.6125 7.66316 10.4159 7.66316 8.95322C7.66316 7.49056 6.46394 6.29381 4.99826 6.29381Z" fill="#942738"/>
            </svg>

        </SvgIcon>
    </div>
);

export const IconGenderDiverseWhite = () => (
    <div style={{"width": "21px", "height": "21px", "float": "right"}}>
        <SvgIcon>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <path
                        d="M14.6078 2.00003H11.0078V0H18.0078V6.99999H16.0078V3.40005L12.2078 7.20011C13.7078 9.5001 13.1078 12.6 10.8078 14.1C10.3078 14.5 9.7078 14.7 9.0078 14.8V17H11.0078V19.0001H9.0078V21.0001H7.0078V19.0001H5.0078V17H7.0078V14.9C4.3078 14.4 2.5078 11.7 3.1078 9.00002C3.7078 6.3 6.3078 4.50008 9.0078 5.10008C9.6078 5.20024 10.2078 5.50011 10.8078 5.80009L14.6078 2.00003ZM8.0078 6.99999C6.3078 6.99999 5.0078 8.30004 5.0078 10C5.0078 11.7 6.3078 13.0001 8.0078 13.0001C9.7078 13.0001 11.0078 11.7 11.0078 10C11.0078 8.30004 9.7078 6.99999 8.0078 6.99999Z"
                        fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="21" height="21" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    </div>
);
