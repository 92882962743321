import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const FormikSwitch = withStyles(theme => ({
    root: {
        width: 45,
        height: 20,
        padding: 0,
        overflow: 'visible',
        display: 'inline-flex',
        justifyContent: 'center'
    },
    switchBase: {
        padding: 0,
        color: theme.palette.common.white,
        '&$checked': {
            color: theme.palette.primary.main,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.common.white,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 20,
        height: 20,
        boxShadow: 'none',
        border: `1px solid ${theme.palette.primary.main}`,
    },
    track: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 20,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(({field, form, classes, ...props}) => {


    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            value={field.value}
            checked={field.value}
            onChange={event => form.setFieldValue(field.name, event.target.checked)}
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});