import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import appData from '../../models/ReddworldData'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import {SvgReddingtonLogo} from "../../img/icons/figma/components/Icons";

import './Login.css';

import {Field, Form, Formik} from "formik";
import {TextInput} from "../input_elements/text_input/TextInput";

import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import {RWRoundButton} from "../../components/input_elements/buttons/Buttons";
import "../font/JoannaMT.css"
import {amber, blueGrey, green} from "@material-ui/core/colors";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import {Snackbar} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3, 2),
        alignItems: "center",
        textAlign: "center",
        width: "100vw",
        maxWidth: "100vw",
        maxHeight: "100vh",
        height: "100vh",
        margin: 0,
        position: "absolute",
        top: "0",
        left: "0",
        fontFamily: "Joanna MT",
        // transform: "translate(-50%, -50%)"
    }, container: {
        height: "100vh",
        width: "100vw",
        textAlign: "center",
        background: "#ffffff"
    }, logo: {
        "marginBottom": "20%",
        "marginTop": "20%",
        width: "10em",
        height: "10em",

    }
}));


const useFeedbackStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: blueGrey[300],
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

function FeedbackContentWrapper(props) {
    const classes = useFeedbackStyles();
    const {className, message, onClose, variant, ...other} = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)}/>
                    {message}
        </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon}/>
                </IconButton>,
            ]}
            {...other}
        />
    );
}

FeedbackContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

const loginSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    password: Yup.string()
});

const emailSchema = Yup.object().shape({
    email: Yup.string().email()
});

export default function Login(props) {

    const [page, setPage] = React.useState('login');
    const [loginError, setLoginError] = React.useState(false);
    const [passwordResetDone, setPasswordResetDone] = React.useState(false);
    const [loginFeedbackOpen, setLoginFeedbackOpen] = React.useState(true);

    const classes = useStyles();

    function openLoginFeedback() {
        setLoginFeedbackOpen(true);
    }

    function handleLoginFeedbackClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setLoginFeedbackOpen(false);
    }

    function currentPage() {
        switch (page) {
            case 'login':
                return <LoginPage/>;
            case 'passwordRecovery':
                return <PasswordRecovery/>;
            default:
                return <LoginPage/>
        }
    }

    const LoginPage = () => (
        <Formik

            validationSchema={loginSchema}

            onSubmit={(userValues, {setSubmitting}) => {
                console.log("is submitting", userValues);
                // on submit of form, update the mobx state tree
                // updating the data to the backend can be done with an event listener on the model (onPatch)
                appData.login(userValues.username, userValues.password).then(function (success) {
                    if(success) {
                        setLoginError(false);
                    } else {
                        setLoginError(true);
                        openLoginFeedback();
                    }
                }, function (values) {
                    setLoginError(true);
                    openLoginFeedback();
                });
                // values contains the entire user subtree of the state
                //alert("user updated" + JSON.stringify(userValues, null, 2));
                setSubmitting(false);
                // setTimeout(() => {
                // }, 400);
            }}
        >
            {({errors, touched, isSubmitting, handleSubmit}) => (

                <Form style={{'marginTop': '2rem'}}>
                    <Grid item xs={12}>
                        <Field component={TextInput} placeholder="Benutzername" name="username" label={"Benutzername"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Field component={TextInput} placeholder="Passwort" type="password" name="password" label={"Passwort"}/>
                    </Grid>

                    <Grid item xs={12}>
                        {/*<Button variant="outlined" color="primary" type="submit">Login</Button>*/}
                        <RWRoundButton type="submit" variant="outlined" color="primary" text="Login"
                                       style={{float: "none"}} onClick={handleSubmit}/>
                    </Grid>
                    <Grid item xs={12}>
                        <br/><br/>
                        <Typography variant="subtitle2" component="h3" color={'secondary'}>
                            <RWRoundButton onClick={() => setPage('passwordRecovery')} variant="outlined"
                                           color="primary" text=" Passwort vergessen?"/>
                        </Typography>
                    </Grid>
                </Form>
            )}
        </Formik>
    );

    const PasswordRecovery = () => (
        <Formik
            initialValues={{email: ""}}
            validationSchema={emailSchema}

            onSubmit={(userValues, {setSubmitting}) => {
                console.log("is submitting", userValues);
                // on submit of form, update the mobx state tree
                // updating the data to the backend can be done with an event listener on the model (onPatch)
                appData.resetPassword(userValues.email).then(function(values){
                    setPasswordResetDone(true);
                    setLoginError(false);
                    openLoginFeedback();
                    setPage("login");
                }, function(values){
                });
                // values contains the entire user subtree of the state
                //alert("user updated" + JSON.stringify(userValues, null, 2));
                setSubmitting(false);
                // setTimeout(() => {
                // }, 400);
            }}
        >
            {({errors, touched, isSubmitting, handleSubmit}) => (

                <Form style={{'marginTop': '8rem'}}>
                    <Grid item xs={12}>
                        <Field component={TextInput} placeholder="Email-Adresse" name="email"/>
                    </Grid>

                    <Grid item xs={12}>
                        {/*<Button variant="outlined" color="primary" type="submit">Login</Button>*/}
                        <RWRoundButton type="submit" variant="outlined" color="primary" text="Passwort zurücksetzen"
                                       style={{float: "none"}} onClick={handleSubmit}/>
                    </Grid>
                </Form>
            )}
        </Formik>
    );

    return (
        <>
            <CssBaseline/>
            <Container className={classes.container} maxWidth={"lg"} style={{'marginBottom': '0px'}}>
                <Paper className={classes.root}>
                    <Grid xs={12} item={true} style={{'marginTop': '2rem'}}>
                        <SvgReddingtonLogo className={classes.logo}/>
                        <Typography variant="subtitle2" component="h3" align={"center"} color={"textSecondary"}>
                            reddworld Ver. 2.1
                        </Typography>
                    </Grid>

                    <Grid xs={12} item={true}>

                        {currentPage()}
                    </Grid>
                </Paper>
                {loginError && <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={loginFeedbackOpen}
                    autoHideDuration={8000}
                    onClose={handleLoginFeedbackClose}
                >
                    <FeedbackContentWrapper
                        onClose={handleLoginFeedbackClose}
                        variant="error"
                        message="Der Login ist fehlgeschlagen. Falsche Zugangsdaten?"
                    />
                </Snackbar> }
                {passwordResetDone && <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={loginFeedbackOpen}
                    autoHideDuration={8000}
                    onClose={handleLoginFeedbackClose}
                >
                    <FeedbackContentWrapper
                        onClose={handleLoginFeedbackClose}
                        variant="info"
                        message="Falls diese Mailadresse im System ist, wurde ein neues Passwort an sie geschickt."
                    />
                </Snackbar> }
            </Container>
        </>
    )
}

