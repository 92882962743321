import {SvgIcon} from "@material-ui/core";
import React from "react";

export const IconInfo = () => (
    <div style={{"width": "24px", "height": "24px", "float": "right"}}>
        <SvgIcon>
            <svg width="24" height="24" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="23" height="23" rx="11.5" stroke="#B4233C" strokeWidth="2"/>
                <path
                    d="M13.3087 19.2632H11.6827V9.75342H13.3087V19.2632ZM11.5509 7.23096C11.5509 6.96729 11.63 6.74463 11.7882 6.56299C11.9522 6.38135 12.1925 6.29053 12.5089 6.29053C12.8253 6.29053 13.0655 6.38135 13.2296 6.56299C13.3936 6.74463 13.4757 6.96729 13.4757 7.23096C13.4757 7.49463 13.3936 7.71436 13.2296 7.89014C13.0655 8.06592 12.8253 8.15381 12.5089 8.15381C12.1925 8.15381 11.9522 8.06592 11.7882 7.89014C11.63 7.71436 11.5509 7.49463 11.5509 7.23096Z"
                    fill="#B4233C"/>
            </svg>
        </SvgIcon>
    </div>
);

export const IconMissingData = () => (
    <div style={{"width": "24px", "height": "24px", "float": "right", minheight: "24px"}}>
        <SvgIcon>
            <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.6074 6.60736C8.84352 4.26477 11.5056 3.19995 14.7 3.19995C17.8944 3.19995 20.5565 4.37125 22.7926 6.60736C25.0287 8.84347 26.2 11.612 26.2 14.7C26.2 17.7879 25.0287 20.5564 22.7926 22.7925C20.5565 25.0287 17.788 26.2 14.7 26.2C11.5056 26.2 8.84352 25.0287 6.6074 22.7925C4.26481 20.5564 3.2 17.8944 3.2 14.7C3.2 11.5055 4.26481 8.84347 6.6074 6.60736ZM8.20463 21.1953C10.0148 23.0055 12.1444 23.9638 14.7 23.9638C17.2556 23.9638 19.3852 23.0055 21.1954 21.1953C23.0056 19.3851 23.9639 17.2555 23.9639 14.7C23.9639 12.1444 23.0056 10.0148 21.1954 8.20458C19.3852 6.3944 17.1491 5.43606 14.7 5.43606C12.1444 5.43606 10.0148 6.3944 8.20463 8.20458C6.39444 10.0148 5.43611 12.1444 5.43611 14.7C5.43611 17.2555 6.39444 19.3851 8.20463 21.1953Z"
                    fill="#B4233C"/>
                <path
                    d="M19.602 19.3911H9.25483C9.02742 19.3911 8.91371 19.6185 8.91371 19.8459C8.91371 20.0733 9.14112 20.3008 9.25483 20.3008H19.602C19.8294 20.3008 19.9432 20.0733 19.9432 19.8459C20.0569 19.6185 19.8294 19.3911 19.602 19.3911Z"
                    fill="#B4233C"/>
                <path
                    d="M8.8 16.321V18.5952C8.8 18.7089 8.8 18.8226 8.91371 18.9363C9.02741 19.05 9.14112 19.05 9.25482 19.05H11.5289C11.6426 19.05 11.7563 19.05 11.8701 18.9363L19.8294 10.9769C20.0569 10.7495 20.0569 10.5221 19.8294 10.2946L17.5553 8.02053C17.3279 7.79312 17.1005 7.79312 16.8731 8.02053L15.2812 9.61241L9.02741 15.9799C8.91371 16.0936 8.8 16.2073 8.8 16.321ZM17.2142 9.04388L18.8061 10.6358L17.8964 11.5454L16.3046 9.95353C16.3046 9.95353 17.2142 9.04388 17.2142 9.04388ZM9.82335 16.5485L15.6223 10.7495L17.2142 12.3413L11.4152 18.1403H9.82335V16.5485Z"
                    fill="#B4233C"/>
            </svg>
        </SvgIcon>
    </div>
);

export const IconOkay = () => (
    <div style={{"width": "24px", "height": "24px", "float": "right"}}>
        <SvgIcon>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.99996 1.66669C5.41663 1.66669 1.66663 5.41669 1.66663 10C1.66663 14.5834 5.41663 18.3334 9.99996 18.3334C14.5833 18.3334 18.3333 14.5834 18.3333 10C18.3333 5.41669 14.5833 1.66669 9.99996 1.66669ZM8.33329 14.1667L4.16663 10L5.33329 8.83335L8.33329 11.8334L14.6666 5.50002L15.8333 6.66669L8.33329 14.1667Z"
                    fill="#2DA54B"/>
            </svg>
        </SvgIcon>
    </div>
);

export const IconPending = () => (
    <div style={{"width": "24px", "height": "24px", "float": "right"}}>
        <SvgIcon>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 1.66669C5.41669 1.66669 1.66669 5.41669 1.66669 10C1.66669 14.5834 5.41669 18.3334 10 18.3334C14.5834 18.3334 18.3334 14.5834 18.3334 10C18.3334 5.41669 14.5834 1.66669 10 1.66669ZM10 16.6667C6.33335 16.6667 3.33335 13.6667 3.33335 10C3.33335 6.33335 6.33335 3.33335 10 3.33335C13.6667 3.33335 16.6667 6.33335 16.6667 10C16.6667 13.6667 13.6667 16.6667 10 16.6667Z"
                    fill="#F0AD4E"/>
                <path d="M10.4167 5.83337H9.16669V10.8334L13.5834 13.4167L14.25 12.4167L10.5 10.1667V5.83337H10.4167Z"
                      fill="#F0AD4E"/>
            </svg>
        </SvgIcon>
    </div>
);

export const IconPendingForFiles = () => (
    <div style={{"marginTop": "3px"}}>
        <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48 0C21.6 0 0 21.6 0 48C0 74.4 21.6 96 48 96C74.4 96 96 74.4 96 48C96 21.6 74.4 0 48 0ZM48 86.4C26.88 86.4 9.6 69.12 9.6 48C9.6 26.88 26.88 9.6 48 9.6C69.12 9.6 86.4 26.88 86.4 48C86.4 69.12 69.12 86.4 48 86.4Z" fill="#F0AD4E"/>
            <path d="M50.4 24H43.2V52.8L68.64 67.68L72.48 61.92L50.88 48.96V24H50.4Z" fill="#F0AD4E"/>
        </svg>
    </div>
);

export const IconOkayForFiles = () => (
    <div style={{"marginTop": "4px"}}>
        <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.4971 0C21.2756 0 0 21.2704 0 47.503C0 73.7355 21.2756 95 47.4971 95C73.7244 95 95 73.7355 95 47.503C95 21.2704 73.7244 0 47.4971 0ZM47.4971 87.3593C25.4863 87.3593 7.6497 69.5087 7.6497 47.503C7.6497 25.4944 25.4863 7.64556 47.4971 7.64556C69.5093 7.64556 87.3502 25.4944 87.3502 47.503C87.3502 69.5087 69.5093 87.3593 47.4971 87.3593Z" fill="#2DA54B"/>
            <path d="M36.7222 60.5596L72.2815 25L77 29.7203L36.7222 70L18 51.274L22.72 46.5552L36.7222 60.5596Z" fill="#2DA54B"/>
        </svg>
    </div>
);

export const IconRejectedForFiles = () => (
    <div style={{"marginTop": "3.5px"}}>
        <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2222 14.2223C23.5556 4.44441 34.6668 0 48.0001 0C61.3335 0 72.445 4.88892 81.7784 14.2223C91.1117 23.5556 96 35.1111 96 48C96 60.889 91.1117 72.4443 81.7784 81.7777C72.445 91.111 60.8891 96 48.0001 96C34.6668 96 23.5556 91.111 14.2222 81.7777C4.44443 72.4443 0 61.3334 0 48C0 34.6667 4.44443 23.5556 14.2222 14.2223ZM20.8889 75.1115C28.4445 82.667 37.3335 86.6667 48.0001 86.6667C58.6668 86.6667 67.556 82.667 75.1115 75.1115C82.6669 67.5553 86.6666 58.6667 86.6666 48C86.6666 37.3334 82.6669 28.4445 75.1115 20.8889C67.556 13.3333 58.2224 9.33334 48.0001 9.33334C37.3335 9.33334 28.4445 13.3333 20.8889 20.8889C13.3334 28.4445 9.33337 37.3334 9.33337 48C9.33337 58.6667 13.3334 67.5553 20.8889 75.1115Z" fill="#B4233C"/>
            <path d="M73 29.6371L54.5791 47.7787L73 65.9199L65.9828 73L48.0001 54.8583L29.579 73L23 66.3626L40.9825 48.2212L23 29.6371L29.579 23L48.0001 41.584L65.9828 23L73 29.6371Z" fill="#B4233C"/>
        </svg>
    </div>
);

export const IconXOut = () => (
    <div style={{"width": "24px", "height": "24px", "float": "right"}}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.48572 5.48566C7.28572 3.59994 9.42857 2.7428 12 2.7428C14.5714 2.7428 16.7143 3.68566 18.5143 5.48566C20.3143 7.28565 21.2571 9.51423 21.2571 11.9999C21.2571 14.4857 20.3143 16.7142 18.5143 18.5142C16.7143 20.3142 14.4857 21.2571 12 21.2571C9.42857 21.2571 7.28572 20.3142 5.48572 18.5142C3.6 16.7142 2.74286 14.5714 2.74286 11.9999C2.74286 9.42851 3.6 7.28565 5.48572 5.48566ZM6.77143 17.2285C8.22857 18.6857 9.94286 19.4571 12 19.4571C14.0571 19.4571 15.7714 18.6857 17.2286 17.2285C18.6857 15.7714 19.4571 14.0571 19.4571 11.9999C19.4571 9.9428 18.6857 8.22851 17.2286 6.77137C15.7714 5.31423 13.9714 4.5428 12 4.5428C9.94286 4.5428 8.22857 5.31423 6.77143 6.77137C5.31429 8.22851 4.54286 9.9428 4.54286 11.9999C4.54286 14.0571 5.31429 15.7714 6.77143 17.2285Z"
                fill="#B4233C"/>
            <path
                d="M16.8857 8.48567L13.2857 12L16.8857 15.5142L15.5143 16.8857L12 13.3714L8.39997 16.8857L7.11426 15.6L10.6285 12.0857L7.11426 8.48567L8.39997 7.19995L12 10.8L15.5143 7.19995L16.8857 8.48567Z"
                fill="#B4233C"/>
        </svg>
    </div>
);

export const IconArrowRight = () => (
    <div style={{"width": "24px", "height": "24px", "float": "right"}}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3 12C20.3 16.6 16.6 20.3 12 20.3C7.4 20.3 3.7 16.6 3.7 12C3.7 7.39996 7.4 3.69996 12 3.69996C16.6 3.69996 20.3 7.39996 20.3 12ZM22 12C22 6.49996 17.5 1.99996 12 1.99996C6.5 1.99996 2 6.49996 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12Z" fill="#B4233C"/>
            <g clipPath="url(#clip0)">
                <path d="M9.491 8.1715L10.6332 6.99996L15.5082 12L10.6332 17L9.491 15.8284L13.2237 12L9.491 8.1715Z" fill="#8C2332"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="10" height="21" fill="white" transform="translate(2 17) rotate(-90)"/>
                </clipPath>
            </defs>
        </svg>
    </div>
);

export const IconDownloadFile = () => (
    <div style={{"width": "52px", "height": "52px"}}>
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.5877 26C48.5877 39.0206 38.2291 49.5 25.5439 49.5C12.8586 49.5 2.5 39.0206 2.5 26C2.5 12.9794 12.8586 2.5 25.5439 2.5C38.2291 2.5 48.5877 12.9794 48.5877 26Z" stroke="#B4233C" strokeWidth="5"/>
            <path d="M28.25 19.4375V12H17.3125C16.5852 12 16 12.5852 16 13.3125V38.6875C16 39.4148 16.5852 40 17.3125 40H35.6875C36.4148 40 37 39.4148 37 38.6875V20.75H29.5625C28.8406 20.75 28.25 20.1594 28.25 19.4375ZM32.4309 30.9962L27.1579 36.2298C26.7942 36.5913 26.2069 36.5913 25.8432 36.2298L20.5702 30.9962C20.0152 30.4455 20.4045 29.5 21.1855 29.5H24.75V25.125C24.75 24.6416 25.1416 24.25 25.625 24.25H27.375C27.8584 24.25 28.25 24.6416 28.25 25.125V29.5H31.8145C32.5955 29.5 32.9848 30.4455 32.4309 30.9962ZM36.6172 17.7422L31.2633 12.3828C31.0172 12.1367 30.6836 12 30.3336 12H30V19H37V18.6664C37 18.3219 36.8633 17.9883 36.6172 17.7422Z" fill="#444444"/>
        </svg>
    </div>
);

export const IconDeleteFile = () => (
    <div style={{"width": "52px", "height": "52px"}}>
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.58 26C47.58 37.96 37.96 47.58 26 47.58C14.04 47.58 4.42 37.96 4.42 26C4.42 14.04 14.04 4.42 26 4.42C37.96 4.42 47.58 14.04 47.58 26ZM52 26C52 11.7 40.3 0 26 0C11.7 0 0 11.7 0 26C0 40.3 11.7 52 26 52C40.3 52 52 40.3 52 26Z" fill="#B4233C"/>
            <path d="M15.7143 37.375C15.7143 38.0712 15.9852 38.7388 16.4674 39.2311C16.9496 39.7234 17.6037 40 18.2857 40H33.7143C34.3963 40 35.0504 39.7234 35.5326 39.2311C36.0148 38.7388 36.2857 38.0712 36.2857 37.375V19H15.7143V37.375ZM30.2857 23.375C30.2857 23.1429 30.376 22.9203 30.5368 22.7562C30.6975 22.5922 30.9155 22.5 31.1429 22.5C31.3702 22.5 31.5882 22.5922 31.7489 22.7562C31.9097 22.9203 32 23.1429 32 23.375V35.625C32 35.857 31.9097 36.0796 31.7489 36.2437C31.5882 36.4078 31.3702 36.5 31.1429 36.5C30.9155 36.5 30.6975 36.4078 30.5368 36.2437C30.376 36.0796 30.2857 35.857 30.2857 35.625V23.375ZM25.1429 23.375C25.1429 23.1429 25.2332 22.9203 25.3939 22.7562C25.5547 22.5922 25.7726 22.5 26 22.5C26.2274 22.5 26.4453 22.5922 26.6061 22.7562C26.7668 22.9203 26.8571 23.1429 26.8571 23.375V35.625C26.8571 35.857 26.7668 36.0796 26.6061 36.2437C26.4453 36.4078 26.2274 36.5 26 36.5C25.7726 36.5 25.5547 36.4078 25.3939 36.2437C25.2332 36.0796 25.1429 35.857 25.1429 35.625V23.375ZM20 23.375C20 23.1429 20.0903 22.9203 20.2511 22.7562C20.4118 22.5922 20.6298 22.5 20.8571 22.5C21.0845 22.5 21.3025 22.5922 21.4632 22.7562C21.624 22.9203 21.7143 23.1429 21.7143 23.375V35.625C21.7143 35.857 21.624 36.0796 21.4632 36.2437C21.3025 36.4078 21.0845 36.5 20.8571 36.5C20.6298 36.5 20.4118 36.4078 20.2511 36.2437C20.0903 36.0796 20 35.857 20 35.625V23.375ZM37.1429 13.75H30.7143L30.2107 12.7273C30.104 12.5087 29.9397 12.3248 29.7363 12.1963C29.5328 12.0678 29.2982 11.9998 29.059 12H22.9357C22.697 11.999 22.4628 12.0668 22.2601 12.1954C22.0573 12.324 21.8941 12.5084 21.7893 12.7273L21.2857 13.75H14.8571C14.6298 13.75 14.4118 13.8422 14.2511 14.0062C14.0903 14.1703 14 14.3929 14 14.625V16.375C14 16.607 14.0903 16.8296 14.2511 16.9937C14.4118 17.1578 14.6298 17.25 14.8571 17.25H37.1429C37.3702 17.25 37.5882 17.1578 37.7489 16.9937C37.9097 16.8296 38 16.607 38 16.375V14.625C38 14.3929 37.9097 14.1703 37.7489 14.0062C37.5882 13.8422 37.3702 13.75 37.1429 13.75Z" fill="#444444"/>
        </svg>
    </div>
);

export const IconSaveFile = () => (
    <div style={{"width": "52px", "height": "52px"}}>
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.6746 20.0482C38.4576 19.5165 38.197 19.0952 37.8931 18.785L33.3357 14.1303C33.0321 13.8202 32.6197 13.5541 32.0988 13.3324C31.5783 13.1108 31.1007 13 30.6668 13H15.5624C15.1285 13 14.7595 13.1551 14.4556 13.4654C14.1518 13.7756 14 14.1524 14 14.5958V36.9381C14 37.3817 14.1518 37.7585 14.4556 38.0687C14.7595 38.3788 15.1285 38.534 15.5624 38.534H37.4377C37.872 38.534 38.2409 38.3788 38.5447 38.0687C38.8482 37.7585 39 37.3817 39 36.9381V21.5113C39 21.0679 38.8916 20.5803 38.6746 20.0482ZM24.4168 15.6596C24.4168 15.5155 24.4684 15.3911 24.5715 15.2856C24.6745 15.1806 24.7966 15.1279 24.9377 15.1279H28.0628C28.2035 15.1279 28.3256 15.1804 28.4287 15.2856C28.532 15.3911 28.5836 15.5155 28.5836 15.6596V20.9792C28.5836 21.1235 28.5317 21.2481 28.4287 21.3534C28.3256 21.4584 28.2035 21.5111 28.0628 21.5111H24.9377C24.7966 21.5111 24.6745 21.4586 24.5715 21.3534C24.4684 21.2479 24.4168 21.1235 24.4168 20.9792V15.6596V15.6596ZM32.7504 36.4063H20.2499V30.0226H32.7504V36.4063ZM36.9174 36.4063H34.8336H34.8332V29.4906C34.8332 29.0471 34.6814 28.6705 34.3777 28.36C34.0739 28.0498 33.705 27.8946 33.2711 27.8946H19.7291C19.295 27.8946 18.926 28.0498 18.6222 28.36C18.3185 28.6703 18.1666 29.0471 18.1666 29.4906V36.4063H16.0832V15.1277H18.1665V22.0432C18.1665 22.4866 18.3183 22.8633 18.6221 23.1736C18.926 23.4838 19.295 23.639 19.7291 23.639H29.1045C29.5383 23.639 29.9077 23.4838 30.2111 23.1736C30.5146 22.8635 30.6668 22.4866 30.6668 22.0432V15.1277C30.8295 15.1277 31.0409 15.183 31.3016 15.2938C31.5623 15.4046 31.7466 15.5154 31.8551 15.6262L36.4291 20.2976C36.5375 20.4084 36.646 20.5998 36.7545 20.8712C36.8633 21.1428 36.9173 21.3562 36.9173 21.5113V36.4063H36.9174Z" fill="#444444"/>
            <path d="M47.58 26C47.58 37.96 37.96 47.58 26 47.58C14.04 47.58 4.42 37.96 4.42 26C4.42 14.04 14.04 4.42 26 4.42C37.96 4.42 47.58 14.04 47.58 26ZM52 26C52 11.7 40.3 0 26 0C11.7 0 0 11.7 0 26C0 40.3 11.7 52 26 52C40.3 52 52 40.3 52 26Z" fill="#B4233C"/>
        </svg>

    </div>
);

export const IconUploadFile = () => (
    <div style={{"width": "52px", "height": "52px"}}>
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.58 26C47.58 37.96 37.96 47.58 26 47.58C14.04 47.58 4.42 37.96 4.42 26C4.42 14.04 14.04 4.42 26 4.42C37.96 4.42 47.58 14.04 47.58 26ZM52 26C52 11.7 40.3 0 26 0C11.7 0 0 11.7 0 26C0 40.3 11.7 52 26 52C40.3 52 52 40.3 52 26Z" fill="#B4233C"/>
            <path d="M36.2 25.1219C36.3922 24.6203 36.5 24.0719 36.5 23.5C36.5 21.0156 34.4844 19 32 19C31.0766 19 30.2141 19.2812 29.5016 19.7594C28.2031 17.5094 25.7797 16 23 16C18.8562 16 15.5 19.3562 15.5 23.5C15.5 23.6266 15.5047 23.7531 15.5094 23.8797C12.8844 24.8031 11 27.3062 11 30.25C11 33.9766 14.0234 37 17.75 37H35C38.3141 37 41 34.3141 41 31C41 28.0984 38.9375 25.675 36.2 25.1219ZM29.4406 28H26.375V33.25C26.375 33.6625 26.0375 34 25.625 34H23.375C22.9625 34 22.625 33.6625 22.625 33.25V28H19.5594C18.8891 28 18.5563 27.1937 19.0297 26.7203L23.9703 21.7797C24.2609 21.4891 24.7391 21.4891 25.0297 21.7797L29.9703 26.7203C30.4438 27.1937 30.1062 28 29.4406 28V28Z" fill="#444444"/>
        </svg>
    </div>
);
