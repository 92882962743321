import {flow, types} from "mobx-state-tree";

import axios from "axios";
import {API_AUTOCOMPLETE} from "../constants/api";
import {HealthInsurance} from "./BillingData";

export const AutoComplete = types.model({
    healthInsurances: types.array(HealthInsurance),
}).actions((self => ({
    getHealthInsurances: flow(function* getHealthInsurances() {
        // <- note the star, this a generator function!
        try {
            // ... yield can be used in async/await style
            // first get the available jobalerts
            const healthInsurances = yield axios.post(API_AUTOCOMPLETE, {
                action: 'getAllHealthInsurances',
                auth_token: localStorage.getItem('jwt')
            }).then((response) => {
                if (response.status === 200) {
                    return response.data
                }
            }, (error) => {
                console.log(error);
                return false
            });

            healthInsurances.forEach(healthInsurance => {
                self.healthInsurances.push(healthInsurance);
            });
        } catch (error) {
            // ... including try/catch error handling
            console.error("Failed to fetch health insurances", error)
        }
    })
})));
