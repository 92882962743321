import * as Yup from "yup";

export const billingDataSchema = Yup.object().shape({
});

export const bankAccountSchema = Yup.object().shape({
    iban: Yup.string()
        .min(15, 'IBAN zu kurz')
        .max(32, 'IBAN zu lang')
        .required('IBAN benötigt'),
    bic: Yup.string()
        .min(8, 'BIC zu kurz')
        .max(11, 'BIC zu lang')
        .required('BIC benötigt'),
    creditInstitution: Yup.string()
        .required('Bankname benötigt'),
});

export const billingAccountingTypeSchema = Yup.object().shape({
    id: Yup.number()
        .min(1)
        .max(8)
});

export const confessionSchema = Yup.object().shape({
    id: Yup.number()
        .min(1)
        .max(10)
        .required("Bitte gib deine Konfession an.")
});

export const employmentSchema = Yup.array().of(
    Yup.object().shape({
        // start: Yup.date(),
        // end: Yup.date(),
        workingDays: Yup.number()
            .min(1),
        employer: Yup.object().shape({
            name: Yup.string()
                .min(1)
                .required(),
            address: Yup.object().shape({
                street: Yup.string()
                    .min(1)
                    .required(),
                housingNumber: Yup.string()
                    .min(1)
                    .required(),
                zip: Yup.string()
                    .min(3)
                    .required(),
                city: Yup.object().shape({
                    city: Yup.string()
                        .min(3)
                        .required(),
                }),
                // state: Yup.object().shape({
                //     state: Yup.string()
                //         .min(3)
                //         .required(),
                // }),
                // country: Yup.object().shape({
                //     country: Yup.string()
                //         .min(3)
                //         .required(),
                // })
            })
        })
    })
);

export const healthInsuranceSchema = Yup.object().shape({
    nationalInsuranceNumber: Yup.string().required(),
    healthInsurance: Yup.string()
        .required(),
    address: Yup.object().shape({
        street: Yup.string()
            .min(1)
            .required(),
        housingNumber: Yup.string()
            .min(1)
            .required(),
        zip: Yup.string()
            .min(3)
            .required(),
        city: Yup.object().shape({
            city: Yup.string()
                .min(3)
                .required(),
        }),
        // country: Yup.object().shape({
        //     country: Yup.string()
        //         .min(3)
        //         .required(),
        // })
    })
});

export const taxSchema = Yup.object().shape({
    // nationalInsuranceNumber: Yup.string()
    //     .required(),
    taxClass: Yup.string()
        .required(),
    taxOffice: Yup.string()
        .required(),
    tinTaxNumber: Yup.string()
        .required(),
});
