import React from 'react'
import Slider from '@material-ui/core/Slider';
import {JOB_APPLICATION_APPLIED, JOB_APPLICATION_PENDING} from "../../../constants/constants";

const marks = [
    {
        value: 1,
        label: 'alle',
    },
    {
        value: JOB_APPLICATION_PENDING,
        label: 'Beworben',
    },
    {
        value: JOB_APPLICATION_APPLIED,
        label: 'Besätigt',
    },
];

export const FormikJobAlertStatusSlider = ({field, form, ...props}) => {

    return (
        <div>
            {/*<p style={{margin:'0'}}>{props.label}: {statusValueLabelFormat(field.value)}</p>*/}
            <Slider
                min={1}
                max={3}
                marks={marks}
                defaultValue={1}
                aria-label={"Filter Ausschreibungsstatus"}
                name={field.name}
                value={field.value}
                valueLabelDisplay="off"
                onChangeCommitted={(event, value) => {
                    form.setFieldValue(field.name, value, true);
                    form.handleSubmit()
                }}
                {...props}
            />
        </div>
    );
};
