import React from 'react'
import Slider from '@material-ui/core/Slider';

function schoolValueLabelFormat(value) {
    switch (value) {
        case 5:
            return "Abitur";
        case 3:
            return "Mittlere Reife";
        case 4:
            return "Fachabitur";
        case 1:
            return "Hauptschule";
        case 2:
            return "Gesamtschule";
        default:
            return "bitte auswählen";
    }
}

function mapToDBValue(value) {
    switch (value) {
        // Hauptschule
        case 1:
            return 4;
        // Gesamtschule
        case 2:
            return 5;
        // mittlere Reife
        case 3:
            return 2;
        // Fachabitur
        case 4:
            return 3;
        // Abitur
        case 5:
            return 1;
        default:
            return 4;
    }
}

function mapFromDBValue(value) {
    switch (value) {
        // Hauptschule
        case 4:
            return 1;
        // Gesamtschule
        case 5:
            return 2;
        // mittlere Reife
        case 2:
            return 3;
        // Fachabitur
        case 3:
            return 4;
        // Abitur
        case 1:
            return 5;
        default:
            return 4;
    }
}

// eslint-disable-next-line no-unused-vars
const schoolMarks = [
    {
        value: 1,
        label: "Abitur",
    },
    {
        value: 2,
        label: "Mittlere Reife",
    },
    {
        value: 3,
        label: "Fachabitur",
    },
    {
        value: 4,
        label: "Hauptschule",
    },
    {
        value: 5,
        label: "Gesamtschule",
    },
];

export const FormikSchoolSlider = ({field, form, ...props}) => {

    return (
        <div>
            <p style={{margin:'0'}}>{props.label}: {schoolValueLabelFormat(mapFromDBValue(field.value))} {props.unit}</p>
            <Slider
                min={props.min}
                max={props.max}
                aria-label={field.name}
                name={field.name}
                getAriaValueText={schoolValueLabelFormat}
                value={mapFromDBValue(field.value)}
                valueLabelDisplay="off"
                onChange={(event, value) => {
                    form.setFieldValue(field.name, mapToDBValue(value), true)
                }}
                {...props}
            />
        </div>
    );
};

