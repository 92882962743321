import React from "react"
import {observer} from "mobx-react"
import "./JobAlertListItemView.css"
import Grid from "@material-ui/core/Grid";
import {RWAppliedButton, RWJoinUsButton, RWPendingButton} from "../../input_elements/buttons/Buttons";
import {IconInfo} from "../../icons/StatusIcons";
import {Typography} from "@material-ui/core";
import appData from "../../../models/ReddworldData";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import MomentUtils from '@date-io/moment';
import {JOB_APPLICATION_AVAILABLE} from "../../../constants/constants";

export function JobAlertListItemView (props) {

    const [expanded, setExpanded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [applicationStatus, setApplicationStatus] = React.useState("not_applied");

    const handleExpandedChange = panel => (event, newExpanded) => {
        console.log("expanding");
        setExpanded(!expanded);
    };

    const momentUtils = new MomentUtils();
    const start = momentUtils.moment(props.jobAlert.workingStart, "DD.MM.YYYY").format("D.M.");
    const end = momentUtils.moment(props.jobAlert.workingEnd, "DD.MM.YYYY").format("D.M.");

    let jobtypes = "";

    if (props.jobAlert.jobType.length === 1) {
        jobtypes = "[" + props.jobAlert.jobType[0].shortcut + "]";
    } else if(props.jobAlert.jobType.length === 2) {
        jobtypes = "[" + props.jobAlert.jobType[0].shortcut +", " + props.jobAlert.jobType[1].shortcut + "]";
    } else if(props.jobAlert.jobType.length === 3) {
        jobtypes = "[" + props.jobAlert.jobType[0].shortcut +", " + props.jobAlert.jobType[1].shortcut + ", " + props.jobAlert.jobType[2].shortcut + "]";
    }
    function applyForJobAlert() {
        console.log("Applying for JobAlert with ID: " + props.jobAlert.id);
        setLoading(true);
        appData.applyForJobAlert(props.jobAlert.id).then(function(success) {
            // if promise fulfilled
            if(success) {
                setLoading(false);
                setApplicationStatus("applied");
            } else {
                setLoading(false);
                setApplicationStatus("error");
            }
        }, function(value) {
            setLoading(false);
            // if promise rejected
        });
    }
    return (

        <Grid xs={12} item={true}>
        <div className="jobalert-listitem">
                <div className="left">
                    <Typography align={"left"}>
                        <span className="left-horizontal-rule" style={{
                            "paddingLeft": "2em",
                            fontSize: '0.8em',
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis"
                        }}>{start} - {end} - {props.jobAlert.title} &nbsp;&nbsp;  {jobtypes}</span><br/>
                        <span className="left-event-name"
                              style={{"paddingLeft": "2em", fontSize: '0.8em'}}>{props.jobAlert.event.location.name}</span>
                    </Typography>
                    <br/>
                </div>
            {/*</Grid>*/}
            {/*<Grid xs={12} item={true}>*/}
                <div className="right">
                    <div className="ImageContainer">
                        <Typography>
                            {props.jobAlert.image && <img alt="Ausschreibungsbild" className="jobAlertImage"
                                                    src={"https://backoffice.reddworld.com/user_files/images/" + props.jobAlert.image}/>}
                        </Typography>
                    </div>
                </div>
            {/*</Grid>*/}

            {/*<ExpansionPanel square expanded={expanded === 'panel8'} onChange={handlePanelChange('panel8')}>*/}
            {/*    <ExpansionPanelSummary aria-controls="panel8d-content" id="panel8d-header">*/}

            {/*<Grid xs={12} item={true}>*/}
                <ExpansionPanel square expanded={expanded}
                                onChange={handleExpandedChange(!expanded)}>

                    <ExpansionPanelSummary
                        aria-controls={`panel-${props.jobAlert.id}`} id={`panel-${props.jobAlert.id}-header`}
                        className="JobAlertButtons"
                        style={{'margin': '0 auto', "margin-top": "0em", 'maxWidth': '80%', textAlign: 'left'}}>
                        {/*// aria-controls="panel-" id="panel--header" className="JobAlertButtons" style={{ margin: "0 auto", "margin-top" : "0em", maxWidth : '80%', textAlign: 'left'}}>*/}
                        <div className="JobAlertButtons" style={{
                            'margin': '0 auto',
                            "marginTop": "0em",
                            Width: '100% !important',
                            textAlign: 'left'
                        }}>
                            <></>
                            {(!loading && appData.appliedJobAlerts.includes(props.jobAlert)) && <RWAppliedButton text="angenommen!" variant="outlined" color="primary"/>}
                            {(!loading && appData.pendingJobAlerts.includes(props.jobAlert)) && <RWPendingButton text="beworben" variant="outlined" color="primary"/>}
                            {(!loading && appData.availableJobAlerts.includes(props.jobAlert) && applicationStatus === "not_applied" && props.status <= JOB_APPLICATION_AVAILABLE) && <RWJoinUsButton text="Join Us" variant="outlined" color="primary" onClick={applyForJobAlert}/>}
                            {(!loading && appData.availableJobAlerts.includes(props.jobAlert) && applicationStatus === "applied" && props.status <= JOB_APPLICATION_AVAILABLE) && <RWJoinUsButton text="erfolgreich beworben!" variant="outlined" color="success" disabled />}
                            {(!loading && appData.availableJobAlerts.includes(props.jobAlert) && applicationStatus === "error" && props.status <= JOB_APPLICATION_AVAILABLE) && <RWJoinUsButton text="Bewerbung nicht möglich - bereits beworben?" variant="outlined" color="success" disabled />}
                            {loading && <RWJoinUsButton text="bitte warten" variant="outlined" disabled color="primary"/>}
                            <IconInfo style={{"margin": "0px"}}/>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{
                        maxWidth: "80%",
                        margin: "0 auto",
                        border: "2px solid #942738",
                        borderRadius: "12px",
                        lineHeight: "16pt",
                        marginBottom: '0em'
                    }}>
                        <Typography color={"black"}
                                    style={{fontFamily: 'Merriweather', fontSize: '0.8em', marginBottom: "0em"}}>
                            {props.jobAlert.description.replace("<br />", "\n")}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

        </div>
        </Grid>
    )
}

export default observer(JobAlertListItemView)
