import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Construction} from '../../icons/GeneralIcons';


export default function ELearning() {

    return <>
        <h1>E-Learning</h1>
        <Grid item xs={12}>
            <Construction/>
        </Grid>
        <Grid item xs={12}>
            <p>Das E-Learning Modul befindet sich noch in der Entwicklung. <br/> Demnächst findest du hier den
                neuen E-Learning Bereich.</p>
        </Grid>
        {/*<Grid>*/}
        {/*{appData.elearnings.map((elearning, index) => {*/}
        {/*    return <div key={index}>*/}
        {/*        <Grid xs={12}*/}
        {/*              item={true}*/}
        {/*              style={{height: "250px"}}*/}
        {/*        >*/}
        {/*            <div className="elearning-listitem">*/}
        {/*                <div className="left">*/}
        {/*                    <Typography align={"left"}>*/}
        {/*                <span className="left-horizontal-rule" style={{*/}
        {/*                    "paddingLeft": "0.6em",*/}
        {/*                    fontSize: '1.4em',*/}
        {/*                    whiteSpace: "nowrap",*/}
        {/*                    textOverflow: "ellipsis"*/}
        {/*                }}>{elearning.name}</span><br/>*/}
        {/*                    </Typography>*/}
        {/*                    <br/>*/}
        {/*                    <Typography align={"left"}>*/}
        {/*                        {elearning.status !== "bestanden" && <>*/}
        {/*                            abzulegen bis {moment(elearning.date_until_necessary).format("DD.MM.YYYY")}<br/>*/}
        {/*                        </>}*/}
        {/*                        Status: {elearning.status}<br/>*/}
        {/*                        {elearning.status === "bestanden" && <>*/}
        {/*                            Ergebnis {elearning.result} %<br/>*/}
        {/*                            abgelegt am {moment(elearning.date_passed).format("DD.MM.YYYY")}<br/>*/}
        {/*                            gültig bis {moment(elearning.valid_until).format("DD.MM.YYYY")}<br/>*/}
        {/*                            </>}*/}
        {/*                        <RWRoundButton type="submit" variant="outlined" color="primary" text="Elearning aufrufen"*/}
        {/*                                       style={{float: "none"}}*/}
        {/*                                       onClick={() => window.open(elearning.path + "?jwt=" + localStorage.getItem("jwt"), "_blank")}/>*/}
        {/*                    </Typography>*/}

        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </Grid>*/}
        {/*    </div>;*/}
        {/*})}*/}
        {/*</Grid>*/}
    </>
};
