import {types} from "mobx-state-tree"

export const ELearning = types.model({
    id: types.number,
    path: types.string,
    standard_valid_for_days: types.maybe(types.number),
    name: types.string,
    status: types.maybe(types.string),
    result: types.maybe(types.number),
    date_passed: types.maybeNull(types.Date),
    date_until_necessary: types.Date,
    valid_until: types.maybeNull(types.Date),
}).actions(self => ({
    setUrl(url) {
        self.url = url;
    },
}));
