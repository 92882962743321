import {types} from "mobx-state-tree";
import {JobType} from "./JobAlert";

const Graduation = types.model({
    id: types.number,
    graduation: types.maybe(types.string)
});


// eslint-disable-next-line no-unused-vars
const JobTypeExperienceRating = types.model({
    value: types.number,
    jobType: JobType
});

const AccommodationOption = types.model({
    id: types.maybeNull(types.union(types.number, types.string)),
    city: types.string,
    checked: types.boolean
});

const Subject = types.model({
    id: types.optional(types.number, 0),
    subject: types.optional(types.string, "")
});

const Degree = types.model({
    id: types.optional(types.number, 0),
    degree: types.optional(types.string, "")
});

const University = types.model({
    id: types.optional(types.number, 0),
    university: types.maybeNull(types.string)
});

const Education = types.model({
    subject: types.maybe(Subject),
    done: types.optional(types.boolean, false),
    degree: types.maybe(Degree),
    semester: types.maybeNull(types.number),
    university: types.maybeNull(University),
    apprenticeshipYear: types.maybe(types.number)
});
const Apprenticeship = types.model({
    subject: Subject
});

const ExperienceForApplication = types.model({
    year: types.number,
    company: types.string,
    job_type: types.string,
    event: types.string
});

const HairColor = types.model({
    id: types.maybe(types.union(types.number, types.string)),
    haircolor: types.maybe(types.string)
});

// const DegreeOfKnowledge = types.model({
//     id: types.number,
//     knowledge: types.string
// });
//
// const Language = types.model({
//     id: types.number,
//     language: types.string,
//     degreeOfKnowledge: DegreeOfKnowledge
// });

export const ServiceEmployee = types.model({
    breast_circumference: types.optional(types.number, 80),
    waist_size: types.optional(types.number, 80),
    seat_circumference: types.optional(types.number, 80),
    body_height: types.optional(types.number, 150),
    t_shirt_size: types.optional(types.refinement(types.number, value => 1 <= value <= 7), 1),
    shoe_size: types.optional(types.number, 34),
    hairColor: types.optional(HairColor, {id: 1, haircolor: "blond"}),
    has_driver_licence: types.optional(types.boolean, false),
    has_a_security_cert: types.optional(types.boolean, false),
    has_transport_of_persons_sparkle: types.optional(types.boolean, false),
    is_tattooed: types.optional(types.boolean, false),
    is_pierced: types.optional(types.boolean, false),
    has_glasses: types.optional(types.boolean, false),
    jeans_length: types.optional(types.number, 24),
    jeans_width: types.optional(types.number, 24),
    clothes_size: types.optional(types.number, 32),
    prefers_day_shift: types.optional(types.boolean, false),
    prefers_night_shift: types.optional(types.boolean, false),
    //languages: types.optional(types.array(Language), []),

    // Schulabschluss:
    graduation: types.maybe(Graduation),
    hostessExperience: types.optional(types.model({
        value: types.number
    }), {value: 0}),
    cleaningExperience:types.optional(types.model({
        value: types.number
    }), {value: 0}),
    securityExperience:types.optional(types.model({
        value: types.number
    }), {value: 0}),
    promotionExperience:types.optional(types.model({
        value: types.number
    }), {value: 0}),
    gastroExperience:types.optional(types.model({
        value: types.number
    }), {value: 0}),
    salesExperience:types.optional(types.model({
        value: types.number
    }), {value: 0}),

// cleaningExperience: types.optional(JobTypeExperienceRating, {
        //     value: 0,
        //     jobType: types.optional(JobType, {
        //         id: 1,
        //         permission: []
        //     })
        // }),
        // securityExperience: types.optional(JobTypeExperienceRating, {
        //     value: 0,
        //     jobType: types.optional(JobType, {
        //         id: 4,
        //         permission: []
        //     })
        // }),
        // promotionExperience: types.optional(JobTypeExperienceRating, {
        //     value: 0,
        //     jobType: types.optional(JobType, {
        //         id: 6,
        //         permission: []
        //     })
        // }),
        // gastroExperience: types.optional(JobTypeExperienceRating, {
        //     value: 0,
        //     jobType: types.optional(JobType, {
        //         id: 8,
        //         permission: []
        //     })
        // }),
        // salesExperience: types.optional(JobTypeExperienceRating, {
        //     value: 0,
        //     jobType: types.optional(JobType, {
        //         id: 11,
        //         permission: []
        //     })
        // }),

    //types.optional(types.array(JobTypeExperienceRating), []),
    accommodationOptions: types.optional(types.array(AccommodationOption), []),
    // Studium, Ausbildung etc
    currentDegree: types.maybe(Education),
    finishedDegree: types.maybe(Education),
    apprenticeship: types.optional(Apprenticeship, {
        subject: {
            id: 0,
            subject: ""
        }
    }),
    experienceForApplication: types.optional(types.array(ExperienceForApplication), [])
}).views(self => ({
    // get finishedStudies() {
    //     return self.finalDegree.filter(education =>
    //         education.done === true && education.university !== null)[0];
    // },
    // get currentStudies() {
    //     return self.finalDegree.filter(education =>
    //         education.done === false && education.university !== null)[0];
    // },
    // get finishedApprenticeship() {
    //     return self.finalDegree.filter(education =>
    //         education.done === true && education.university === null)[0];
    // },
    // get hostessExperience() {
    //     return self.jobTypeExperienceRating.filter(experience =>
    //         experience.jobType.id === 3)[0];
    // },
    // get cleaningExperience() {
    //     return self.jobTypeExperienceRating.filter(experience =>
    //         experience.jobType.id === 1)[0];
    // },
    // get securityExperience() {
    //     return self.jobTypeExperienceRating.filter(experience =>
    //         experience.jobType.id === 4)[0];
    // },
    // get promotionExperience() {
    //     return self.jobTypeExperienceRating.filter(experience =>
    //         experience.jobType.id === 6)[0];
    // },
    // get gastroExperience() {
    //     return self.jobTypeExperienceRating.filter(experience =>
    //         experience.jobType.id === 8)[0];
    // },
    // get salesExperience() {
    //     return self.jobTypeExperienceRating.filter(experience =>
    //         experience.jobType.id === 11)[0];
    // },

})).actions(self => ({
    // addLanguage() {
    //     let language = {
    //         id: 0,
    //             language: "",
    //         degreeOfKnowledge: {
    //             id: 1,
    //             knowledge: ""
    //         }
    //     };
    //     let languages = self.languages;
    //     languages.push(language);
    //     self.languages = languages;
    // }
}));
