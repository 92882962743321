import React from 'react';
import appData from "../../../models/ReddworldData";

import 'react-dropzone-uploader/dist/styles.css'
import {Typography} from "@material-ui/core";
import {FilePreviewUploader} from "./FilePreviewUploader";

export default function HealthInsuranceDocuments() {
    return (
        <>
            <Typography align={"left"}>
                Für die Abrechnung brauchen wir eine aktuelle Mitgliedsbescheinigung deiner Krankenkasse.
            </Typography>

            {/* needed documents */}
            {/* Health Insurance Certification */}
            <FilePreviewUploader
                file={appData.user.billing_data.getHealthInsuranceCertificates()}
                key={appData.user.billing_data.getHealthInsuranceCertificates().filename}
            />
        </>


    )

}