import React, {useEffect, useState} from 'react'
import appData from '../../models/ReddworldData'
import "typeface-roboto"
import BillingData from './billing_data/BillingData'
// import {ELearning} from './ELearning/ELearning'
import JobAlerts from './jobalerts/JobAlerts'
import {Overview} from './overview/Overview'
import PersonalData from './personal_data/PersonalData'
import './Dashboard.css'
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import ELearning from './elearning/ELearning';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import CssBaseline from "@material-ui/core/CssBaseline";
import {Personal} from './personal/Personal';
import {
    SvgDokumentsymbolZentriert,
    SvgDokumentsymbolZentriertActive,
    SvgEurosymbol,
    SvgEurosymbolActive,
    SvgPersonCircle,
    SvgPersonCircleActive,
    SvgReddingtonLogoMenu,
    SvgReddingtonLogoMenuActive
} from "../../img/icons/figma/components/Icons";
import {CircleAvatar} from "./avatar/CircleAvatar";
import Grid from "@material-ui/core/Grid";
import {LoadingPage} from "./loading_page/LoadingPage";

export const Dashboard = () => {

    const [currentPage, setCurrentPage] = useState('Overview');
    const [loading, setLoading] = useState(true);
    const [loadingPercentage, setLoadingPercentage] = useState(0);

    //componentDidMount() {
    useEffect(() => {

        // these api calls must be nested, otherwise it can come to a race condition,
        // so that the function fetching the detailed serviceemployee data does not yet have
        // all the needed parameters for the call.
        // once the dashboard mounts, trigger all the data fetching from the backend - the loading percentage indicates
        // how many steps are already done. This can be used in a progress bar. The loading state can be used to render
        // a loading icon.
        // if any of the steps fail, the loading percentage is set to 100%.

        appData.user.getUserData().then((result) => {
            //this.setState({loading: true, loadingPercentage: 0.15});
            setLoading(true);
            setLoadingPercentage(0.1);
            appData.user.getServiceEmployeeData().then((result) => {
                setLoading(true);
                setLoadingPercentage(0.2);
                //this.setState({loading: true, loadingPercentage: 0.3});
                appData.getCurrentJobAlertsForUser().then((result) => {
                    setLoading(true);
                    setLoadingPercentage(0.3);
                    //this.setState({loading: true, loadingPercentage: 0.45});
                    appData.user.getUserImages().then((result) => {
                        setLoading(true);
                        setLoadingPercentage(0.5);
                        //this.setState({loading: true, loadingPercentage: 0.6});
                        appData.user.getBillingData().then((result) => {
                            setLoading(true);
                            setLoadingPercentage(0.6);
                            appData.getElearningsForUser().then((result) => {
                                setLoading(true);
                                setLoadingPercentage(0.7);
                                appData.autoComplete.getHealthInsurances().then((result) => {
                                    setLoading(true);
                                    setLoadingPercentage(0.85);
                                    //this.setState({loading: true, loadingPercentage:0.75});
                                    appData.user.getBillingFiles().then((result) => {
                                        setLoadingPercentage(1);
                                        setLoading(false);
                                        //this.setState({loading: false, loadingPercentage: 1});
                                        appData.setLoading(false);
                                    }, (err) => {
                                        setLoadingPercentage(1);
                                        setLoading(false);
                                        //this.setState({loading: false, loadingPercentage: 1});
                                        appData.setLoading(false);
                                        console.log(err)
                                    })
                                }, (err) => {
                                    setLoadingPercentage(1);
                                    setLoading(false);
                                    //this.setState({loading: false, loadingPercentage: 1});
                                    appData.setLoading(false);
                                    console.log(err)
                                })
                            }, (err) => {
                                setLoadingPercentage(1);
                                setLoading(false);
                                //this.setState({loading: false, loadingPercentage: 1});
                                appData.setLoading(false);
                                console.log(err)
                            })
                        }, (err) => {
                            setLoadingPercentage(1);
                            setLoading(false);
                            //this.setState({loading: false, loadingPercentage: 1});
                            appData.setLoading(false);
                            console.log(err)
                        })
                    }, (err) => {
                        setLoadingPercentage(1);
                        setLoading(false);
                        //this.setState({loading: false, loadingPercentage: 1});
                        appData.setLoading(false);
                        console.log(err)
                    })
                }, (err) => {
                    setLoadingPercentage(1);
                    setLoading(false);
                    //this.setState({loading: false, loadingPercentage: 1});
                    appData.setLoading(false);
                    console.log(err)
                })
            }, (err) => {
                setLoadingPercentage(1);
                setLoading(false);
                //this.setState({loading: false, loadingPercentage: 1});
                appData.setLoading(false);
                console.log(err)
            })
        }, (err) => {
            setLoadingPercentage(1);
            setLoading(false);
            //this.setState({loading: false, loadingPercentage: 1});
            appData.setLoading(false);
            console.log(err)
        })
    }, []);

    const resolveCurrentPage = () => {
        switch (currentPage) {
            case 'Overview':
                return <Overview setCurrentPage={setCurrentPage}/>;
            case 'JobAlerts':
                return <JobAlerts/>;
            case 'PersonalData':
                return <PersonalData/>;
            case 'BillingData':
                return <BillingData/>;
            case 'ELearning':
                return <ELearning/>;
            case 'Personal':
                return <Personal/>;
            default:
                return <></>
        }
    };

    return (
        <>
            <CssBaseline/>
            <Grid xs={12} item={true}>
                <Typography component="div" style={{backgroundColor: 'rgb(255, 255, 255)'}}>
                    <Container maxWidth="lg">
                        <Typography component="div" align="center">
                            {!loading && resolveCurrentPage()}
                            {loading && <LoadingPage percent={loadingPercentage}/>}
                        </Typography>
                    </Container>
                </Typography>

            </Grid>
            <Grid xs={12} item={true}>
                <BottomNavigation
                    showLabels={true}
                    //value={}
                >
                    <BottomNavigationAction onClick={() => setCurrentPage('JobAlerts')} showLabel={false}
                                            icon={currentPage === "JobAlerts" ? <SvgReddingtonLogoMenuActive/> :
                                                <SvgReddingtonLogoMenu/>}/>
                    <BottomNavigationAction onClick={() => setCurrentPage('PersonalData')} showLabel={false}
                                            icon={currentPage === "PersonalData" ? <SvgPersonCircleActive/> :
                                                <SvgPersonCircle/>}/>
                    <BottomNavigationAction onClick={() => setCurrentPage('BillingData')} showLabel={false}
                                            icon={currentPage === "BillingData" ? <SvgEurosymbolActive/> :
                                                <SvgEurosymbol/>}/>
                    <BottomNavigationAction onClick={() => setCurrentPage('ELearning')} showLabel={false}
                                            icon={currentPage === "ELearning" ? <SvgDokumentsymbolZentriertActive/> :
                                                <SvgDokumentsymbolZentriert/>}/>
                    <BottomNavigationAction onClick={() => setCurrentPage('Overview')} showLabel={false}
                                            icon={<CircleAvatar/>}/>

                </BottomNavigation>
            </Grid>
        </>
    );

};

